import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from '../../styles/register.module.scss';
import DatalistInput from './datalistInput';
import SelectInput from './selectInput';
import { c, isValidEmail as validateEmail } from '../../helpers';
import { useForm, useCountryList } from './hooks';

/* constants */
export const COUNTRY_INPUT = 'country'
export const PHONE_CODE_INPUT = 'phone_code'
export const ZIP_INPUT = 'zip'
export const USERNAME_INPUT = 'username'
export const EMAIL_INPUT = 'email'
export const USER_TYPE_INPUT = 'user_type'
export const PASSWORD_INPUT = 'password'
export const CONFIRM_PASSWORD_INPUT = 'confirm_password'

const userTypeList = [
    { name: 'Student', value: 'student' },
    { name: 'Professional', value: 'professional' },
    { name: 'Other', value: 'other' }
];

/* validation */

const validateZipCode = (value) => {
    const regex = /^[a-z\d\-\s]+$/i;
    return regex.test(value);
}

const validateUsername = (value) => {
    const regex = /^[a-z\d._]+$/i;
    return regex.test(value);
}

const validatePassword = (value) => {
    return isValidPasswordStep1(value) && isValidPasswordStep2(value);
}

const validateConfirmPassword = password => value => {
    return password === value;
}

const isValidPasswordStep1 = (value) => {
    const regex = /[a-zA-Z0-9]{8,}/;
    return regex.test(value);
}

const isValidPasswordStep2 = (value) => {
    const regex = /[0-9]{1,}/;
    return regex.test(value);
}

const initialStateLeftForm = {
    [COUNTRY_INPUT]: {
        value: '',
        valid: false
    },
    [PHONE_CODE_INPUT]: {
        value: '',
        valid: true
    },
    [ZIP_INPUT]: {
        value: '',
        valid: false
    },
    [USERNAME_INPUT]: {
        value: '',
        valid: false
    },
    [EMAIL_INPUT]: {
        value: '',
        valid: false
    },
    [USER_TYPE_INPUT]: {
        value: '',
        valid: false
    },
    [PASSWORD_INPUT]: {
        value: '',
        valid: false
    },
    [CONFIRM_PASSWORD_INPUT]: {
        value: '',
        valid: false
    },
};

const LeftForm = ({ onSubmit }) => {
    const { inputs, isValid, handleInputChange, handleSubmit } = useForm(initialStateLeftForm, () => onSubmit(inputs));
    const countryList = useCountryList();
    const [activeInput, setActiveInput] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [stagingPassword, setStagingPassword] = useState(false);
    const [stagingConfirmPassword, setStagingConfirmPassword] = useState(false);
    const handleInput = (name, validation) => e => handleInputChange(name, e.target.value, validation(e.target.value));

    const handleCountryChange = (_, value, valid, item) => {
        handleInputChange(COUNTRY_INPUT, value, valid);
        handleInputChange(PHONE_CODE_INPUT, item.callingCodes[0], true);
    }

    const passwordValueShown = () => {
        if(showPassword || stagingPassword) return inputs[PASSWORD_INPUT].value;
        let shown = '';
        while(shown.length < inputs[PASSWORD_INPUT].value.length) shown += '*';
        return shown
    }

    const confirmPasswordValueShown = () => {
        if(stagingConfirmPassword) return inputs[CONFIRM_PASSWORD_INPUT].value;
        let shown = '';
        while(shown.length < inputs[CONFIRM_PASSWORD_INPUT].value.length) shown += '*';
        return shown;
    }

    return (
    <>
        <div className={style.inputBox}>
            <DatalistInput name={COUNTRY_INPUT} options={countryList} onChange={handleCountryChange} />
            <div
                className={style.inputGroup}>
                <i className={c(style.iconPersonPin, activeInput === ZIP_INPUT && style.iconBlue)} />
                &nbsp;
                <input
                    onBlur={() => setActiveInput(null)}
                    onFocus={() => setActiveInput(ZIP_INPUT)}
                    className={style.inputBlue}
                    type="text"
                    placeholder="Zip code"
                    autoComplete="off"
                    value={inputs[ZIP_INPUT].value}
                    onChange={handleInput(ZIP_INPUT, validateZipCode)} />
            </div>
            <div
                className={style.inputGroup}>
                <i className={c(style.iconPerson, activeInput === USERNAME_INPUT && style.iconBlue)} />
                &nbsp;
                <input
                    onBlur={() => setActiveInput(null)}
                    onFocus={() => setActiveInput(USERNAME_INPUT)}
                    className={style.inputBlue}
                    type="text"
                    placeholder="Username"
                    autoComplete="off"
                    maxLength="24"
                    value={inputs[USERNAME_INPUT].value}
                    onChange={handleInput(USERNAME_INPUT, validateUsername)} />
                <div className={c(style.tooltip, style.right, activeInput === USERNAME_INPUT && !inputs[USERNAME_INPUT].valid && style.show)}>
                    <div className={style.tooltipContent}>
                        <span className={style.valid}>
                            <i className={style.iconError}></i>&nbsp;Accepts a-z 0-9 . _
                        </span>
                    </div>
                </div>
            </div>
            <div
                className={style.inputGroup}>
                <i className={c(style.iconEmail, activeInput === EMAIL_INPUT && style.iconBlue)} />
                &nbsp;
                <input
                    onBlur={() => setActiveInput(null)}
                    onFocus={() => setActiveInput(EMAIL_INPUT)}
                    className={style.inputBlue}
                    type="text"
                    placeholder="Email"
                    autoComplete="off"
                    value={inputs[EMAIL_INPUT].value}
                    onChange={handleInput(EMAIL_INPUT, validateEmail)} />
                <div className={c(style.tooltip, style.right, activeInput === EMAIL_INPUT && !inputs[EMAIL_INPUT].valid && style.show)}>
                    <span className={style.tooltipContent}>
                        <span className={style.valid}>
                            <i className={style.iconError}></i>&nbsp;Invalid email
                        </span>
                    </span>
                </div>
            </div>
            <SelectInput name={USER_TYPE_INPUT} placeholder="User Type" onChange={handleInputChange} options={userTypeList} />
            <div
                className={style.inputGroup}>
                <i className={c(style.iconLock, activeInput === PASSWORD_INPUT && style.iconBlue)} />
                &nbsp;
                <input
                    onBlur={() => setActiveInput(null)}
                    onFocus={() => setActiveInput(PASSWORD_INPUT)}
                    className={style.inputBlue}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={passwordValueShown()}
                    onKeyDown={() => setStagingPassword(true)}
                    onChange={e => { handleInput(PASSWORD_INPUT, validatePassword)(e); setStagingPassword(false); }}
                    autoComplete="off"
                    maxLength="24" />
                <i
                    onMouseDown={() => setShowPassword(true)}
                    onMouseUp={() => setShowPassword(false)}
                    onMouseOut={() => setShowPassword(false)}
                    className={c(showPassword ? style.iconDark : style.iconGrey, style.iconVisibility)} />
                <div className={c(style.tooltip, style.right, activeInput === PASSWORD_INPUT && !inputs[PASSWORD_INPUT].valid && style.show)}>
                    <span className={style.tooltipContent}>
                    <span className={style.valid}>
                        <i className={c(style.iconCheck, isValidPasswordStep1(inputs[PASSWORD_INPUT].value) && style.iconGreen)}></i>Minimum 8 characters
                    </span>
                    <span className={style.valid}>
                        <i className={c(style.iconCheck, isValidPasswordStep2(inputs[PASSWORD_INPUT].value) && style.iconGreen)}></i>At least one number</span>
                    </span>
                </div>
            </div>
            <div
                className={style.inputGroup}>
                <i className={c(style.iconLock, activeInput === CONFIRM_PASSWORD_INPUT && style.iconBlue)} />
                &nbsp;
                <input
                    onBlur={() => setActiveInput(null)}
                    onFocus={() => setActiveInput(CONFIRM_PASSWORD_INPUT)}
                    className={style.inputBlue}
                    type="password"
                    placeholder="Repeat Password"
                    value={confirmPasswordValueShown()}
                    onKeyDown={() => setStagingConfirmPassword(true)}
                    onChange={e => { handleInput(CONFIRM_PASSWORD_INPUT, validateConfirmPassword(inputs[PASSWORD_INPUT].value))(e); setStagingConfirmPassword(false); }}
                    autoComplete="off"
                    maxLength="24" />
                <div className={c(style.tooltip, style.right, activeInput === CONFIRM_PASSWORD_INPUT && !inputs[CONFIRM_PASSWORD_INPUT].valid && style.show)}>
                    <span className={style.tooltipContent}>
                        <span className={style.valid}>
                            <i className={style.iconError}></i>&nbsp;Passwords do not match
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <button
            disabled={!isValid()}
            onClick={handleSubmit}
            className={style.btBlock}
            type="button">
            CONTINUE
        </button>
    </>);
};

LeftForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

LeftForm.defaultProps = {
    onSubmit: () => {}
};

export default LeftForm;
