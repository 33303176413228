import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actionShowDelete, actionShowDeleteComment } from "../actions/action_styles";
import style from "../styles/optionsPost.module.scss";
import { c } from '../helpers';

const OptionsPost = ({ comment, post }) => {
  const dispatch = useDispatch();
  const showDelete = useSelector((state) => state.styles.delete);
  const showDeleteComment = useSelector((state) => state.styles.comment);
  const [showOption, setShowOption] = useState(false);
  const [postState, setPost] = useState(post);
  const [background, setBackground] = useState('');

  const [commentState, setComment] = useState(comment);
  const [modal, setModal] = useState();


  const handleClick = () => {
    setShowOption(!showOption)
  }

  const handleShowDelete = () => {
    setBackground('')
    if (postState) {
      dispatch(actionShowDelete(!showDelete))
    }
    if (commentState) {
      dispatch(actionShowDeleteComment(!showDeleteComment))
    }
    setShowOption(!showOption)
  }
  useEffect(() => {
    setComment(comment)
    setPost(post)
    if(comment){
      setModal(style.modal)
    }
  }, [])
  return (
      <div className={c(style.boxOption, modal)}>
        <div onClick={()=>setBackground(style.background)} className={c(style.iconBox, background)}>
          <i title="OPTIONS" onClick={handleClick}
            className={style.iconOption}></i>
        </div>
        {showOption &&
          <div className={style.options}>
            <span >Edit</span>
            <span className={style.botton} onClick={handleShowDelete}>Delete</span>
          </div>
        }
      </div>
  )
}

export default OptionsPost;