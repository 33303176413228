export const REGISTER_ROUTE = '/register'
export const REGISTER_FROM_INVITATION_ROUTE = '/register/:communityCode'
export const REGISTER_NEXT_ROUTE = '/register/next'
export const LOGIN_ROUTE = '/login'
export const DASHBOARD_ROUTE = '/dashboard'
export const EVENTS_ROUTE = '/event'
export const NEW_EVENT_ROUTE = '/event/new'
export const SEARCH_ROUTE = '/event/search'
export const PROFILE_ROUTE = '/profile'

export const TRACK_PATH = '/track'
export const COMMUNITY_PATH = '/community'
export const ROOM_PATH = '/room'
export const SPEAKER_PATH = '/speaker'
export const SPONSOR_PATH = '/sponsor'
export const EXHIBITOR_PATH = '/exhibitor'
export const TICKET_PATH = '/ticket'
export const ATTENDEE_PATH = '/attendee'
export const CALL_FOR_PAPERS_PATH = '/callforpapers'
export const BLOGS_ROUTE = '/blog'
export const FORUMS_ROUTE = '/forum'
export const WEBSITE_ROUTE = '/website'
export const VIDEOCALL_ROUTE = '/videocall'


export const EVENT_ROUTE = EVENTS_ROUTE + '/:eventId'
export const TRACKS_ROUTE = EVENTS_ROUTE + '/:eventId' + TRACK_PATH
export const COMMUNITY_ROUTE = EVENTS_ROUTE + '/:eventId' + COMMUNITY_PATH
export const ROOMS_ROUTE = EVENTS_ROUTE + '/:eventId' + ROOM_PATH
export const SPEAKERS_ROUTE = EVENTS_ROUTE + '/:eventId' + SPEAKER_PATH
export const SPEAKER_ROUTE = EVENTS_ROUTE + '/:eventId' + SPEAKER_PATH + '/:speakerId'
export const SPONSORS_ROUTE = EVENTS_ROUTE + '/:eventId' + SPONSOR_PATH
export const EXHIBITORS_ROUTE = EVENTS_ROUTE + '/:eventId' + SPONSOR_PATH
export const TICKETS_ROUTE = EVENTS_ROUTE + '/:eventId' + TICKET_PATH
export const ATTENDEES_ROUTE = EVENTS_ROUTE + '/:eventId' + ATTENDEE_PATH
export const CALL_FOR_PAPERS_ROUTE = EVENTS_ROUTE + '/:eventId' + CALL_FOR_PAPERS_PATH

export const getEventRoute = (route, eventId) => route.replace(':eventId', eventId)
export const getSpeakerRoute = (route, eventId, speakerId) => route.replace(':eventId', eventId).replace(':speakerId', speakerId)


/* redirects to this route all non auth user, except for index */
export const REDIRECT_PATH = '/login'