import axios from 'axios';

import {
    READ_EXHIBITORS,
    READ_EXHIBITOR,
    SUCCESS_EXHIBITOR_CREATE,
    SUCCESS_EXHIBITOR_UPDATE,
    SUCCESS_EXHIBITOR_DELETE,
    ERROR_EXHIBITOR_READ
} from '../config/constants'
import { URL_BASE_API, EXHIBITOR_PATH } from '../config/api';

const headers = {
    headers: {
        skip: true
    }
}

// CRUD EXHIBITOR

const get_exhibitors = () => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${EXHIBITOR_PATH}`, headers)
            .then(res => res.data.payload[0])
            .then(exhibitors => exhibitors.sort((exhibitor, b) => exhibitor.id - b.id))
            .then(data => {
                dispatch({
                    type: READ_EXHIBITORS,
                    exhibitors: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_exhibitor_read(error_read))
                reject(error_read)
            });
         });

    }

}

const get_exhibitor = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${EXHIBITOR_PATH}/${ID}`, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: READ_EXHIBITOR,
                    exhibitor: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_exhibitor_read(error_read))
                reject(error_read)
            });
         });

    }

}

const post_exhibitors = data => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .post(`${URL_BASE_API}${EXHIBITOR_PATH}`, data, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: SUCCESS_EXHIBITOR_CREATE,
                    create: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_exhibitor_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const put_exhibitors = (ID, data) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .put(`${URL_BASE_API}${EXHIBITOR_PATH}/${ID}`, data, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: SUCCESS_EXHIBITOR_UPDATE,
                    update: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_exhibitor_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const delete_exhibitors = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .delete(`${URL_BASE_API}${EXHIBITOR_PATH}/${ID}`, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_EXHIBITOR_DELETE,
                    delete: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_exhibitor_read(error_read))
                reject(error_read)
            });
         });

    }
    
}


////////////Sponsor Read/////////////

const error_exhibitor_read = (error_read) => {
  return {
    type: ERROR_EXHIBITOR_READ,
    error_read
  }
}

export { get_exhibitors, get_exhibitor, post_exhibitors, put_exhibitors, delete_exhibitors }