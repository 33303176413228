import axios from 'axios';

import {
    READ_TICKETS,
    READ_TICKETS_ID_EVENT,
    SUCCESS_TICKET_CREATE,
    SUCCESS_TICKET_UPDATE,
    SUCCESS_TICKET_DELETE,
    ERROR_TICKET_READ,
} from '../config/constants'
import { URL_BASE_API, TICKET_PATH } from '../config/api';

const headers = {
    headers: {
        skip: true
    }
}

const get_tickets = () => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${TICKET_PATH}`, headers)
            .then(res => res.data.payload[0])
            .then(tickets => tickets.sort((ticket, b) => ticket.id - ticket.id))
            .then(data => {
                dispatch({
                    type: READ_TICKETS,
                    tickets: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_ticket_read(error_read))
                reject(error_read)
            });
         });

    }
}


const get_tickets_id_event = (ID_EVENT) => {

    return dispatch => {
        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${TICKET_PATH}`, headers)
            .then(res => res.data.payload[0])
            .then(tickets => tickets.filter( tickets => tickets.id_event === ID_EVENT))
            .then(filter => filter.sort((filter, b) => filter.id - b.id))
            .then(data => {
                dispatch({
                    type: READ_TICKETS_ID_EVENT,
                    tickets: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_ticket_read(error_read))
                reject(error_read)
            });
         });

    }
}

const post_tickets = data => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .post(`${URL_BASE_API}${TICKET_PATH}`, data, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_TICKET_CREATE,
                    create: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_ticket_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const put_tickets = (ID, data) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .put(`${URL_BASE_API}${TICKET_PATH}/${ID}`, data, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_TICKET_UPDATE,
                    update: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_ticket_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const delete_tickets = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .delete(`${URL_BASE_API}${TICKET_PATH}/${ID}`, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_TICKET_DELETE,
                    delete: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_ticket_read(error_read))
                reject(error_read)
            });
         });

    }
    
}


////////////Track Read/////////////

const error_ticket_read = (error_read) => {
  return {
    type: ERROR_TICKET_READ,
    error_read
  }
}

export { get_tickets, get_tickets_id_event, post_tickets, put_tickets, delete_tickets }