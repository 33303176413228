import React, {Component} from 'react';
import FilterTab from './filterTab';
import style from '../styles/tabBar.module.scss';

export default class TabBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: this.loadStatus()
    }
  }
  loadStatus() {
    return this.props.tabs.map((_, i) => i === 0);
  }
  loadFilters() {
    return this.props.tabs.map((tab, i) => (
      <FilterTab items={tab.items} key={i} id={i} actived={this.state.status[i]} selected={this.selectedTab}>
        {tab.name}
      </FilterTab>
    ));
  }
  deselectedTab() {
    const status = this.state.status.map(actived => {
      if(actived) return false;
      return actived;
    });
    this.setState({status});
  }
  selectedTab = (id) => {
    this.deselectedTab()
    let {status} = this.state
    status[id] = true
    this.setState({status})
    this.props.value(this.props.tabs[id].name.toLowerCase())
  }
  render() {
    return (
      <menu className={style.tabBar}>
        {
          this.loadFilters()
        }
      </menu>
    )
  }
}