import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import style from '../styles/register.module.scss';
import action_signup from '../actions/action_signup';
import { actionForm } from '../actions/action_form';
import { action_read_community } from '../actions/action_communities';
import { c, formatDate } from '../helpers';
import Meta from '../components/metaContent';
import { LOGIN_ROUTE, REGISTER_NEXT_ROUTE } from '../config/routes';
import LeftForm, {
  PHONE_CODE_INPUT,
  EMAIL_INPUT,
  USERNAME_INPUT,
  COUNTRY_INPUT,
  PASSWORD_INPUT,
  ZIP_INPUT,
  USER_TYPE_INPUT
} from '../components/register/leftForm';
import RightForm, {
  NAME_INPUT,
  LASTNAME_INPUT,
  ADDRESS_INPUT,
  PHONE_NUMBER_INPUT,
  BIRTHDAY_INPUT,
  GENDER_INPUT
} from '../components/register/rightForm';


const RegisterLoginColumn = (props) => {
  return (
    <div className={style.circle}>
      <div className={style.message}>
        <div className={style.headCircle}>
          <h3 className={style.circleTitle}>Welcome to Kommu</h3>
          <p className={style.circleSubtitle}>Create share and enjoy your interests</p>
        </div>
        <div className={style.footerCircle}>
          <h4 className={style.footerText}>Already have account?</h4>
          <Link to={LOGIN_ROUTE} className={style.btOutline}>
            LOGIN
          </Link>
        </div>
      </div>
    </div>
  );
}

const Register = ({ invited }) => {
  const params = useParams();
  const dispatch = useDispatch();
  let history = useHistory();
  const community = useSelector(state => state.community.community);
  const [logoColor, setStyleLogoColor] = useState('');
  const [activeTabRight, setActiveTabRight] = useState(false);
  const [leftForm, setLeftForm] = useState({});

  const showActiveTabLeft = () => setActiveTabRight(false);
  const showActiveTabRight = () => setActiveTabRight(true);

  const stylesTabLeft = () => activeTabRight ? style.tabOff : style.tabOn;
  const stylesTabRight = () => activeTabRight ? style.tabOn : style.tabOff;
  const stylesFormLeft = () => activeTabRight ? style.formHide : style.formShow;
  const stylesFormRight = () => activeTabRight ? style.formShow : style.formHide;

  useEffect(() => {
    if(invited) {
      dispatch(action_read_community(params.communityCode));
    }
  });

  const handleContinue = (form) => {
    showActiveTabRight();
    setLeftForm(form);
  };

  const handleSubmit = (rightForm) => {
    //VALIDATE DISPATCH ACTION
    const form = {...leftForm, ...rightForm};

    const data = {
      name: form[NAME_INPUT].value,
      email: form[EMAIL_INPUT].value,
      last_name: form[LASTNAME_INPUT].value,
      username: form[USERNAME_INPUT].value,
      address: form[ADDRESS_INPUT].value,
      country: form[COUNTRY_INPUT].value,
      password: form[PASSWORD_INPUT].value,
      phone: form[PHONE_NUMBER_INPUT].value,
      country_code: form[PHONE_CODE_INPUT].value,
      zip_code: form[ZIP_INPUT].value,
      type: form[USER_TYPE_INPUT].value,
      birthdate: formatDate(form[BIRTHDAY_INPUT].value),
      gender: form[GENDER_INPUT].value,
      id_role: 1,
      id_repository: 1,
    };

    if (invited) {
      data.codeCommunity = params.communityCode;
      data.urlInvitation = community.url_invitation;
      sendRegister(data);
    } else {
      dispatch(actionForm(data));
      history.push(REGISTER_NEXT_ROUTE);
    }
  };

  const sendRegister = async(data) => {
    try {
      const { signup } = await dispatch(action_signup(data));
      if(signup) {
        history.push(LOGIN_ROUTE);
      }
    } catch {
      // TO DO: show error
    }
  }

  return (
    <div className={style.back}>
      <Meta title="Sign Up" />
      <aside className={style.leftLayout}>
        <RegisterLoginColumn />
      </aside>
      <article className={style.rightLayout}>
        <div className={style.rightTop}>
          <i
            onClick={() => setStyleLogoColor(style.noBack)}
            className={c(style.rightLogo, logoColor)}></i>
        </div>
        <h2 className={style.rightTitle}>Join us!</h2>
        <div className={style.tabs}>
          <button
            type="button"
            onClick={showActiveTabLeft}
            className={stylesTabLeft()}>
            Account
          </button>
          <button type="button" className={stylesTabRight()}>
            Personal
          </button>
        </div>
        <div className={style.box}>
          <form
            className={style.form}
            onSubmit={e => e.preventDefault()}>
            <div
              className={stylesFormLeft()}
              onFocus={showActiveTabLeft}>
              <LeftForm onSubmit={handleContinue} />
            </div>
            <div
              className={stylesFormRight()}
              onFocus={showActiveTabRight}>
              <RightForm phoneCode={leftForm[PHONE_CODE_INPUT] ? leftForm[PHONE_CODE_INPUT].value : ''} onSubmit={handleSubmit} />
            </div>
          </form>
        </div>
      </article>
    </div>
  );
}

export default Register;
