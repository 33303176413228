import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import style from '../styles/eventSpeakers.module.scss';
import { actionShowDelete } from '../actions/action_styles';
import { get_speaker_for_event } from '../actions/action_speakers'
import Search from '../components/search';
import ButtonChangeView from '../components/buttonChangeView';
import Card from '../components/card';
import Meta from '../components/metaContent';
import { c, urlFor } from '../helpers';

const EventSpeakers = (props) => {
	const dispatch = useDispatch();
	const showList = useSelector((state) => state.styles);
	const showDelete = useSelector((state) => state.styles);
	const speakersData = useSelector((store) => store.speakers.speaker_event);

	const [stateSpeakers, setStateSpeakers] = useState({
		idEvent: props.match.path.match(/\d+/)[0],
		search: ''
	})

	useEffect(() => {
		dispatch(get_speaker_for_event(stateSpeakers.idEvent))
	});

	const empty = () => speakersData.length === 0;

	const loaderSpeakers = () =>{
        let _speakers = [];
        for (let i = 0; speakersData.length > i; i++){
            const speaker = speakersData[i];
            _speakers.push(
				<Card 
					key={speaker.id}
					id={speaker.id}
					values={speaker}
					title={`${speaker.user.name} ${speaker.user.last_name}`}
					description={speaker.session.description}
					showList={showList.view}
					showDelete={showDelete.delete}
					event={false}
					search={stateSpeakers.search}
					imgURL={urlFor(speaker.user.profile_photo)}
                    fnAction={[
                        {fn: () => {
							dispatch(actionShowDelete(!showDelete.delete))
                            // openDialog({message: 'You will lose the information of this event', buttonText: 'DELETE'}, () => deleteEvents(event.id) )
                        },
                        name: "Delete"},
                    ]}
            	/>
            )
		}
        return _speakers;
	}

	const updateSearch = (value) => {
		setStateSpeakers({search: value})
	}
	
	return (
		<main>
			<Meta title="Event Speakers" />
			{ !empty() ? (
				<React.Fragment>
					<header className={style.toolbar}>
						<ButtonChangeView visible={false} />
						<Search method={(e) => updateSearch(e)} />
					</header>
					<section className={c(style.container, showList.view && style.list)}>
						{ loaderSpeakers() }
					</section>
				</React.Fragment>
				) : (
					<section className={style.infoEmpty}>
						<p className={style.title}>There are still no speaker for this event</p>
						<i className={style.icon}></i>
					</section>
				)
			}
		</main>
	);
}

export default EventSpeakers;