import axios from "axios";
import {
  READ_COMMUNITY,
  READ_COMMUNITIES,
  SUCCESS_CREATE_COMMUNITY,
  ERROR_CREATE_COMMUNITY,
  ERROR_READ_COMMUNITY,
  ERROR_READ_COMMUNITIES
} from '../config/constants'
import { URL_BASE_API, COMMUNITY_PATH } from '../config/api'
const token = localStorage.getItem('token')

////////////Community Create/////////////

function create_community (create) {
  return {
    type: SUCCESS_CREATE_COMMUNITY,
    create
  }
}
function error_create_community (error_create) {
  return {
    type: ERROR_CREATE_COMMUNITY,
    error_create
  }
}
function fetch_create_community (data) {
  return axios.post(`${URL_BASE_API}${COMMUNITY_PATH}`, data, {
    headers: {
      skip: true
    }
  })
}
const action_create_community = data => {
  return dispatch => {
    return fetch_create_community(data)
      .then(data => dispatch(create_community(true)))
      .catch(error_create => dispatch(error_create_community(error_create)))
  }
}

////////////Community Read/////////////

function error_read_community (error_read) {
  return {
    type: ERROR_READ_COMMUNITY,
    error_read
  }
}

function error_read_communities (error_read) {
  return {
    type: ERROR_READ_COMMUNITIES,
    error_read
  }
}

const action_read_communities = () => {
  return dispatch => {
    axios
      .get(`${URL_BASE_API}${COMMUNITY_PATH}`, {
        headers: {
          skip: true
        }
      })
      .then(response => {
        dispatch({
          type: READ_COMMUNITIES,
          communities: response.data.payload
        })
      })
      .catch(error_read => dispatch(error_read_communities(error_read)))
  }
}

const action_read_community = id => {
   return dispatch => {
    axios
      .get(`${URL_BASE_API}${COMMUNITY_PATH}/${id}/${id}`, {
        headers: {
          skip: true,
          Authorization: "bearer"+token
        }
      })
      .then(response => {
        dispatch({
          type: READ_COMMUNITY,
          community: response.data.payload
        })
      })
      .catch(error_read => dispatch(error_read_community(error_read)))
  }
}

export {
  action_read_communities,
  action_create_community,
  action_read_community
}
