import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";

import { REDIRECT_PATH } from '../config/routes';

const PrivateRoute = ({ component: Component, ...props }) => {
  const auth = useSelector((state) => state.signin.auth);
  const token = useSelector((state) => state.signin.access_token);

  useEffect(() => {
    if (!token) {
      localStorage.removeItem('token')
    } else {
      localStorage.setItem('token', token)
    }
  });
  return (
    <Route {...props} render={props => !auth
      ? (
        <Redirect to={REDIRECT_PATH} />
      )
      : (
        <Component {...props} />
      )}
    />
  );
}
export default PrivateRoute