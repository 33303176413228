import React, {Component} from 'react';
import style from '../styles/search.module.scss';
import { c } from '../helpers';

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      actived: false,
      value: ''
    }
    this.toggle = this.toggle.bind(this)
    this.updateText = this.updateText.bind(this)
  }
  toggle() {
    this.setState({actived: !this.state.actived})
  }
  updateText(e) {
    this.props.method(e.target.value)
  }
  expander() {
    return this.state.actived && style.expander
  }
  render() {
    return (
      <div className={c(style.search, this.expander())}>
        <button className={style.btn} onClick={this.toggle}>
          <i></i>
        </button>
        <input type="search" placeholder="search..." onKeyUp={this.updateText.bind(this)}/>
      </div>
    )
  }
}

export default Search