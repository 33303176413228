import React, { useEffect, useCallback } from 'react';
import { useDispatch } from "react-redux";
import style from "../styles/modalViewPost.module.scss";
import { actionShowDetails } from '../actions/action_styles';

const ModalViewPost = ({ children }) => {

  const dispatch = useDispatch();

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      dispatch(actionShowDetails(false))
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);


  return (
    <React.Fragment>
      <div className={style.row}>
        <i className={style.iconClose}
          onClick={() => dispatch(actionShowDetails(false))}>
        </i>
      </div>
      <div className={style.background}  >
        <div className={style.box}>
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ModalViewPost;