import {
  READ_TRACKS,
  READ_TRACK,
  SUCCESS_TRACK_CREATE,
  SUCCESS_TRACK_UPDATE,
  SUCCESS_TRACK_DELETE,
  ERROR_TRACK_READ,
} from '../config/constants'

const initialState = {
  tracks: []
}

export const reducerTracks = (state = initialState, action) => {

  switch (action.type) {
    case READ_TRACKS:
      return { ...state, tracks: action.tracks }
      
    case READ_TRACK:
      return { ...state, track: action.track }

    case SUCCESS_TRACK_CREATE || SUCCESS_TRACK_UPDATE || SUCCESS_TRACK_DELETE:
      return { ...state }

    case ERROR_TRACK_READ:
      return { ...state, error_read: action.error_read }

    default:
      return state
  }

}