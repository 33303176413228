import { ERROR_SIGNUP, SUCCESS_SIGNUP } from '../config/constants';


let initial_state = {
  signup: false,
}

export default function reducerSignup (state = initial_state, action){
  switch (action.type){
    case SUCCESS_SIGNUP:
      return { ...state, signup: true }
    default:
      return state
  }

}