import React, { useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { action_log_out } from '../actions/action_signin';
import style from '../styles/menuBar.module.scss';
import { c } from '../helpers';
import {
	EVENTS_ROUTE,
	WEBSITE_ROUTE,
	VIDEOCALL_ROUTE,
	DASHBOARD_ROUTE,
	PROFILE_ROUTE,
	BLOGS_ROUTE,
	FORUMS_ROUTE,
	REDIRECT_PATH
} from '../config/routes';

const MenuBar = () => {
	const [activeMenu, setActiveMenu] = useState(false);
	const [activeDrop, setActiveDrop] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const logout = useCallback(() => {
		dispatch(action_log_out);
		setTimeout(() => history.push(REDIRECT_PATH), 500);
	}, [dispatch, history]);

	const toggleMenu = () => setActiveMenu(!activeMenu);
	const toggleDrop = () => setActiveDrop(!activeDrop);
	const goTohome =()=>{
		history.push(DASHBOARD_ROUTE)
		setActiveDrop(false)
	}
	return (
		<div className={style.menubar}>
			<div className={style.menuSide}>
				<div className={style.menuBtn} onClick={toggleMenu} />
				<div className={c(style.menu, activeMenu && style.show)} onClick={toggleMenu}>
					<Link to={EVENTS_ROUTE} className={style.eventBtn}>
						<div className={style.icon} />
						<div className={style.text}>Event</div>
					</Link>
					<Link to={BLOGS_ROUTE} className={style.blogBtn}>
						<div className={style.icon} />
						<div className={style.text}>Blog</div>
					</Link>
					<Link to={FORUMS_ROUTE} className={style.forumBtn}>
						<div className={style.icon} />
						<div className={style.text}>Forum</div>
					</Link>
					<Link to={WEBSITE_ROUTE} className={style.websiteBtn}>
						<div className={style.icon} />
						<div className={style.text}>Website</div>
					</Link>
					<Link to={VIDEOCALL_ROUTE} className={style.videocallBtn}>
						<div className={style.icon} />
						<div className={style.text}>Videocall</div>
					</Link>
				</div>
			</div>
			<div className={style.img} onClick={goTohome}/>
			<div className={style.toolBar}>
				<div className={style.conf} />
				<div className={style.home} onClick={goTohome} />
				<div className={style.message} />
				<div className={style.notif} />
				<div className={style.profile}>
					<div
						className={style.profBtn}
						onClick={toggleDrop}
					/>
				</div>
			</div>
			{activeDrop &&
				<div className={style.divProfile}>
					<button onClick={() => history.push(PROFILE_ROUTE)}>User profile</button>
					<button onClick={logout}>Close Session</button>
				</div>}
		</div>
	);
};

export default MenuBar;
