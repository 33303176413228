import React, { useState, useEffect } from 'react';
import axios from 'axios';
import style from "../styles/countryList.module.scss";
import { c } from '../helpers';

const CountryList = ({ disable, disableStyle, form, setForm }) => {

  const [countryStyles, setCountryStyles] = useState(
    {
      showList: false,
      iconExpand: style.iconExpandM,
      iconGender: '',
    })
  const { showList, iconExpand } = countryStyles
  const [list, setList] = useState([]);

  const [filtered, setFiltered] = useState([]);
  const updateInput = e => {
    setForm({ ...form, country: e.target.value });
  }

  const { country } = form

  const handleChange = e => {
    let currentList = [];
    let newList = [];
    setForm({ ...form, country: e.target.value });
    if (e.target.value !== '') {
      setCountryStyles(
        {
          showList: true,
          iconExpand: style.iconExpandL,
          iconExpandColor: style.iconBlueDark
        })
      currentList = list;
      newList = currentList.filter(item => {
        const lc = item.name.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return lc.includes(filter);
      });
    } else {
      newList = list;
    }
    if (e.target.value === '') {
      newList = list;
      setCountryStyles({
        showList: !showList,
        iconExpand: style.iconExpandM
      })
    }
    setFiltered(newList);
  }
  const hideShow = () => {
    let oldStateMl = iconExpand;
    let oldStateSh = showList;
    let isExpand = oldStateMl === style.iconExpandM;
    let isShowOrHide = !oldStateSh;
    let newStateMl = isExpand
      ? style.iconExpandL
      : style.iconExpandM;

    let newShowOrhide = isShowOrHide
      ? true
      : false;

    setCountryStyles(
      {
        iconExpand: newStateMl,
        showList: newShowOrhide,
        iconExpandColor: style.iconBlueDark,
        iconPublic: style.iconBlue,
        iconGender: style.iconBlue
      });
  }
  useEffect(() => {
    axios.get('https://restcountries.eu/rest/v2/all')
      .then((res) => {
        setFiltered(res.data)
        setList(res.data);
      });
  }, [])
  return (
    <React.Fragment>
        <label htmlFor="country">Country</label>
        <div className={style.divInputIcon}>
          <input
            disabled={disable}
            className={c(disableStyle, style.inputIcon)}
            onChange={handleChange}
            onMouseDown={handleChange}
            name="country"
            value={country}
            type="text"
            placeholder="Country"
            onKeyUp={updateInput}
          />
          <div className={style.divIcons}>
            <i
              onMouseDown={hideShow}
              className={iconExpand} />
          </div>
        </div>
        {
          showList &&
          !disable && (
            <div className={style.inputSelect}>
              {filtered.length >= 1 &&
                filtered.map((item, i) => (
                  <span
                    className={style.list}
                    value={item.name}
                    key={i}
                    onMouseDown={() => setForm({ ...form, country: item.name })}
                    onClick={() => setCountryStyles({
                      showList: !showList,
                      iconExpand: style.iconExpandM,
                      iconExpandColor: '',
                      iconGender: ''
                    })}
                    onMouseEnter={() => setForm({ ...form, codePhone: item.callingCodes })}
                  >
                    {item.name}
                  </span>
                ))}
            </div>
          )
        }
    </React.Fragment>
  )
}

export default CountryList;