import React, { useState, useRef } from 'react';
import { useSelector } from "react-redux";
import style from "../styles/userDashboard.module.scss";
import LeftAsideUserDashboard from '../components/leftAsideUserDashboard';
import RightAsideUserDashboard from '../components/rightAsideUserDashboard';
import CenterUserDashboard from '../components/centerUserDashboard';
import ToTop from '../components/toTop';
import Meta from '../components/metaContent';

const UserDashboard = props => {
  const newsSelector = useSelector((state) => state.news.news);
  const forumSelector = useSelector((state) => state.forum.forum);
  const [showToTop, setShowToTop] = useState(false);
  const [scroll, setScroll] = useState(0);
  const scrollY = useRef(null);
  const onScroll = () => {
    setShowToTop(true)
    setScroll(scrollY.current.scrollTop)
  }
  return (
    <>
      <Meta title="Home" />
      <div className={style.userDashboard}>
        <section ref={scrollY} className={style.container} onScroll={onScroll}>
          <LeftAsideUserDashboard news={newsSelector} />
          <CenterUserDashboard />
          <RightAsideUserDashboard forum={forumSelector} />
          <ToTop scroll={scroll} showToTop={showToTop} />
        </section>
      </div>

    </>
  )
}

export default UserDashboard;