import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from '../../styles/register.module.scss';
import { c } from '../../helpers';


const SelectInput = ({ name, placeholder, options, onChange }) => {
    const [item, setItem] = useState({ name: placeholder, value: null});
    const [active, setActive] = useState(false);

    const iconExpand = () => active ? style.iconExpandL : style.iconExpandM;
    const iconExpandColor = () => active && style.iconBlueDark;
    const iconActive = () => active && style.iconBlue;

    const handleSelect = item => {
        setItem(item);
        onChange(name, item.value, true);
        setActive(false);
    }

    const onBlur = () => setTimeout(() => setActive(false), 200);

    return (
        <div onFocus={() => setActive(true)}
        onBlur={onBlur}>
            <div
                className={style.inputGroup}>
                <i className={c(style.iconHdr, iconActive())} />
                &nbsp;
                <select name={name} className={style.hide}>
                    {options.map((opt, i) => <option key={i} value={opt.value}>{opt.name}</option>)}
                </select>
                <span tabIndex={0} className={c(style.inputBlue, style.spanInput, !item.value && style.spanInputPlaceholder)} onClick={() => setActive(true)}>{item ? item.name : ''}</span>
                <i
                    className={c(iconExpand(), iconExpandColor())} />
            </div>
            <ul onBlur={onBlur} className={c(style.gender, style.inputSelect, !active && style.hideSelect)}>
                {options
                    .map((opt, i) => (
                        <li key={i}
                            className={style.list}
                            onClick={() => handleSelect(opt)}>
                                {opt.name}
                        </li>
                ))}
            </ul>
        </div>
    );
}

SelectInput.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func
}

SelectInput.defaultProps = {
    name: 'select_input',
    placeholder: ''
}


export default SelectInput;
