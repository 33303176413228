import React, { useState, useEffect } from 'react';
import style from '../styles/loginForm.module.scss';
import {action_signin} from '../actions/action_signin';
import { c, isValidEmail } from '../helpers';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LoginError from './loginError';
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from '../config/routes';


const isValidPassword = passwd => passwd.length > 0;

const LoginForm = () => {
	let history = useHistory();
	const dispatch = useDispatch();
	const error = useSelector((state) => state.signin.error);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [forgotPassword, setForgotPassword] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [forgotPasswordSent, setForgotPasswordSent] = useState(false);
	const [invalid, setInvalid] = useState(false);


	useEffect(() => {
		const invalidPassword = isValidEmail(email) && !isValidPassword(password);
		setInvalid(invalidPassword);
	}, [password]);

	const handleSubmit = event => {
		event.preventDefault();
		if (!isValidForm()) {
			return;
		}
		dispatch(action_signin(email, password))
			.then(() => history.push(DASHBOARD_ROUTE))
			.catch(signinError => {
				history.replace(LOGIN_ROUTE)
			});
	}

	const toggleForgotPassword = () => setForgotPassword(!forgotPassword);

	const submitForgotPassword = () => setForgotPasswordSent(!forgotPasswordSent);

	const isValidForm = () => {
		/* needs validation */
		return email && password;
	}

	return (
		<form className={style.form} onSubmit={handleSubmit}>
			<div className={c(style.passError, style.active)}>
				{error&&
					<LoginError message={error} />
				}
				{invalid && !error &&
					<LoginError message="Password is required" />
				}
			</div>
			<div className={style.inputGroup}>
				<div className={style.email}>
					<i className={email && style.active} />
					<input
						onChange={e => setEmail(e.target.value)}
						placeholder="Email"
						type="text"
					/>
				</div>
				<div className={style.password}>
					<i className={password && style.active} />
					<input
						onChange={e => setPassword(e.target.value)}
						placeholder="Password"
						type="password"
					/>
				</div>
			</div>
			<div className={style.options}>
				<div className={style.remember}>
					<input type="checkbox" value={rememberMe} onChange={e => setRememberMe(e.target.value)} />
				Remember me
			</div>
				<div onClick={toggleForgotPassword} className={style.forgot}>
					I forgot my password
			</div>
			</div>
			<button className={c(style.button, isValidForm() && style.active)} onClick={handleSubmit}>
				LOGIN
		</button>
			<div className={c(style.forgotModal, forgotPassword && style.show)}>
				<span onClick={toggleForgotPassword} className={style.closeModal}></span>

				<div className={c(style.icon, forgotPasswordSent && style.sent)}></div>
				{forgotPasswordSent ?
					(<ForgotPasswordSent />) :
					(<ForgotPasswordForm onSubmit={submitForgotPassword} />)
				}
			</div>
		</form>
	);
}


const ForgotPasswordForm = (props) => (
	<div>
		<div className={style.text}>
			We will send you a recovery message <br />
			Please enter your email
		</div>
		<div className={style.emailForm}>
			<input
				className={style.input}
				type="text"
				placeholder="email@email.com"
			/>
			<button
				className={style.button}
				onClick={props.onSubmit}
			>
				SEND EMAIL
			</button>
		</div>
	</div>
);

const ForgotPasswordSent = () => (
	<div>
		<div className={style.subt}>
			Email sent
		</div>
		<div className={style.msg}>
			Remember to check your spam tray if you do not find our message
		</div>
	</div>
);

export default LoginForm;
