import { useState } from 'react';
import { getCountryList } from '../../services/countries';

export function useForm(initialState = {}, callback = () => {}) {
    const [inputs, setInputs] = useState(initialState);
    
    const isValid = () => {
        const nonValidInput = Object.keys(inputs).findIndex(key => inputs[key] && !inputs[key].valid);

        return nonValidInput === -1;
    }

    const handleSubmit = event => {
        if(event) {
            event.preventDefault();
        }

        if(isValid()) {
            callback();
        }
    }

    const handleInputChange = (name, value, valid = true) => {
        setInputs(inputs => ({
            ...inputs,
            [name]: {
                value,
                valid
            }
        }));
    }

    const reset = () => setInputs(initialState);

    return {
        inputs,
        reset,
        isValid,
        handleSubmit,
        handleInputChange
    }
}

export function useCountryList() {
    const initialState = [];
    const [countryList, setCountryList] = useState(initialState);
    const [fetchingCountry, setFetchingCountry] = useState(false);

    const fetchCountryList = async () => {
        setFetchingCountry(true);
        try {
            const list = await getCountryList();
            setCountryList(list.map(l => {
                l.value = l.alpha3Code
                return l;
            }));
        } catch (err) {
            setCountryList(initialState);
        } finally {
            setFetchingCountry(false);
        }
    };
    
    if(countryList === initialState && !fetchingCountry) fetchCountryList();

    return countryList;
}