const { REACT_APP_API_ENV } = process.env;

const API_HOST = REACT_APP_API_ENV === 'qa' ?
    'https://qa-kommu.herokuapp.com' :
    REACT_APP_API_ENV === 'production' ?
    'https://qa-kommu.herokuapp.com' :
    'http://localhost:8000';



export const URL_BASE_API = `${API_HOST}/api/`

export const URL_BASE = `${API_HOST}/`
export const URL_BASE_RESOURCES = `${URL_BASE}/uploads`

// we use path relative i.e the below variables

export const SIGNIN_PATH = 'auth'
export const USER_PATH = 'user'
export const SIGNUP_PATH = 'register'
export const COMMUNITY_PATH = 'community'
export const EVENT_PATH = 'event'
export const POST_PATH = 'post'
export const COMMENT_PATH = 'comment'
export const ROOM_PATH = 'room'
export const TRACK_PATH = 'track'
export const SPONSOR_PATH = 'sponsor'
export const TYPE_SPONSOR_PATH = 'type_sponsor'
export const TYPE_PARTNERSHIP_PATH = 'partnership'
export const PARTNERSHIP_POSITION_PATH = 'partnership_position'
export const EXHIBITOR_PATH = 'exhibitor'
export const TYPE_BOOTH_PATH = 'type_booth'
export const TICKET_PATH = 'ticket'
export const ATTENDES_PATH = 'attendee'
export const LIKE_PATH = 'like'
export const PROFILE_PATH = 'profile'
export const SPEAKER_PATH = 'speaker'
