import React, { Component } from 'react';
import { connect } from 'react-redux';
import { c } from '../helpers';
import Meta from '../components/metaContent';
import style from '../styles/tracks.module.scss';
import { get_tracks, post_tracks, put_tracks, delete_tracks } from '../actions/action_tracks.js';
import CardTicket from '../components/cardTicket';
import Button from '../components/button';
import Search from '../components/search';

class Tracks extends Component{

    constructor(props){
        super(props);
        this.state = {
            idEvent: this.props.match.path.match(/\d+/)[0],
            search: ''
        }
        // global.Tracks = this
    }

	componentDidMount() {
        this.getTrack();
    }
    getTrack(){
        return this.props.get_tracks()
    }
    empty(){
        return this.props.tracks.length === 0;
    }
    loadTracks(){
        let tracks = [];
        for (let i = 0; this.props.tracks.length > i; i++){
            const track = this.props.tracks[i];
            tracks.push(
                <CardTicket 
                    key={track.id}
                    id={track.id}
                    info={track}
                    title={track.name}
                    description={track.description}
                    isVailable={track.active}
                    selected={ () => this.cardSelected(track)}
                    search={this.state.search}
                    filter={"all"}
                    fnAction={[
                        {fn: () =>{
                            // openDialog({message: 'You will lose the information of the tracks', buttonText: 'DELETE'}, () => this.deleteTracks(track.id))
                        }, name: "Delete"},
                    ]}
                    subDescription={[]}
            />
            )
        }
        return tracks;
    }
    type() {
        let status = this.props.info.confirmed
        if (status) return 'filled'
        else return 'outlined'
    }
	updateSearch(value) {
		this.setState({search: value})
    }    
	cardSelected(dataCard){
        // openSheetSide({
        //     overflow: false,
        //     title: {placerholder: 'Track name', edit: true, value: dataCard.name},
        //     fields: {
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert description', value: dataCard.description},
        //         active: { tag: 'input', type: 'checkbox', label: 'track active', value: dataCard.active}
        //         }
        // }, data => {
        //     data.name = data.title;
        //     data.module_name = "session";
        //     LoaderModule.start();
        //     this.props.put_tracks(dataCard.id, data)
        //     .then(res => {
        //         this.getTrack();
        //         LoaderModule.end();
        //     });            
        // })
	}
	deleteTracks(idTrack){
        // LoaderModule.start();
        // this.props.delete_tracks(idTrack)
        //     .then(res => {
        //         this.getTrack();
        //         LoaderModule.end();
        //     });
    }
    newTrack(){
        // openSheetSide({
        //     title: {placerholder: 'Track name', edit: true, value: ''},
        //     fields: {
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert description', value: ''},
        //         active: { tag: 'input', type: 'checkbox', label: 'track active', value: false}
        //         }
        // }, data => {
        //     data.name = data.title;
        //     data.module_name = "session";
        //     LoaderModule.start();
        //     this.props.post_tracks(data)
        //     .then(res => {
        //         this.getTrack();
        //         LoaderModule.end();
        //     });            
        // })
    }
    render() {  
        return (
            <main>
                <Meta title="Tracks" />
                { !this.empty() ? (
                    <section className={style.container}>
                        <header className={style.toolbar}>
                            <Search method={this.updateSearch.bind(this)}/>
                            <Button type={'filled'} onClick={e => this.newTrack()}>
                                { 'new' }
                            </Button>
                        </header>
                        <section>
                            {
                                this.loadTracks()
                            }
                        </section>
                    </section>
                   ) : (
                        <section className={style.infoEmpty}>
                            <p className={style.title}>There are still no tracks for this event</p>
                            <i className={style.icon}></i>
                            <button 
                                className={c(style.btn, this.state.newTrack && style.hidden)}
                                type="button"
                                onClick={e => this.newTrack()}
                            >
                                create
                            </button>
                        </section>
                   )
                }
            </main>
        );
    }
}

const mapDispatchToProps =  {
    get_tracks,
    post_tracks,
    put_tracks,
    delete_tracks
};
const mapStateToProps = state => {

	return {
		tracks: state.tracks.tracks
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tracks);