import {
  READ_TYPE_SPONSORS,
  READ_TYPE_SPONSOR,
  SUCCESS_TYPE_SPONSOR_CREATE,
  SUCCESS_TYPE_SPONSOR_UPDATE,
  SUCCESS_TYPE_SPONSOR_DELETE,
  SEARCH_TYPE_SPONSORS,
  ERROR_TYPE_SPONSOR_READ
} from '../config/constants'

const initialState = {
  type_sponsors: [],
  type_sponsor: [],
  search: [],
  create: [],
  update: [],
  error_create: 'error',
  error_read: 'error'
}

export const reducerTypeSponsors = (state = initialState, action) => {

    switch (action.type) {
      case READ_TYPE_SPONSORS:
        return { ...state, type_sponsors: action.type_sponsor }

      case READ_TYPE_SPONSOR:
        return { ...state, type_sponsor: action.type_sponsor }

      case SEARCH_TYPE_SPONSORS:
        return { ...state, search: action.search }

      case SUCCESS_TYPE_SPONSOR_CREATE:
        return { ...state, create: action.create }

      case SUCCESS_TYPE_SPONSOR_UPDATE:
        return { ...state, update: action.update }

      case SUCCESS_TYPE_SPONSOR_DELETE:
        return { ...state, delete: action.delete }

      case ERROR_TYPE_SPONSOR_READ:
        return { ...state, error_read: action.error_read }

      default:
        return state
    }

}
