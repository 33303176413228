import React, { Component } from 'react';
import style from '../styles/cardTicket.module.scss';
import CardListOptions from './cardListOptions';
import CardSubDescription from './cardSubDescription';
import { c } from '../helpers';

class CardTicket extends Component {

    constructor(props){
        super(props);
        this.state = {
            status: this.loadStatus(),
            hidden: false,
            selected: null,
            id: null
        }
    }

    loadStatus(){
        console.log(this.props);
        let status = []
        for (let i = 0; i < this.props.info.length; i++) {
            status.push(false)
        }
        return status
    }

    filter() {
        let {filter} = this.props
        let {quantity_current} = this.props.info
        if (filter === 'all') return true
        if (filter === 'avaliable') return quantity_current > 0
        if (filter === 'sold out') return quantity_current === 0
        return false 
    }

    search() {
        let {search} = this.props
        let {name} = this.props.info
        if (name.toLowerCase().search(search) !== -1) return true
        else return false
    }

    hide() {
        return !(this.search() && this.filter()) && style.hidden
    }
    deselectedCard(){
        let {status} = this.state;
        status.forEach((actived, i) => {
            if(actived) status[i] = false            
        })
        this.setState({status})
    }
    selectedCard(event, id){
        this.setState({
            selected: !this.state.selected,
            target: event.currentTarget,
            id
        })

        window.onclick = e => {
            console.log(event);

            let find = e.path.find( el => el === this.state.target);

            if(find === undefined){
                this.setState({selected: false, id: null})
                window.onclick = null;
            }

        }

        this.props.selected(this.props.info);

    }

    render(){
        return(
        <article
            className={c(style.cardTicket, this.state.selected && style.selected, this.hide(), !this.props.isVailable  && style.inactive)}
            onClick={e => this.selectedCard(e, this.props.id)}
        >
            <header>
                <h3>{this.props.title}</h3>
                <span>inactive</span>
                <CardListOptions options={this.props.fnAction} />
            </header>
            <p>{this.props.description}</p>
            <CardSubDescription 
                emphasis={style.emphasis}
                infos={this.props.subDescription}
            />

        </article>
        )

    }

}

export default CardTicket;