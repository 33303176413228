import React from 'react';
import Events from '../screens/events';
import NewEvent from '../screens/newEvent';
import Event from '../screens/event';
import { Switch, Route } from 'react-router-dom';
import { EVENTS_ROUTE, NEW_EVENT_ROUTE, EVENT_ROUTE, SEARCH_ROUTE } from '../config/routes';

const EventRouter = (props) => {
    return (
        <Switch>
            <Route exact path={EVENTS_ROUTE} component={Events} />
            <Route exact path={SEARCH_ROUTE} component={Events} />
            <Route path={NEW_EVENT_ROUTE} component={NewEvent} />
            <Route path={EVENT_ROUTE} component={Event} />
        </Switch>
    )
}

export default EventRouter;