import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { get_sponsors, get_sponsor, post_sponsors, put_sponsors, delete_sponsors } from '../actions/action_sponsor.js';
import { get_type_sponsors, get_type_sponsor, post_type_sponsors, put_type_sponsors } from '../actions/action_type_sponsor.js';
import { get_partnerships, get_partnership, post_partnerships, put_partnerships } from '../actions/action_partnership.js';
import { get_partnership_positions, get_partnership_position, post_partnership_positions, put_partnership_positions } from '../actions/action_partnership_position.js';

import Meta from '../components/metaContent';
import style from '../styles/sponsors.module.scss';
import CardSponsors from '../components/cardSponsors';
import Button from '../components/button';
import Search from '../components/search';
import { c, urlFor } from '../helpers';
import db from '../data/sponsors.json';

class Sponsors extends Component {
    constructor(props){
        super(props);
        this.state = {
            idEvent: this.props.match.path.match(/\d+/)[0],
            sponsors: [],
            type_sponsor: [],
            partnership: [],
            partnership_position: [],
            card_sponsors: [],
            countSponsors: 0,
            currency: db.currency.map(el => el.symbol),
            newSponsor: false,
            isCategoryActive: true,
            search: '',
            filter: '',
        }
        // global.Sponsors = this
    }
	componentDidMount() {
        Promise.all([this.getAll()]).then(([res]) => {
            const _category = this.state.type_sponsor;
            if(_category.length > 0) {
                this.changeFilter(_category[0].id);
                this.setState({isCategoryActive: _category[0].active});
            }
        });
    }
    orderSort(data, compare){
        return data.sort((a, b) => a[compare] - b[compare]);
    }
    getAll(){
        const _sponsor = this.getSponsors();
        const _typeSponsor = this.getTypeSponsor();
        const _partnerships = this.getPartnerships();
        const _partnershipPositions = this.getPartnershipPositions();
        return Promise.all([_sponsor, _typeSponsor, _partnerships, _partnershipPositions]).then(([sponsors, typeSponsors, partnerships, partnershipPositions]) => {
            let _cardSponsors = [];
            for(let i = 0; i < sponsors.length; i++ ){
                const _idTypeSponsor = sponsors[i].id_type_sponsor;
                const _idPartnership = sponsors[i].id_partnership;
                const _idSponsor     = sponsors[i].id;
                const type_sponsor = typeSponsors.filter( typeSponsor => _idTypeSponsor ==  typeSponsor.id);
                const partnership = partnerships.filter( partnership => _idPartnership ==  partnership.id);
                const _dataParnetship = partnership[0];
                const partnershipPosition = partnershipPositions.filter( partnershipPosition => _idPartnership ==  partnershipPosition.id_partnership);
                const _data = {
                        id: _idSponsor,
                        id_partnership: _idPartnership,
                        name: _dataParnetship.name,
                        description: _dataParnetship.description,
                        host: _dataParnetship.host,
                        logo: urlFor(_dataParnetship.logo),
                        registry_number: _dataParnetship.registry_number,
                        url: _dataParnetship.url,
                        active: _dataParnetship.active,
                        createdAt: _dataParnetship.createdAt,
                        updatedAt: _dataParnetship.updatedAt,
                        type_sponsor: type_sponsor[0],
                        partnershipPosition: partnershipPosition[0]
                };
                _cardSponsors.push(_data);
              }
              return this.setState({
                    partnership: partnerships,
                    partnership_position: partnershipPositions,
                    card_sponsors: _cardSponsors
                }, () => this.countSponsorsCategory())
          });
    }
    getSponsors(){
        return this.props.get_sponsors()
            .then(sponsors => {
                const _filterSponsor = sponsors.filter( sponsor => sponsor.id_event === this.state.idEvent)
                this.setState({sponsors: this.orderSort(_filterSponsor, "id")})
                return _filterSponsor
            });
    }
    getTypeSponsor(){
        return this.props.get_type_sponsors()
            .then(type_sponsor => {
                this.setState({type_sponsor: this.orderSort(type_sponsor, "id")})
                return type_sponsor
            });
    }
    empty(){
        if(this.state.sponsors.length == 0) return true
        else return false
    }
    getPartnerships(){
        return this.props.get_partnerships()
        .then( partnership => {return partnership})
    }
    getPartnershipPositions(){
        return this.props.get_partnership_positions()
        .then( partnership_positions => { return partnership_positions })
    }
    loadSponsors(){
        let sponsors = [];
        for (let i = 0; this.state.card_sponsors.length > i; i++){
            const sponsor = this.state.card_sponsors[i];
            sponsors.push(
                <CardSponsors 
                    key={i}
                    id={sponsor.id}
                    info={sponsor}
                    title={sponsor.name}
                    description={sponsor.description}
                    isVailable={sponsor.active}
                    selected={ () => {}}
                    search={this.state.search}
                    filter={this.state.filter}
                    fnAction={[
                        {fn: () => {
                            // openDialog({message: 'You will lose the information of the sponsors', buttonText: 'DELETE'}, () => this.deleteSponsors(sponsor))
                        }, name: "Delete"},
                        {fn: () => {
                            // this.editSponsors(sponsor)
                        }, name: "Edit"},
                    ]}
                    subDescription={[]}
            />
            )
        }
        return sponsors;
    }
    newSponsors(){
        // let {type_sponsor} = this.state;
        // const _type_sponsor = type_sponsor.map(el => el.name);
        // openSheetSide({
        //     title: {placerholder: 'Sponsor name', edit: true, value: ''},
        //     fields: {
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert description', value: ''},
        //         contact_name: { tag: 'input', label: 'Contact Name', placerholder: 'Insert contact name', width: '50%', value: ''},
        //         email: { tag: 'input', label: 'Email', placerholder: 'Insert email name', width: '50%', value: ''},
        //         contact_position: { tag: 'input', type: 'number', label: 'Contact position', placerholder: 'Insert contact position', width: '50%', value: ''},
        //         level_sponsorship: { tag: 'select', label: 'Level Sponsorship', placerholder: 'Insert contact position', width: '50%', value: '', options: _type_sponsor, actions: [{icon: 'add', method: (schema, setSchema) => this.newLevelSponsors(schema, setSchema)}, {icon: 'blog', method: (schema, setSchema) => this.editLevelSponsors('level_sponsorship', schema, setSchema)}]},
        //         url: { tag: 'input', label: 'URL', value: '' },
        //         logo: { tag: 'upload', label: 'Logo', value: ''}
        //     }
        // }, data => {
        //     LoaderModule.start();
        //     const _partner = {
        //         name: data.title,
        //         description: data.description,
        //         url: data.url,
        //         registry_number: "",
        //         host: "",
        //         logo: data.logo,
        //         active: true
        //     };
        //     const formDataPartnership = new FormData();    
        //     for (const key in _partner) formDataPartnership.append(key, _partner[key]);
        //     this.props.post_partnerships(formDataPartnership)
        //         .then( res_partnership => {
        //             const parnerPosition = {
        //                 id_partnership: res_partnership.id,
        //                 name_contact: data.contact_name,
        //                 email: data.email,
        //                 job_title: "",
        //                 description: "",
        //                 phone: "",
        //                 active: false,
        //             }

        //             this.props.post_partnership_positions(parnerPosition)
        //                 .then(() => {
        //                     const { idEvent } = this.state;
        //                     const _dataTypeSponsor =  this.findLevelSponsors(data.level_sponsorship);
        //                     const _dataSendSponsor = {
        //                         id_event: idEvent,
        //                         id_partnership: res_partnership.id,
        //                         id_type_sponsor: _dataTypeSponsor ? _dataTypeSponsor.id : type_sponsor[0].id,
        //                     }
        //                     this.props.post_sponsors(_dataSendSponsor).then(() => {
        //                         this.getAll();
        //                         LoaderModule.end();
        //                     })

        //                 })
        //         })
        // })
    } 
    editSponsors(dataCard){
        // let {type_sponsor} = this.state;
        // const _type_sponsor = type_sponsor.map(el => el.name);
        // openSheetSide({
        //     overflow: false,
        //     title: {placerholder: 'Sponsor name', edit: true, value: dataCard.name},
        //     fields: {
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert description', value: dataCard.description},
        //         contact_name: { tag: 'input', label: 'Contact Name', placerholder: 'Insert contact name', width: '50%', value: dataCard.partnershipPosition.name_contact},
        //         email: { tag: 'input', label: 'Email', placerholder: 'Insert email name', width: '50%', value: dataCard.partnershipPosition.email},
        //         contact_position: { tag: 'input', type: 'number', label: 'Contact position', placerholder: 'Insert contact position', width: '50%', value: dataCard.partnershipPosition.id},
        //         level_sponsorship: { tag: 'select', label: 'Level Sponsorship', placerholder: 'Insert contact position', width: '50%', value: dataCard.type_sponsor.name, options: _type_sponsor, actions: [{icon: 'add', method: (schema, setSchema) => this.newLevelSponsors(schema, setSchema)}, {icon: 'blog', method: (schema, setSchema) => this.editLevelSponsors('level_sponsorship', schema, setSchema)}]},
        //         url: { tag: 'input', label: 'URL', value: dataCard.url },
        //         logo: { tag: 'upload', label: 'Logo', value: dataCard.logo}
        //         }
        // }, data => {
        //     LoaderModule.start();

        //     const _partner = {
        //         id: dataCard.id_partnership,
        //         name: data.title,
        //         description: data.description,
        //         url: data.url,
        //         registry_number: dataCard.registry_number,
        //         host: dataCard.host,
        //         logo: data.logo,
        //         active: true,
        //         return_data: true
        //     };
        //     const formDataPartnership = new FormData();    
        //     for (const key in _partner) formDataPartnership.append(key, _partner[key]);

        //     this.props.put_partnerships(dataCard.id_partnership, formDataPartnership)
        //     .then((res_partnership) => {

        //         const isEdit = dataCard.partnershipPosition.name_contact != data.contact_name || dataCard.partnershipPosition.email != data.email;
        //         const isNew = data.contact_position != dataCard.partnershipPosition.id
        //         const parnerPosition = {
        //             id_partnership: dataCard.id_partnership,
        //             name_contact: data.contact_name,
        //             email: data.email,
        //             job_title: dataCard.partnershipPosition.job_title,
        //             description: dataCard.partnershipPosition.description,
        //             phone: dataCard.partnershipPosition.phone,
        //             active: dataCard.partnershipPosition.active,
        //         }
        //         const _dataTypeSponsor =  this.findLevelSponsors(data.level_sponsorship);

        //         if(isEdit || isNew){

        //             const _query = isNew ? this.props.post_partnership_positions(parnerPosition) : this.props.put_partnership_positions(dataCard.partnershipPosition.id, parnerPosition);

        //             _query.then(() => put_sponsor(_dataTypeSponsor.id))

        //         }else{
        //             put_sponsor(_dataTypeSponsor.id)
        //         }

        //     });

        // })

        // const put_sponsor = (id_type_sponsor) => {

        //     let _dataPut = {
        //         id_event: this.state.idEvent,
        //         id_partnership: dataCard.id_partnership,
        //         id_type_sponsor,   
        //     }

        //     this.props.put_sponsors(dataCard.id, _dataPut)
        //     .then(() => {
        //         this.getAll();
        //         LoaderModule.end();
        //     })
        // }
    }
	deleteSponsors(cardSponsor){
        // LoaderModule.start();
        // Promise.all([this.props.delete_sponsors(cardSponsor.id)])
        // .then(([deleteSponsor]) => {
        //     this.getAll();
        //     LoaderModule.end();
        // })
    }
    // Level Sponsorship
    newLevelSponsors(schema, setSchema){
        // let { currency } = this.state
        // openModal({
        //     title: {placerholder: 'Level of Sponsorship'},
        //     fields: {
        //         name: { tag: 'input', label: 'Name', placerholder: 'Insert name', value: ''},
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert description', value: ''},
        //         contribution_value: { tag: 'input', type: 'number', label: 'Price', placerholder: 'Insert price', value: '', select: { value: '', options: currency, width: '30%'}},
        //         active: { tag: 'input', type: 'checkbox', label: 'active', value: ''},
        //         }
        // }, data => {
        //     let json = schema
        //     json.fields.level_sponsorship.options.push(data.name)
        //     json.fields.level_sponsorship.value = data.name
        //     setSchema({...json})
        //     const _data = {...data};
        //     _data.currency = data.contribution_value.option ? data.contribution_value.option : currency[0];
        //     _data.contribution_value = data.contribution_value.value;
        //     _data.active = _data.active ? true : false;
        //     LoaderModule.start();
        //     this.props.post_type_sponsors(_data)
        //     .then(res => {
        //         this.getTypeSponsor();
        //         LoaderModule.end();
        //     });
        // })
    }
    editLevelSponsors(select, schema, setSchema){
        // let { currency } = this.state
        // const value = document.getElementById(select).value;
        // const _selected = this.findLevelSponsors(value);
        // openModal({
            
        //     title: {placerholder: 'Level of Sponsorship'},
        //     fields: {
        //         name: { tag: 'input', label: 'Name', placerholder: 'Insert name', value: _selected.name},
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert description', value: _selected.description},
        //         contribution_value: { tag: 'input', type: 'number', label: 'Price', placerholder: 'Insert price', value: _selected.contribution_value, select: { options: currency, value: _selected.currency, width: '30%'}},
        //         active: { tag: 'input', type: 'checkbox', label: 'active', value: _selected.active},
        //         }
        // }, data => {
        //     LoaderModule.start();

        //     if(schema || setSchema){
        //         let json = schema
        //         json.fields.level_sponsorship.options.push(data.name)
        //         json.fields.level_sponsorship.value = data.name
        //         setSchema({...json})
        //     }

        //     const _data = {...data};
        //     _data.currency = data.contribution_value.option ? data.contribution_value.option : currency[0];
        //     _data.contribution_value = data.contribution_value.value;

        //     this.props.put_type_sponsors(_selected.id, _data)
        //     .then(res => {
        //         this.getTypeSponsor();
        //         if (select == "selectorCategory" && data.active) this.setState({isCategoryActive: data.active})
        //         LoaderModule.end();
        //     });
        // })
    }
    findLevelSponsors(levelSponsor){
        const { type_sponsor } = this.state;
        return type_sponsor.find(el => el.name == levelSponsor);
    }
    handleImg(e, name){
        const _value        = e.target.value,
            _img            = e.target.files["0"];
        this.setState({
            [name]: _img,
            [`img${name}`]: _img
        }, () => {
            this.transformBase64(name);
        });
    }
    transformBase64(name) {
        if(this.state[`img${name}`] != null){
            let reader = new FileReader();
            reader.readAsDataURL(this.state[name]);
            reader.onerror    = (e) => {
                                    reader.abort();
                                    // this.errorHandler(e);
                                };
            // reader.onprogress = (e) => this.handleFileProgress(e);
        
            // if (reader.readyState == 1) {
            //     this.stateUpload();
            // }
            reader.onload = () => {
                this.setState({
                    [`img${name}`]: reader.result
                });
            }
        }
    }
    // search & filter
	updateSearch(value) {
		this.setState({search: value})
	}
	changeFilter(value) {
		this.setState({filter: value}, () => this.countSponsorsCategory())
    }
    countSponsorsCategory() {
        const _filterCategory = this.state.sponsors.filter(sponsor => sponsor.id_type_sponsor === this.state.filter);
        this.setState({countSponsors: _filterCategory.length});
    }
    // catego
    isCategoryActive(){
        let {isCategoryActive} = this.state;
        return !(isCategoryActive) && style.showMsg;
    }
    render() {  
        return (
            <main>
                <Meta title="Sponsors" />
                { !this.empty() ? (
                    <section className={style.container}>
                        <header className={style.toolbar}>
                            <Search method={this.updateSearch.bind(this)}/>
                            <div className={style.selectorCategory}>
                                <span>{this.state.countSponsors}</span>
                                <select 
                                    id={"selectorCategory"}
                                    onChange={e => {
                                        const _dataTypeSponsor = this.state.type_sponsor.find(type => type.name === e.target.value);
                                        this.changeFilter(_dataTypeSponsor.id);
                                        this.setState({isCategoryActive: _dataTypeSponsor.active});
                                    }}
                                >
                                    {this.state.type_sponsor.length < 0 ?
                                        <option defaultValue="" value="">Insert Category</option>
                                    :this.state.type_sponsor.map( (option, index) => {
                                        return <option key={option.id ? option.id : index} value={option.name}>{option.name}</option>
                                    })}
                                </select>
                            </div>
                            <Button type={'filled'} onClick={e => this.newSponsors()}>
                                { 'new' }
                            </Button>
                        </header>
                        <div className={c(style.categoryDisabled, this.isCategoryActive())}>
                            <span>this category is currently disabled</span>
                            <button onClick={e => this.editLevelSponsors("selectorCategory")} type="button">
                                <i className={style.editIcon}></i>
                            </button>
                        </div>
                        <section className={!(this.state.countSponsors > 0) && style.noCard}>
                            {this.state.countSponsors > 0 ?
                                this.loadSponsors()
                                : 
                                <Fragment>
                                    <p className={style.title}>No sponsor added in this category</p>
                                    <i className={style.icon}></i>
                                </Fragment>
                            }
                        </section>

                    </section>
                   ) : (
                        <section className={style.infoEmpty}>

                            <p className={style.title}>There are still no sponsors for this event</p>
                            <i className={style.icon}></i>
                            <button 
                                className={c(style.btn, this.state.newSponsor && style.hidden)}
                                type="button"
                                onClick={e => this.newSponsors()}
                            >
                                create
                            </button>
                        </section>
                   )
                }
            </main>
        );
    }
}

const mapDispatchToProps =  {
    get_sponsors,
    get_sponsor,
    post_sponsors,
    put_sponsors,
    delete_sponsors,
    get_partnerships,
    get_partnership,
    post_partnerships,
    put_partnerships,
    get_type_sponsors,
    get_type_sponsor,
    post_type_sponsors,
    put_type_sponsors,
    get_partnership_positions,
    get_partnership_position,
    post_partnership_positions,
    put_partnership_positions
};

const mapStateToProps = state => {

	return {
        //sponsors
        sponsors: state.sponsors.sponsors,
        sponsor: state.sponsors.sponsor,
        sponsor_create: state.sponsors.create,
        sponsor_update: state.sponsors.update,
        sponsor_delete: state.sponsors.delete,

        //partnership
        partnerships: state.partnership.partnerships,
        partnership: state.partnership.partnership,
        partnership_create: state.partnership.create,
        partnership_update: state.partnership.update,

        //type sponsors
        type_sponsors: state.type_sponsor.type_sponsors,
        type_sponsor: state.type_sponsor.type_sponsor,
        type_sponsor_create: state.type_sponsor.create,
        type_sponsor_update: state.type_sponsor.update,

        // partnership position
        partnership_positions: state.partnership_position.partnership_positions,
        partnership_position: state.partnership_position.partnership_position,
        partnership_position_create: state.partnership_position.create,
        partnership_position_update: state.partnership_position.update,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sponsors);