import React from 'react';
import { useDispatch } from "react-redux";
import { actionShowDelete, actionShowCancel, actionShowDeleteComment } from "../actions/action_styles";
import style from "../styles/modal.module.scss";

const Modal = ({ children }) => {
  const dispatch = useDispatch();
  const handleHide = () => {
    dispatch(actionShowDeleteComment(false))
    dispatch(actionShowDelete(false))
    dispatch(actionShowCancel(false))
  }
  return (
    <React.Fragment>
      <div className={style.modal}>
        <div className={style.row}>
          <i className={style.iconClose}
            onMouseDown={handleHide}>
          </i>
        </div>
        {children}
      </div>
    </React.Fragment>
  )
}

export default Modal;