import axios from 'axios';

import {
    READ_TRACKS,
    SUCCESS_TRACK_CREATE,
    SUCCESS_TRACK_UPDATE,
    SUCCESS_TRACK_DELETE,
    ERROR_TRACK_READ,
} from '../config/constants'
import { URL_BASE_API, TRACK_PATH } from '../config/api';

const headers = {
    headers: {
        skip: true
    }
}

const get_tracks = () => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${TRACK_PATH}`, headers)
            .then(res => res.data.payload[0])
            .then(tracks => tracks.sort((track, b) => track.id - b.id))
            .then(data => {
                console.log(data)
                dispatch({
                    type: READ_TRACKS,
                    tracks: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_track_read(error_read))
                reject(error_read)
            });
         });

    }
}

const post_tracks = data => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .post(`${URL_BASE_API}${TRACK_PATH}`, data, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_TRACK_CREATE,
                    create: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_track_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const put_tracks = (ID, data) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .put(`${URL_BASE_API}${TRACK_PATH}/${ID}`, data, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_TRACK_UPDATE,
                    update: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_track_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const delete_tracks = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .delete(`${URL_BASE_API}${TRACK_PATH}/${ID}`, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_TRACK_DELETE,
                    delete: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_track_read(error_read))
                reject(error_read)
            });
         });

    }
    
}


////////////Track Read/////////////

const error_track_read = (error_read) => {
  return {
    type: ERROR_TRACK_READ,
    error_read
  }
}

export { get_tracks, post_tracks, put_tracks, delete_tracks }