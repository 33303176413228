import React, {useState} from 'react';
import style from '../styles/btnUrl.module.scss';
import { c } from '../helpers';

const BtnUrl = props => {
  const [active, setActive] = useState(false);
  
  const toggle = () => setActive(!active);

  const expander = () => active && style.expander;

  return (
    <div className={c(style.btnUrl, expander())}>
      <button className={style.btn} onClick={toggle}>
      <i></i>
      </button>
      <a href={props.url}>{props.url}</a>
    </div>
  );
}

export default BtnUrl;