import axios from 'axios'
import {
  READ_POSTS,
  ERROR_POST,
  READ_POST,
  CREATE_POST,
  DELETE_POST,
  READ_LIKE,
  ADD_LIKE
} from '../config/constants'
import {
  URL_BASE_API,
  POST_PATH,
  LIKE_PATH
} from '../config/api'
////////////token/////////////

////////////Error post/////////////

const error_post = error => {
  return {
    type: ERROR_POST,
    error
  }
}

////////////Delete post/////////////

const delete_post = erase => {
  return {
    type: DELETE_POST,
    erase
  }
}

const fetch_delete_post = id => {
  const token = localStorage.getItem('token')

  return axios.delete(`${URL_BASE_API}${POST_PATH}/${id}`, {
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

const action_delete_post = id => {
  return dispatch => {
    return fetch_delete_post(id)
      .then(data => dispatch(delete_post(true)))
      .catch(error => dispatch(error_post(error)))
  }
}

////////////Post Read/////////////

const action_read_post = () => {
  const token = localStorage.getItem('token')

  return dispatch => {
    axios
      .get(`${URL_BASE_API}${POST_PATH}`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: READ_POSTS,
          post: response.data.payload.data
        })
      })
      .catch(error => dispatch(error_post(error)))
  }
}

//////////// Read ONE Post/////////////

const action_one_post = id => {
  const token = localStorage.getItem('token')

  return dispatch => {
    axios
      .get(`${URL_BASE_API}${POST_PATH}/${id}`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: READ_POST,
          onePost: response.data.payload
        })
      })
      .catch(error => dispatch(error_post(error)))
  }
}

//////////// Read likes ONE Post/////////////

const action_read_likes = id => {
  const token = localStorage.getItem('token')

  return dispatch => {
    axios
      .get(`${URL_BASE_API}${LIKE_PATH}/${id}`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: READ_LIKE,
          read_like: response.data.payload.total_likes
        })
      })
      .catch(error => dispatch(error_post(error)))
  }
}

//////////// Create Post/////////////

const create_post = create => {
  return {
    type: CREATE_POST,
    create
  }
}

const fetch_create = data => {
  const token = localStorage.getItem('token')

  return axios.post(`${URL_BASE_API}${POST_PATH}/`, data, {
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

const action_create_post = data => {
  let formdata = new FormData()
  formdata.append('content', data.content)
  formdata.append('active', 'true')
  formdata.append('value', '3')
  formdata.append('fixed', 'true')
  formdata.append('title', 'none')
  formdata.append('track', '[1]')


  if (data.media.length >= 1) {
    let fileObj = []
    fileObj.push(data.media)
    for (let i = 0; i < fileObj[0].length; i++) {
      formdata.append('media', fileObj[0][i])
    }
  }

  return dispatch => {
    return fetch_create(formdata)
      .then(data => dispatch(create_post(true)))
      .catch(error => dispatch(error_post(error)))
  }
}

////////////Add like post/////////////

const add_like = add_like => {
  return {
    type: ADD_LIKE,
    add_like
  }
}

const fetch_add_like = id => {
  const token = localStorage.getItem('token')

  return axios.post(`${URL_BASE_API}${LIKE_PATH}/`, id, {
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

const action_add_like = id => {
  let formdata = new FormData()
  formdata.append('id_post', id)

  return dispatch => {
    return fetch_add_like(formdata)
      .then(data => dispatch(add_like(true)))
      .catch(error => dispatch(error_post(error)))
  }
}

export {
  action_read_post,
  action_one_post,
  action_create_post,
  action_delete_post,
  action_add_like,
  action_read_likes
}
