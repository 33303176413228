import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actionShowDeleteComment } from "../actions/action_styles";
import { action_read_comment_post, action_delete_commet } from "../actions/action_comment_post";
import style from "../styles/commentPost.module.scss";
import OptionsPost from './optionsPost';
import LikeComment from './likeComment';
import Modal from "./modal";
import WriteComment from "./writeComments";
import { c, urlFor } from '../helpers';

const CommentPost = index => {
  const dispatch = useDispatch();
  const commentpost = useSelector((state) => state.comment.read_comments);
  const showDel = useSelector((state) => state.styles.comment);
  const [idComment, setIdComment] = useState();
  const [showNewComment, setShowNewComment] = useState(false);

  useEffect(() => {
    dispatch(action_read_comment_post(index.index))
  }, [dispatch, index.index])

  return (
    <React.Fragment>
      {commentpost.map(({ id, user, count_likes, count_messages, comment, image, video, file }, i) =>
        <div key={i} className={style.commentsBox} onClick={() => setIdComment(id)}>
          <div className={c(style.row, style.view)}>

            <div className={style.profileBox}>
              {user.img_user === '' &&
                <i className={style.iconUser} />
              }
              {user.img_user !== '' &&
                <img alt="user" src={urlFor(user.img_user)} />
              }
            </div>
            <p onClick={() => setShowNewComment(!showNewComment)}>
              <span className={style.author}>
                {user.author}
              </span>
              {comment}</p>
            <OptionsPost comment={true} />
          </div>
          <div className={c(style.row, style.view, style.comment)}>
            <LikeComment index={index.index} count_likes={count_likes} count_messages={count_messages} />
          </div>
          {/* {showNewComment &&
            <WriteComment hideImg={true}/>
          } */}
        </div>
      )}
      {showDel &&
        <ModalComment indexComment={idComment} indexPost={index} />
      }
    </React.Fragment>
  )
}

const ModalComment = ({ indexComment, indexPost }) => {
  const dispatch = useDispatch();
  const showDel = useSelector((state) => state.styles.comment);
  const hide = () => {
    dispatch(actionShowDeleteComment(!showDel))
  }
  const onDelete = () => {
    dispatch(action_delete_commet(indexComment))
      .then(req => {
        dispatch(action_read_comment_post(indexPost.index))
      }).catch(error => {
        console.log(`fail delete comment: ${error}`);
      });
  };
  return (
    <Modal>
      <div className={style.commentModal}>
        <h2>Are you sure?</h2>
        <span>
          You will lose all about this comment
        </span>
        <button onClick={() => hide()} onMouseDown={onDelete}>DELETE</button>
      </div>
    </Modal>
  )
}
export default CommentPost;