import axios from 'axios'
import {
  READ_USER,
  ERROR_USER,
  UPDATE_USER,
  PHOTO_USER
} from '../config/constants'
import { URL_BASE_API, USER_PATH } from '../config/api'

////////////USER Read/////////////
const error_read_user = error_read => {
  return {
    type: ERROR_USER,
    error_read
  }
}

const action_read_user = () => {
  let token = localStorage.getItem('token')
  return dispatch => {
    axios
      .get(`${URL_BASE_API}${USER_PATH}`, {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        dispatch({
          type: READ_USER,
          user: response.data.payload
        })
      })
      .catch(error_read => dispatch(error_read_user(error_read)))
  }
}
////UPDATE USER
const update_user = update => {
  return {
    type: UPDATE_USER,
    update
  }
}

const fetch_update = (data) => {
  let token = localStorage.getItem('token')
  return axios.put(`${URL_BASE_API}${USER_PATH}`, data, {
    headers: {
      Authorization: `bearer ${token}`
    }
  })
}
////////////obtener params de comunity for url
const action_update_user = data => {
  let formdata = new FormData()
  formdata.append('name', data.name)
  formdata.append('last_name', data.lastname)
  formdata.append('username', data.user)
  formdata.append('country', data.country)
  formdata.append('address', data.adress)
  formdata.append('country_code', data.codePhone)
  formdata.append('phone', data.phone)
  formdata.append('gender', data.gender)
  formdata.append('type', data.type)
  formdata.append('dni', data.dni)
  formdata.append('zip_code', data.zip_code)
  formdata.append('birthdate', data.birthdate)
  formdata.append('email', data.email)
  formdata.append('password', data.password)
  console.log(data)
  if (data.avatar) {
    if (data.avatar.length >= 1) {
      let fileObj = []
      fileObj.push(data.avatar)
      for (let i = 0; i < fileObj[0].length; i++) {
        formdata.append('avatar', fileObj[0][i])
      }
    }
  }
  return dispatch => {
    return fetch_update(formdata)
      .then(data => dispatch(update_user(true)))
      .catch(error_read => dispatch(error_read_user(error_read)))
  }
}

/////////////////SET DATA PHOTO////////////
const action_set_photo = payload => {
  return {
    type: PHOTO_USER,
    payload
  }
}
export { action_read_user, action_update_user, action_set_photo }
