import {
  READ_TYPE_BOOTHS,
  READ_TYPE_BOOTH,
  SUCCESS_TYPE_BOOTH_CREATE,
  SUCCESS_TYPE_BOOTH_UPDATE,
  SUCCESS_TYPE_BOOTH_DELETE,
  SEARCH_TYPE_BOOTHS,
  ERROR_TYPE_BOOTH_READ
} from '../config/constants'

const initialState = {
  type_booths: [],
  type_booth: [],
  search: [],
  create: [],
  update: [],
  error_create: 'error',
  error_read: 'error'
}

export const reducerTypeBooths = (state = initialState, action) => {

    switch (action.type) {
      case READ_TYPE_BOOTHS:
        return { ...state, type_booths: action.type_booths }

      case READ_TYPE_BOOTH:
        return { ...state, type_booth: action.type_booth }

      case SEARCH_TYPE_BOOTHS:
        return { ...state, search: action.search }

      case SUCCESS_TYPE_BOOTH_CREATE:
        return { ...state, create: action.create }

      case SUCCESS_TYPE_BOOTH_UPDATE:
        return { ...state, update: action.update }

      case SUCCESS_TYPE_BOOTH_DELETE:
        return { ...state, delete: action.delete }

      case ERROR_TYPE_BOOTH_READ:
        return { ...state, error_read: action.error_read }

      default:
        return state
    }

}
