import React, { useEffect } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import style from '../styles/events.module.scss';
import { action_events } from '../actions/action_events';
import EventsBar from '../components/eventsBar';
import EventsCardsCategory from '../components/eventsCardsCategory';
import SearchEvents from '../components/eventsCardsSearch';
import Meta from '../components/metaContent';
import { NEW_EVENT_ROUTE, EVENTS_ROUTE, SEARCH_ROUTE } from '../config/routes';

const Events = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(action_events());
	}, [dispatch]);
	
	return (
		<main className={style.contentCardEvent}>
			<Meta title="Events" />
			<EventsBar />
			<Switch>
				<Route exact path={EVENTS_ROUTE} component={AllEvents} />
				<Route path={SEARCH_ROUTE} component={SearchEvents} />
			</Switch>
		</main>
	);
}

const AllEvents = () => {
	const eventsData = useSelector((store) => store.events.events);
	const empty =() => (eventsData.length === 0);
	const filterEvents = () => {
		const events = [
			{
				title: 'progress',
				array:[]
			},
			{
				title: 'previews',
				array:[]
			},
			{
				title: 'draft',
				array:[]
			}
		];
		const DayCurrent = new Date().getTime();
        for (let i = 0; eventsData.length > i; i++){
			const event = eventsData[i];
			const _isEventEnd = DayCurrent < new Date(event.end).getTime();
			_isEventEnd ? events[0].array.push(event) : events[1].array.push(event)
		}
        return events;
	}

	const loadCardEvent = () => {
		const getFilter = filterEvents();
		return getFilter.map((category, i) => <EventsCardsCategory key={i} data={category.array} labelCategory={category.title} />)
	}

	if(empty()) {
		return (
			<section className={style.noEvents}>
				<p>Your community activity is going to be here</p>
				<i />
				<Link to={NEW_EVENT_ROUTE} className={style.btn}>
					New Event
				</Link>
			</section>
		);
	}

	return (
		<div className={style.eventContainer}>
			{ loadCardEvent() }
		</div>
	);
}

export default Events;
