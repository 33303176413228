import {
    READ_TICKETS,
    READ_TICKETS_ID_EVENT,
    READ_TICKET,
    SUCCESS_TICKET_CREATE,
    SUCCESS_TICKET_UPDATE,
    SUCCESS_TICKET_DELETE,
    ERROR_TICKET_READ,
  } from '../config/constants'
  
  const initialState = {
    tickets: [],
    error_read: 'error'
  }
  
  export const reducerTickets = (state = initialState, action) => {
  
    switch (action.type) {
      case READ_TICKETS:
        return { ...state, tickets: action.tickets }

      case READ_TICKETS_ID_EVENT:
        return { ...state, tickets: action.tickets }

      case READ_TICKET:
        return { ...state, ticket: action.ticket }
  
      case SUCCESS_TICKET_CREATE || SUCCESS_TICKET_UPDATE || SUCCESS_TICKET_DELETE:
        return { ...state }

      case ERROR_TICKET_READ:
        return { ...state, error_read: action.error_read }
  
      default:
        return state
    }
  
  }