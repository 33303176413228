import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from "react-redux";
import Upload from './upload';
import style from "../styles/leftProfile.module.scss";

const LeftProfile = ({ userInfo, communityInfo, form, setForm }) => {

  const enablePhoto = useSelector((state) => state.styles.enablePhoto);
  const [showInputState, setShowInput] = useState(false)
  const [showImg, setShowImg] = useState(false)
  const [showIcon, setShowIcon] = useState(false)
  const [showUpload, setShowUpload] = useState(false)
  const [data, setData] = useState([]);

  const input = useRef(null);

  const showInput = () => {
    setShowInput(true)
  }
  const hideInput = () => {
    setShowInput(false)
  }
  const handleImage = e => {
    setData(e.target.files)
    setForm({
      ...form,
      avatar: e.target.files
    })
  }
  const onClick = () => {
    setShowImg(false);
    setShowIcon(false);
    setShowUpload(true);

  }
  const [dataProfile, setDataProfile] = useState({
    status: '',
    code_community: '',
    img: '',
  })
  const { status, img, code_community } = dataProfile;

  useEffect(() => {
    setDataProfile({
      ...dataProfile,
      status: userInfo.id_role,
      code_community: communityInfo.prefix + communityInfo.code,
      img: userInfo.profile_photo,
    })

  }, []);
  useEffect(() => {
    if (userInfo.profile_photo) {
      setShowIcon(false)
      setShowImg(true)
    }
    if (!userInfo.profile_photo) {
      setShowImg(false)
      setShowIcon(true)
    }

  }, [img])
  return (
    <React.Fragment>
      <div className={style.contentLeft}>
        <div className={style.image} onMouseLeave={hideInput} onMouseEnter={showInput}>
          {showUpload &&
            <Upload data={data} />
          }
          {showIcon &&
            <div className={style.iconUser}></div>
          }
          {showImg &&
            <img className={style.img} src={img} />
          }
          {showInputState &&
            enablePhoto &&
            <div className={style.fileUpload}>
              <input
                ref={input}
                type="file"
                accept="image/*"
                className={style.upload}
                onChange={handleImage}
                onClick={onClick}
              />
              <span>CHANGE</span>
            </div>
          }
        </div>
        <div className={style.info}>
          <span>Status</span>
          <h3>
            {status === 1 &&
              "Administrator"
            }
            {status !== 1 &&
              "Member"
            }</h3><br />
          <span>Code in the community</span>
          <h3>{code_community}</h3>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LeftProfile;