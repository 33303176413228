import React, { Component } from 'react';
import {  Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import style from '../styles/createEvent.module.scss';
import { action_events_create } from '../actions/action_events';
import { c } from '../helpers';
import Meta from '../components/metaContent';
import { EVENTS_ROUTE } from '../config/routes';

class CreateEvent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			online: true,
			startDate1: '',
			startDate2: '',
			selectedDay: undefined,
			showAddress: `${style.hide}`,
			errorHour: false,
			hourValidation: '',
			hour1: '',
			hour2: '',
			onlineBlue: `${style.blue}`,
		};
	}

	switchType() {
		this.setState(prev => {
			return {
				online: prev.online,
			};
		});
	}
	handleDayChange(day) {
		this.setState({ selectedDay: day, start: day });
	}
	handleDayChange2(day) {
		this.setState({ selectedDay: day, end: day });
	}
	validDate() {
		let day = this.state.start;
		let day2 = this.state.end;
		if (day > day2) {
			this.setState({ errorHour: true, hourValidation: `${style.hourValidation}` });
		} else {
			this.setState({ errorHour: false, hourValidation: '' });
		}
	}
	handleSubmit(e) {
		e.preventDefault();
		//VALIDATE DISPATCH ACTION

		this.state = {
			name: this.state.name,
			draft: this.state.draft,
			online: this.state.online,
			start: this.state.start,
			end: this.state.end,
			description: this.state.description,
			hour1: this.state.hour1,
			hour2: this.state.hour2,
			id_state: 1,
			id_community: 1,
			id_repository: 1,
			prom_rate: 95.3,
			active: true
		};

		// let valid = this.state.valid;
		let valid = true;

		if (valid) {
			let ver = this.props.action_events_create(this.state);
			ver.then(() => {
				console.log('passing');
				this.setState({
					name: '',
					description: '',
					location: '',
					url: '',
					draft: '',
					online: '',
					communityId: '',
					start: '',
					end: '',
					hour1: '',
					hour2: '',
				});
				this.refs.Tilte.value = '';
				this.refs.Description.value = '';
				this.refs.Url.value = '';
				this.refs.Location.value = '';
				this.refs.Draft.value = '';
				this.refs.Online.value = '';
				this.refs.Start.value = '';
				this.refs.End.value = '';
				this.refs.Hour1.value = '';
				this.refs.Hour2.value = '';
				this.props.history.push('/home/events');
			});
		} else {
			console.log('i dont know rick');
		}
	}

	render() {
		const { selectedDay } = this.state;
		return (
			<div className={style.createEvent}>
				<Meta title="New Event" />
				<Link to={EVENTS_ROUTE} className={style.back} />
				<form onSubmit={this.handleSubmit.bind(this)} className={style.center}>
					<h2>¡Create an experience!</h2>
					<div className={style.eventForm}>
						<div>
							<h3>Event data</h3>
							<label>Name</label>
							<input ref="Tilte" onKeyUp={e => this.setState({ name: e.target.value })} type="text" />
							<label>Description</label>
							<input
								ref="Description"
								onKeyUp={e => this.setState({ description: e.target.value })}
								type="text"
							/>
							<label>Event URL</label>
							<input
								ref="Url"
								onKeyUp={e => this.setState({ url: e.target.value })}
								className={style.url}
								type="text"
							/>
							<span className={style.url}>.kommu.io</span>
						</div>
						<div>
							<h3>Type</h3>
							<div className={style.options}>
								<button
									type="button"
									ref="Online"
									onMouseDown={() => this.setState({ showAddress: '', online: false, faceBlue: style.blue, onlineBlue: undefined })}
									onClick={this.switchType.bind(this)}
									className={!this.state.online && style.active}
								>
									<div className={c(style.face, this.state.faceBlue)} />
									<span>face-to-face</span>
								</button>
								<button
									type="button"
									ref="Online"
									onMouseDown={() => this.setState({ showAddress: style.hide, online: true, faceBlue: '', onlineBlue: style.blue })}
									onClick={this.switchType.bind(this)}
									className={this.state.online && style.active}
								>
									<div className={c(style.online, this.state.onlineBlue)} />
									<span>Online</span>
								</button>
							</div>
							<div className={c(style.inputAddress, this.state.showAddress)}>
								<input
									ref="Location"
									onKeyUp={e => this.setState({ location: e.target.value })}
									placeholder="Address"
								/>
							</div>
						</div>
						<div>
							<h3>Date</h3>
							<div className={style.date}>
								<label>Start date</label>
								<DayPickerInput
									dayPickerProps={{
										month: new Date(2020, 0),
										showWeekNumbers: true,
										todayButton: 'Today',
									}}
									onChange={() => this.validDate().bind(this)}
									className={this.state.hourValidation}
									ref="Start"
									onDayChange={this.handleDayChange.bind(this)}
								/>
							</div>
							<div className={style.date}>
								<label>End date</label>
								<DayPickerInput
									dayPickerProps={{
										month: new Date(2020, 0),
										showWeekNumbers: true,
										todayButton: 'Today',
									}}
									onChange={() => this.validDate().bind(this)}
									ref="End"
									onDayChange={this.handleDayChange2.bind(this)}
								/>
							</div>
							{this.state.errorDate &&
								<span className={style.hourValidation}>
									The event start date cannot be earlier than the current one.
								</span>}
						</div>
						<div>
							<h3>Time</h3>
							<div className={style.time}>
								<label>Start Hour</label>
								<input
									placeholder="00:00"
									type="text"
									ref="Hour1"
									onKeyUp={e => this.setState({ hour1: e.target.value })}
								/>
							</div>
							<div className={style.time}>
								<label>End Hour</label>
								<input
									placeholder="00:00"
									type="text"
									ref="Hour2"
									onKeyUp={e => this.setState({ hour2: e.target.value })}
								/>
							</div>
						</div>
						<div className={style.draft}>
							<input
								type="checkbox"
								ref="Draft"
								onChange={e => this.setState({ draft: e.target.value })}
							/>
							<label>Create as draft</label>
						</div>
					</div>
					<button type="submit">CREATE</button>
				</form>
			</div>
		);
	}
}

const mapDispatchToProps = {
	action_events_create: action_events_create,
};
const mapStateToProps = state => {
	return {
		events: state.events.create,
		token: state.signin.access_token
	};
};
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CreateEvent);
