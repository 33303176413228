import React, { useState, useEffect } from 'react';
import style from "../styles/userCardLeft.module.scss";
import { c } from "../helpers";

const UserCardLeft = ({ news }) => {
  const [show, setShow] = useState({
    showSpeaker: true,
    showSurvey: true,
    showTickets: true,
    showMessage: false,
    showInfo: false,
    showCategory: true
  });

  const [expand, setExpand] = useState({
    expandF: false,
    expandS: false,
    expandT: false
  });

  const [expandIcon, setExpandIcon] = useState({
    expandIconF: style.iconExpandMore,
    expandIconS: style.iconExpandMore,
    expandIconT: style.iconExpandMore
  });

  const expandFirts = () => {
    setExpand({
      expandF: !expand.expandF,
      expandS: false,
      expandT: false
    })
    let oldStateIcon = expandIcon.expandIconF;
    let isIcon = oldStateIcon === style.iconExpandLess;
    let newIcon = isIcon ? style.iconExpandMore : style.iconExpandLess;
    setExpandIcon({
      expandIconF: newIcon,
      expandIconS: style.iconExpandMore,
      expandIconT: style.iconExpandMore
    })
  }

  const expandSecond = () => {
    setExpand({
      expandF: false,
      expandS: !expand.expandS,
      expandT: false
    })
    let oldStateIcon = expandIcon.expandIconS;
    let isIcon = oldStateIcon === style.iconExpandLess;
    let newIcon = isIcon ? style.iconExpandMore : style.iconExpandLess;
    setExpandIcon({
      expandIconF: style.iconExpandMore,
      expandIconS: newIcon,
      expandIconT: style.iconExpandMore
    });
  }

  const expandThird = () => {
    setExpand({
      expandF: false,
      expandS: false,
      expandT: !expand.expandT
    })
    let oldStateIcon = expandIcon.expandIconT;
    let isIcon = oldStateIcon === style.iconExpandLess;
    let newIcon = isIcon ? style.iconExpandMore : style.iconExpandLess;
    setExpandIcon({
      expandIconF: style.iconExpandMore,
      expandIconS: style.iconExpandMore,
      expandIconT: newIcon
    })
  }

  useEffect(() => {
    if (news.length === 0) {
      setShow({ showMessage: true })
    }
    // if (news[0].category) {
    //   setShow({ showCategory: true, showInfo: false })
    // }
    // if (!news[0].category) {
    //   setShow({ showInfo: true, showCategory: false })
    // }
    // if (news[0].category.speakers) {
    //   setShow({ showSpeaker: true })
    // }
    // if (news[0].category.survey) {
    //   setShow({ showSurvey: true })
    // }
    // if (news[0].category.tickets) {
    //   setShow({ showTickets: true })
    // }

  }, [news]);
  let { showSpeaker, showInfo, showMessage, showTickets, showSurvey, showCategory } = show;
  return (
    <React.Fragment>
      {showMessage &&
        <div className={style.boxMessage}>
          <div className={style.row}>
            <i className={style.iconPeople}></i>
            <h2>Hello!</h2>
          </div>
          <p>We know you are new in this great community. We hope you will participate and have a good time!</p>
        </div>
      }
      {showCategory &&
        <div className={style.boxEvent}>
          <div className={style.header}>{news[0].title}</div>
          {showSpeaker &&
            <div className={c(style.spaceRow, style.first)}>
              <div className={style.row}>
                <div className={style.iconBox}>
                  <i className={style.iconSpeaker}></i>
                </div>
                <div className={style.column}>
                  <h4>Speakers</h4>
                  {!expand.expandF &&
                    <span className={style.ends}>vote ends: {news[0].category.speakers.vote_end}</span>
                  }
                </div>
                <div className={style.iconBox}>
                  <i className={expandIcon.expandIconF} onClick={() => expandFirts()}></i>
                </div>
              </div>
              {expand.expandF &&
                <div className={style.footer}>
                  <div className={style.expand}>
                    {news[0].category.speakers.desc}
                  </div>
                  <span className={style.red}>vote ends: {news[0].category.speakers.vote_end}</span>
                  <button className={style.buttonCardLeft}>VOTE</button>
                </div>
              }
            </div>
          }
          {showSurvey &&
            <div className={style.spaceRow}>
              <div className={style.row}>
                <div className={style.iconBox}>
                  <i className={style.iconSurvey}></i>
                </div>
                <div className={style.column}>
                  <h4>Survey</h4>
                  {!expand.expandS &&
                    <span className={style.ends}>Ends in: {news[0].category.survey.survey_end}</span>
                  }
                </div>
                <div className={style.iconBox}>
                  <i className={expandIcon.expandIconS} onClick={() => expandSecond()}></i>
                </div>
              </div>
              {expand.expandS &&
                <div className={style.footer}>
                  <div className={style.expand}>
                    {news[0].category.survey.desc}
                  </div>
                  <span className={style.red}>Ends in: {news[0].category.survey.survey_end}</span>
                  <button className={style.buttonCardLeft}>VOTE</button>
                </div>
              }
            </div>
          }
          {showTickets &&
            <div className={c(style.spaceRow, style.third)}>
              <div className={style.row}>
                <div className={style.iconBox}>
                  <i className={style.iconTicket}></i>
                </div>
                <div className={style.column}>
                  <h4>Tickets</h4>
                  {!expand.expandT &&
                    <React.Fragment>
                      <span className={style.ends}>Avail: {news[0].category.tickets.avail}</span>
                      <span className={style.ends}>Sale ends: {news[0].category.tickets.sale_end}</span>
                    </React.Fragment>
                  }
                </div>
                <div className={style.iconBox}>
                  <i className={expandIcon.expandIconT} onClick={() => expandThird()}></i>
                </div>
              </div>
              {expand.expandT &&
                <div className={style.footer}>
                  <div className={style.expand}>
                    {news[0].category.tickets.desc}
                  </div>
                  <span className={style.ends}>Avail: {news[0].category.tickets.avail}</span>
                  <span className={style.red}>vote ends: {news[0].category.tickets.sale_end}</span>
                  <button className={style.buttonCardLeft}>VOTE</button>
                </div>
              }
            </div>
          }
        </div>
      }
      {showInfo &&
        <div className={style.boxEvent}>
          <img src={news[0].img} alt="" />
          <div className={c(style.row, style.border)}>
            <div className={style.column}>
              <h3>{news[0].title}</h3>
              <p>{news[0].description}</p>
              <button className={style.buttonInfo}>INFO</button>
            </div>
          </div>
        </div>
      }

    </React.Fragment>
  )
}

export default UserCardLeft;