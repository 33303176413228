import React from 'react';
import Meta from '../components/metaContent';

const PageNotFound = () => {
    return (
        <div>
            <Meta title="404" />
            Sorry, I could not find what you're looking for
        </div>
    )
}

export default PageNotFound;