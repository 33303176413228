import React from 'react';
import style from '../styles/loginError.module.scss';

const LoginError = ({message}) => {
    return (
        <React.Fragment>
            <span className={style.error}>{message}</span>
        </React.Fragment>
    );
}

export default LoginError;