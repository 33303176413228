import React from 'react'
import style from '../styles/cardSubDescription.module.scss'

const CardSubDescription = ({ infos, emphasis }) => {
  const loadInfo = () => infos.map((info, i) => (
    <span key={i}>
      {info.title ? `${info.title}:` : ''}
      <b className={info.emphasis ? emphasis : ''} >{info.item}</b>
    </span>
  ));

  return (
    <section className={style.contentSubDescription}>
      {loadInfo()}
    </section>
  );
}

export default CardSubDescription;