import React, { useState } from 'react';
import style from "../styles/genderList.module.scss";
import { c } from '../helpers';

const GenderList = ({ disable, disableStyle, form, setForm }) => {

  const [genderStyles, setGenderStyles] = useState(
    {
      showList: false,
      iconExpand: style.iconExpandM,
    })
  const { showList, iconExpand } = genderStyles

  const { gender } = form

  const [list, setList] = useState([
    { "name": "Male" },
    { "name": "Famele" }
  ]);

  const updateInput = e => {
    setForm(e.target.value);
  }

  const hideShow = () => {
    let oldStateMl = iconExpand;
    let oldStateSh = showList;
    let isExpand = oldStateMl === style.iconExpandM;
    let isShowOrHide = oldStateSh === false;
    let newStateMl = isExpand
      ? style.iconExpandL
      : style.iconExpandM;

    let newShowOrhide = isShowOrHide
      ? true
      : false;

    setGenderStyles(
      {
        iconExpand: newStateMl,
        showList: newShowOrhide,
        iconExpandColor: style.iconBlueDark,
        iconPublic: style.iconBlue,
        iconGender: style.iconBlue
      });
  }

  return (
    <React.Fragment>
      <label htmlFor="gender">Gender</label>
      <div className={style.divInputIcon}>
        <input
          disabled={disable}
          className={c(disableStyle, style.inputIcon)}
          name="gender"
          value={gender}
          type="text"
          placeholder="Gender"
          onKeyUp={updateInput}
        />
        <div className={style.divIcons}>
          <i
            onMouseDown={hideShow}
            className={iconExpand} />
        </div>
      </div>
      {
        showList &&
        !disable && (
          <div className={style.inputSelect}>
            {list.length >= 1 &&
              list.map((item, i) => (
                <span
                  className={style.list}
                  value={item.name}
                  key={i}
                  onMouseDown={() => setForm({ ...form, gender: item.name })}
                  onClick={() => setGenderStyles({
                    showList: !showList,
                    iconExpand: style.iconExpandM,
                    iconExpandColor: '',
                    iconGender: ''
                  })}
                >
                  {item.name}
                </span>
              ))}
          </div>
        )
      }
    </React.Fragment>
  )
}

export default GenderList;