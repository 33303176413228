import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import style from '../styles/eventsBar.module.scss';
import { action_events_search } from '../actions/action_events';
import { c } from '../helpers';
import { NEW_EVENT_ROUTE, EVENTS_ROUTE, SEARCH_ROUTE } from '../config/routes';

const EventsBar = (props) => {
	const [showBack, setShowBack] = useState(false);
	const dispatch = useDispatch();

	const handleSearch = e => {
		dispatch(action_events_search(e.target.value))
	}

	const handleBackClick = e => {
		dispatch(action_events_search(''));
		setShowBack(false);
	}

	return (
		<div className={style.eventsBar}>
			{showBack ?
				<Link
					onClick={handleBackClick}
					to={EVENTS_ROUTE}
					className={c(style.iconArrowLeft)}
				/> :
				<h2>
					<span className={style.icon} />
					<span className={style.title}>Events</span>
				</h2>
			}

			{!showBack &&
				<div className={c(style.left)}>
					<Link to={NEW_EVENT_ROUTE} className={style.new}>
						New
					</Link>
				</div>}

			<div className={style.groupSearch}>
				<input
					onKeyDown={handleSearch}
					onChange={handleSearch}
					className={style.search}
					type="search"
				/>
				<Link
						onClick={() => setShowBack(true)}
						to={SEARCH_ROUTE}
						className={style.iconSearch}
					/>
			</div>
		</div>
	);
};

export default EventsBar;