import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { REGISTER_ROUTE, REGISTER_NEXT_ROUTE, REGISTER_FROM_INVITATION_ROUTE } from '../config/routes';
import Register from '../screens/register';
import PostRegister from '../screens/postRegister';

const RegisterRouter = props => {

    return (
        <Switch>
            <Route exact path={REGISTER_ROUTE} component={Register} />
            <Route path={REGISTER_NEXT_ROUTE} component={PostRegister} />
            <Route path={REGISTER_FROM_INVITATION_ROUTE} render={() => <Register invited={true} />} />
        </Switch>
    )
}

export default RegisterRouter;