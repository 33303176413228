import React, { useState } from 'react';
import style from "../styles/card.module.scss";
import Modal from "./modal";
import { Link } from 'react-router-dom';
import { c } from '../helpers';
import { getEventRoute, EVENT_ROUTE } from '../config/routes';

const Card = ({ values, fnAction, imgURL, showList, showDelete, event, ...props }) => {
  const [stateHideImg, setHideImg] = useState(false)

  const listStyle = () => showList && style.list;

  const parseFormatDate = (dateEvent) => {
    const _date = new Date(dateEvent);
    const prettifyDate = number => number.toString().padStart(2, '0'); 
    return `${prettifyDate(_date.getMonth() + 1)}-${prettifyDate(_date.getDate())}-${_date.getFullYear()}`;
  }
  const routeCardEvent = (cardID) => {
    return getEventRoute(EVENT_ROUTE, cardID);
  }

  const search = () => {
    let {search, title} = props;
    return title.toLowerCase().search(search) !== -1;
  }

  const hide = () => !search() && style.hidden;

  return (
    <React.Fragment>
      <div className={c(style.card, listStyle(), hide())} key={values.id}>
        <figure className={c(stateHideImg && style.hiddenImg)}>
          <div icon="image_outline"></div>
          <img src={imgURL} alt="" onError={() => setHideImg(true)}></img>
        </figure>
        <section>
          <header>
              <Link to={routeCardEvent(values.id)} className={style.title}>
                {props.title}
              </Link>
              {/* <CardListOptions options={fnAction} /> */}
          </header>
          <div className={style.desc}>
            {event &&
              <span className={style.date}>
                {parseFormatDate(values.start)}
              </span>
            }
            <p>
              {props.description}
            </p>
          </div>
        </section>
        {showDelete &&
          <ModalCard event={event} />
        }
      </div>
    </React.Fragment>
  )
}

const ModalCard = ({event}) => {
  return (
    <Modal>
      {!event &&
        <div className={style.cardModal}>
          <h2>Are you sure?</h2>
          <span>
            You will lose the information of the postulant
        </span>
          <button>DELETE</button>
        </div>
      }
      {event &&
        <div className={style.cardModal}>
          <h2>Are you sure?</h2>
          <span>
            You will lose the information of this event
        </span>
          <button>DELETE</button>
        </div>
      }
    </Modal>
  )
}

export default Card;