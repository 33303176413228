import React from 'react';
import { Route, Switch, Redirect, useParams } from "react-router-dom";
import style from '../styles/event.module.scss';

import Tickets from './tickets';
// import EventsCfp from '../components/eventsCallForPapers';
//import CFPBuildForm from '../components/cfpBuildForm';
import CFPDashboard from './cfpDashboard';
import EventSpeakers from './speakers';
import Attendes from '../components/attendes';
import Rooms from '../components/rooms';
import Tracks from './tracks';
import Sponsors from './sponsors';
import Exhibitors from './exhibitors';
import { LoadingCircle } from '../components/loading';
import LeftBar from '../components/leftBar';
import Meta from '../components/metaContent';

import {
	getEventRoute,
	TICKETS_ROUTE,
	CALL_FOR_PAPERS_ROUTE,
	ATTENDEES_ROUTE,
	SPEAKERS_ROUTE,
	ROOMS_ROUTE,
	TRACKS_ROUTE,
	EVENT_ROUTE,
	SPONSORS_ROUTE,
	EXHIBITORS_ROUTE,
} from '../config/routes';
import { useSelector } from 'react-redux';

const Event = (props) => {
	const { eventId } = useParams();
	const event = useSelector(state => state.events.event);
	const loading = useSelector(state => state.events.loading);
	const route = (path) => getEventRoute(path, eventId);

	return (
		<div className={style.container}>
			<Meta title={event && event.name ? event.name : `Event ${eventId}`} />
			<LoadingCircle show={false} fixed={true} />
			<LeftBar idEvent={eventId} base={route(EVENT_ROUTE)} />
			<main>
				<LoadingCircle show={loading} />
				<Switch>
					<Route path={route(EVENT_ROUTE)} exact render={() => <Redirect to={route(TICKETS_ROUTE)} />} />
					<Route path={route(TICKETS_ROUTE)} component={Tickets}/>
					<Route path={route(CALL_FOR_PAPERS_ROUTE)} component={CFPDashboard}/>
					<Route path={route(ATTENDEES_ROUTE)} component={Attendes} />		
					<Route path={route(SPEAKERS_ROUTE)} component={EventSpeakers} />
					<Route path={route(ROOMS_ROUTE)} component={Rooms} />	
					<Route path={route(TRACKS_ROUTE)} component={Tracks} />
					<Route path={route(SPONSORS_ROUTE)} component={Sponsors} />
					<Route path={route(EXHIBITORS_ROUTE)} component={Exhibitors} />
				</Switch>
			</main>
		</div>
	);
}

export default Event;