import React from 'react';
import style from '../styles/listOption.module.scss';

const ListOption = props => {
  const select = (e) => props.fn(e);

  return(
      <button 
        className={style.list}
        onClick={select}
      >
          <p>{props.children}</p>
      </button>
    );
}
export default ListOption;