import React from 'react';
import style from '../styles/filterTab.module.scss'
import { c } from '../helpers';

const FilterTab = props => {
  const selected = props.selected(props.id);

  return (
    <button className={c(style.filterTab, props.actived && style.actived)} onClick={selected}>
      <span>{ props.items }</span>
      {props.children}
    </button>
  );
}

export default FilterTab;