import { FORM } from '../config/constants';

const actionForm = form => {
    return dispatch => {
        dispatch({
            type: FORM,
            form
        });
    };
};
export { actionForm };