import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { action_create_comment, action_read_comment_post } from "../actions/action_comment_post";
import { action_read_user } from '../actions/action_user';
import style from "../styles/writeComments.module.scss";

const WriteComments = ({ index, hideImg, modal }) => {
  const dispatch = useDispatch();
  const textArea = useRef(null);
  const user = useSelector((state) => state.user.user);

  const [comment, setComment] = useState({
    id_post: index,
    image: null,
    video: null,
    file: null,
    fileName: null,
    videoName: null,
    imageName: null,
    content: ''
  });
  const [hide, setHide] = useState(false);

  const { content } = comment;

  const cleanInput = () => {
    textArea.current.value = "";
  };

  const updateComment = (e) => {
    e.preventDefault();
    setComment({
      ...comment,
      [e.target.name]: e.target.value
    })
  }
  const autoSize = () => {
    setTimeout(() => {
      textArea.current.style.cssText = 'height:auto; padding:0';
      textArea.current.style.cssText = 'height:' + textArea.current.scrollHeight + 'px';
    }, 0);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => {
      dispatch(action_create_comment(comment))
        .then(req => {
          setComment(
            ...comment, {
            content: '',
            image: null,
            video: null,
            file: null,
            id_post: null
          });
          cleanInput()
          dispatch(action_read_comment_post(index))
        })
        .catch(error => {
          console.log(`fail send comment: ${error}`);
        });
    }, 300);
  }
  useEffect(() => {
    if (hideImg) {
      setHide(true)
    } else {
      setHide(false)
    }
  }, []);
  useEffect(() => {
    dispatch(action_read_user())
  }, []);
  return (
    <React.Fragment>
      <form onSubmit={onSubmit} className={style.comment}>
        {!hide &&
          <React.Fragment>
            {!user.profile_photo &&
              <i className={style.iconUser} />
            }
            {user.profile_photo &&
              <img className={style.img} src={user.profile_photo} />
            }
          </React.Fragment>
        }
        <textarea
          className={modal}
          ref={textArea}
          rows='1'
          onKeyDown={autoSize}
          name="content"
          value={content}
          placeholder="Write a comment"
          onChange={updateComment}
        />
        <button type="submit" className={style.button}>SEND</button>
      </form>
    </React.Fragment>
  )
}

export default WriteComments;