import React, { useState, useEffect } from 'react';
import style from "../styles/footerProfile.module.scss";
import { c } from '../helpers';

const FooterProfile = ({ communityInfo, userInfo }) => {
  const [dataProfile, setDataProfile] = useState({
    dateInfo: '',
    info: '',
    status:''
  })
  const [animation, setAnimation] = useState('');
  const { info, dateInfo } = dataProfile
  useEffect(() => {
    setDataProfile({
      ...dataProfile,
      dateInfo: communityInfo.createdAt,
      info: communityInfo.descripcion,
      status: userInfo.id_role
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(style.animation)
    }, 100);
  }, [])
  return (
    <React.Fragment>
      <div onClick={onclick} className={c(style.footer, animation)}>
        <h3>You had been {userInfo.id_role===1&&"admin"} {userInfo.id_role!==1&&"member"} of Community`s name since {dateInfo}</h3>
        <p>{info}</p>
      </div>
    </React.Fragment>
  )
}

export default FooterProfile;