import React from 'react';
import { c } from "../helpers";
import style from "../styles/upload.module.scss";

class Upload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            urls: [],
            showContent: `${style.none}`
        }
        this.onChangeFiles = this.onChangeFiles.bind(this);
        this.handleFiles = this.handleFiles.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.onChangeFiles(this.props.data)
            this.setState({ showContent: '' })
        }
    }

    onRemove(index) {
        let { files, urls } = this.state;
        let newFiles = files.filter((file, i) => i !== index);
        let newUrls = urls.filter((url, i) => i !== index);

        this.setState({
            ...this.state,
            files: newFiles,
            urls: newUrls
        });
    }

    onChangeFiles(e) {
        const files = e;
        [].forEach.call(files, this.handleFiles);
    }

    handleFiles(file) {
        let reader = new FileReader();
        reader.onloadend = () => {
            let imageUrl = window.URL.createObjectURL(file);
            this.setState({
                files: [file, ...this.state.files],
                urls: [imageUrl, ...this.state.urls]
            });
        }
        reader.readAsDataURL(file);
    }
    render() {
        const { urls, files, file, img, video, oneimg } = this.state;
        return (

            <div className={c(style.content, this.state.showContent)}>
                {
                    this.props.oneimg && 
                        <div className={style.contentOne} >
                            <img className={style.oneImage} src={urls} />
                        </div>
                    
                }
                {
                    this.props.img && (urls.map((url, i) => (
                        <div className={style.contentPreview} key={i}>
                            <i className={style.iconClose}
                                onClick={() => this.onRemove(i)} />
                            <img className={style.preview} src={url} />
                        </div>
                    )))
                }
                {
                    this.props.video && (urls.map((url, i) => (
                        <div className={style.contentPreview} key={i}>
                            <i className={style.iconClose}
                                onClick={() => this.onRemove(i)} />
                            <video muted preload="none" className={style.preview} loading="lazy" controls>
                                <source src={url} type="video/mp4" />
                                <source src={url} type="video/ogg" />
                            </video>
                        </div>
                    )))
                }
                {
                    this.props.file && (urls.map((url, i) => (
                        <div className={style.contentPreviewFile} key={i}>
                            <div className={style.rowFile}>
                                <span className={style.previewFile} >{files[i].name}</span>
                                <div className={style.iconBox}>
                                    <i className={style.iconCancel}
                                        onClick={() => this.onRemove(i)} />
                                </div>
                                <div className={style.ready}><span>READY</span></div>
                            </div>
                        </div>
                    )))
                }
            </div>
        );
    }
}

export default Upload;