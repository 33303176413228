import React, { Component } from 'react';
import { connect } from 'react-redux';

import Meta from '../components/metaContent';
import style from '../styles/tickets.module.scss';
import { get_tickets_id_event, post_tickets, put_tickets, delete_tickets } from '../actions/action_tickets.js';
import CardTicket from '../components/cardTicket';
import Button from '../components/button';
import TabBar from '../components/tabBar';
import { c } from '../helpers';
//import db from '../data/tickets.json';

class Tickets extends Component{

    constructor(props){
        super(props);
        this.state = {
            idEvent: this.props.match.path.match(/\d+/)[0],
            //currency: db.currency,
			filter: 'all',
        }
        global.tickets = this
    }

	componentDidMount() {
        this.getTickets();
    }
    getTickets(){
        return this.props.get_tickets_id_event(this.state.idEvent)
    }
    empty(){
        return this.props.tickets.length === 0;
    }
	changeFilter(value) {
		this.setState({filter: value})
    }
    loadTickets(){
        let tickets = [];
        for(let i = 0; this.props.tickets.length > i; i++){
            const ticket = this.props.tickets[i];
            tickets.push(
                <CardTicket 
                    key={i}
                    id={ticket.id}
                    info={ticket}
                    title={ticket.name}
                    description={ticket.description}
                    isVailable={true}
                    selected={ () => this.cardSelected(ticket)}
                    filter={this.state.filter}
                    fnAction={[
                        {fn: () =>{
                            //openDialog({message: 'You will lose the information of the tickets', buttonText: 'DELETE'}, () => this.deleteTickets(ticket.id))
                        }, name: "Delete"},
                    ]}
                    subDescription={[
                        {title: "Total", item: ticket.quantity_total},
                        {title: "Sold Out", item: (ticket.quantity_total - ticket.quantity_current)},
                        {title: "Avaliable", item: ticket.quantity_current, emphasis: true, classStyle: ticket.quantity_current === 0 ? style.out : ""},
                        ticket.reserved === 0 ? {item: ''} : {title: "R", item: ticket.reserved},
                        {item: this.parseCurrency(ticket.base_price, "es-US", "USD")},
                    ]}
            />
            )
        }
        return tickets;
    }
	cardSelected(dataCard){
        // let { currency } = this.state
        // const _currency = currency.map(el => el.symbol);
        /*openSheetSide({
            overflow: false,
            title: {placerholder: 'Ticket name', edit: true, value: dataCard.name},
            fields: {
                description: { tag: 'textarea', label: 'Description', placerholder: 'Event theme', value: dataCard.description},
                base_price: { tag: 'input', type: 'number', label: 'Price', placerholder: '0', value: dataCard.base_price },
                // base_price: { tag: 'input', type: 'number', label: 'Price', placerholder: '0', value: dataCard.base_price, select: { value: '', options: _currency, width: '30%'}},
                quantity_current: { tag: 'input', type: 'number', label: 'Quanty', placerholder: '0', width: '50%', value: dataCard.quantity_current},
                reserved: { tag: 'input', type: 'number', label: 'Reserved', placerholder: '0', width: '50%', value: dataCard.reserved},
                max_ticket_sell: { tag: 'input', type: 'number', label: 'Max per person', placerholder: '0', width: '50%', value: dataCard.max_ticket_sell},
                min_ticket_sell: { tag: 'input', type: 'number', label: 'Min per person', placerholder: '0', width: '50%', value: dataCard.min_ticket_sell},
                active: { tag: 'input', type: 'checkbox', label: 'ticket active', value: dataCard.active}
                }
        }, data => {
            data.name = data.title;
            data.id_event = this.state.idEvent;
            //data.currency = data.base_price.option ? data.base_price.option : _currency[0];
            data.base_price = data.base_price;
            data.quantity_total = dataCard.quantity_total;

            data.id_state = dataCard.id_state;
            data.id_coupon = dataCard.id_coupon;
            data.start = dataCard.start;
            data.end = dataCard.end;

            LoaderModule.start();
            this.props.put_tickets(dataCard.id, data)
            .then(res => {
                this.getTickets();
                LoaderModule.end();
            });
        })*/
    }
    newTicket(){
        // let { currency } = this.state
        // const _currency = currency.map(el => el.symbol);
        /*openSheetSide({
            title: {placerholder: 'Ticket name', edit: true, value: ''},
            fields: {
                description: { tag: 'textarea', label: 'Description', placerholder: 'Event theme', value: ''},
                base_price: { tag: 'input', type: 'number', label: 'Price', placerholder: '0', value: ''},
                // base_price: { tag: 'input', type: 'number', label: 'Price', placerholder: '0', value: '', select: { value: '', options: _currency, width: '30%'}},
                quantity_current: { tag: 'input', type: 'number', label: 'Quanty', placerholder: '0', width: '50%', value: ''},
                reserved: { tag: 'input', type: 'number', label: 'Reserved', placerholder: '0', width: '50%', value: ''},
                max_ticket_sell: { tag: 'input', type: 'number', label: 'Max per person', placerholder: '0', width: '50%', value: ''},
                min_ticket_sell: { tag: 'input', type: 'number', label: 'Min per person', placerholder: '0', width: '50%', value: ''},
                active: { tag: 'input', type: 'checkbox', label: 'ticket active', value: true}
                }
        }, data => {
            let date = new Date();

            data.name = data.title;
            data.id_state = 1;
            data.id_event = this.state.idEvent;
            data.id_coupon = 1;
            //data.currency = data.base_price.option ? data.base_price.option : _currency[0];
            data.quantity_total = data.quantity_current;
            data.base_price = data.base_price;
            data.start = date;
            data.end = new Date(date.setDate(date.getDate() + 1));

            LoaderModule.start();
            this.props.post_tickets(data)
            .then(res => {
                this.getTickets();
                LoaderModule.end();
            });
        })*/
    }
	deleteTickets(idTicket){
        //LoaderModule.start();
        this.props.delete_tickets(idTicket)
            .then(res => {
                this.getTickets();
                //LoaderModule.end();
            });
    }
	countTickets() {
		return this.props.tickets.length
	}
	countAvaliable() {
		return this.props.tickets.filter(ticket => ticket.quantity_current > 0).length
	}
	countSoldOut() {
		return this.props.tickets.filter(ticket => ticket.quantity_current === 0).length
    }
    parseCurrency(price, location, simbol){
        let _objOptions = {};
        if(simbol != undefined){
            _objOptions = {
                style: 'currency',
                currency: simbol
            }
        }
        return Number(price).toLocaleString(location, _objOptions);
    }
    render() {
        return (
            <main>
                <Meta title="Tickets" />
                { !this.empty() ? (
                    <section className={style.container}>
                        <header className={style.toolbar}>
                            <div></div>
                            <div className={style.right}>
                                <TabBar tabs={[
                                    {items: this.countTickets(), name: 'All'}, 
                                    {items: this.countAvaliable(), name: 'Avaliable'},
                                    {items: this.countSoldOut(), name: 'Sold out'}
                                ]}
                                value={this.changeFilter.bind(this)}
                                />
                                <Button classStyle={style.btnReserved} type={'filled'}>
                                    { 'Reserved' }
                                </Button>
                                <Button type={'filled'} onClick={e => this.newTicket()}>
                                    { 'new' }
                                </Button>
                            </div>
                        </header>
                        <section>
                            {
                                this.loadTickets()
                            }
                        </section>
                    </section>
                   ) : (
                        <section className={style.infoEmpty}>
                            <p className={style.title}>There are still no tickets for this event</p>
                            <i className={style.icon}></i>
                            <button
                                className={c(style.btn, this.state.newTicket && style.hidden)}
                                type="button"
                                onClick={e => this.newTicket()}
                            >
                                create
                            </button>
                        </section>
                   )
                }
            </main>
        );
    }
}

const mapDispatchToProps =  {
    get_tickets_id_event,
    post_tickets,
    put_tickets,
    delete_tickets
};
const mapStateToProps = state => {

	return {
		tickets: state.ticket.tickets
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);