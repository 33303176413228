import { combineReducers } from 'redux'
import reducerSignup from './reducer_signup'
import reducerSignin from './reducer_signin'
import { reducerEvents } from './reducer_events'
import { reducerStyles } from './reducer_styles'
import { reducerForm } from './reducer_form'
import { reducerUser } from './reducer_user'
import { reducerNews } from './reducer_news'
import { reducerPost } from './reducer_post'
import { reducerForum } from './reducer_forum'
import { reducerComments } from './reducer_comment_post'
import { reducerCommunities } from './reducer_communities'
import { reducerRooms } from './reducer_rooms'
import { reducerTracks } from './reducer_tracks'
import { reducerSponsors } from './reducer_sponsors'
import { reducerTypeSponsors } from './reducer_type_sponsor'
import { reducerPartnership } from './reducer_partnership'
import { reducerPartnershipPosition } from './reducer_partnership_position'
import { reducerExhibitors } from './reducer_exhibitor'
import { reducerTypeBooths } from './reducer_type_booth'
import { reducerTickets } from './reducer_tickets'
import { reducerAttendess } from './reducer_attendes'
import { reducerSpeakers } from './reducer_speakers' 

export const rootReducers = combineReducers({
  signup: reducerSignup,
  signin: reducerSignin,
  events: reducerEvents,
  styles: reducerStyles,
  form: reducerForm,
  news: reducerNews,
  user: reducerUser,
  post: reducerPost,
  forum: reducerForum,
  comment: reducerComments,
  community: reducerCommunities,
  rooms: reducerRooms,
  tracks: reducerTracks,
  sponsors: reducerSponsors,
  type_sponsor: reducerTypeSponsors,
  partnership: reducerPartnership,
  partnership_position: reducerPartnershipPosition,
  exhibitors: reducerExhibitors,
  type_booth: reducerTypeBooths,
  ticket: reducerTickets,
  attendes: reducerAttendess,
  speakers: reducerSpeakers
})
