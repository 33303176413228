import axios from 'axios'
import { ERROR_SIGNUP, SUCCESS_SIGNUP } from '../config/constants'
import { URL_BASE, SIGNUP_PATH } from '../config/api'

function success_signup (signup) {
  return {
    type: SUCCESS_SIGNUP,
    signup
  }
}

function error_signup (error) {
  return {
    type: ERROR_SIGNUP,
    error
  }
}

function fetch_signup (data) {
  const urlInvitation = data.urlInvitation
  console.log(urlInvitation)
  if (!urlInvitation) {
    return axios.post(`${URL_BASE}${SIGNUP_PATH}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  } else {
    return axios.post(`${urlInvitation}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}

export default function action_signup (data) {
  return dispatch => {
    return fetch_signup(data)
      .then(data => dispatch(success_signup(true)))
      .catch(error => dispatch(error_signup(error)))
  }
}
