import React from 'react';
import { Redirect } from 'react-router-dom';
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from '../config/routes';

const IndexRedirect = (props) => {
    return (
        <Redirect to={props.auth ? DASHBOARD_ROUTE : LOGIN_ROUTE} />
    );
};

export default IndexRedirect;