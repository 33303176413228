import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	get_attendes_for_event,
	put_attendess
} from '../actions/action_attendes.js';
import style from '../styles/attendes.module.scss';
import CardAttend from './cardAttends';
import Search from './search';
import TabBar from './tabBar';

const Attendes = (props) => {
	const { eventId } = useParams();
	const [filter, changeFilter] = useState('all');
	const [search, updateSearch] = useState('');
	const attendes_event = useSelector(state => state.attendes.attendes_event);

	const dispatch = useDispatch();

	const getAttendesForRvent = () => dispatch(get_attendes_for_event(eventId));

	useEffect(() => {
		getAttendesForRvent();
	});

	const isEmpty = () => attendes_event.length === 0;
	
	const changeStatus = async (id_card) => {
		let {id, is_present} = attendes_event[id_card];
		// let attendes = this.state.attendes
		// attendes[id].confirmed = !attendes[id].confirmed
		// this.setState({attendes})
		const data = { is_present: !is_present }
		//LoaderModule.start();
		try {
			await dispatch(put_attendess(id, data));
			getAttendesForRvent();
			//LoaderModule.end();
		} catch (attendeesError) {
			/* something happened */
		}
	}

	const loadAttends = () => attendes_event.map(
		(attend, i) => (
			<CardAttend 
				info={attend} 
				key={i} 
				id={attend.id} 
				changeStatus={() => changeStatus(i)}
				fnAction={[
					{fn: () => {
						// openDialog({message: 'You will lose the information of the attender', buttonText: 'DELETE'}, () => this.deleteAttends(attend.id))
					}, name: "Delete"},
				]}
				search={search}
				filter={filter}
				/>
	));

	/**
	 * DELETE ATTENDS SHOULD BE ON ATTEND SCREEN OR MODAL
	 */
	/*const deleteAttends = async (id) => {
		try {
			await dispatch(delete_attendess(id));
			getAttendesForRvent();
			//LoaderModule.end();
			// let attends = this.props.attendes_event.filter((attend, i) => attend.id != id)		
	        // this.setState({attends});
		} catch(deleteError) {
			// something happened
		}
	};*/

	const countAttends = () => attendes_event.length;

	const countConfirmed = () => attendes_event
		.filter(attend => attend.is_present).length;

	const countUnconfirmed = () => attendes_event
		.filter(attend => !attend.is_present).length;

	return (
		<main>
			{
				isEmpty() ? 
				<section className={style.infoEmpty}>
					<p>The attendes will appear here</p>
					<i></i>
				</section>
				:
				<section className={style.container}>
					<header className={style.toolbar}>
						<Search method={updateSearch}/>
						<TabBar tabs={[
							{items: countAttends(), name: 'All'}, 
							{items: countConfirmed(), name: 'Confirmed'},
							{items: countUnconfirmed(), name: 'Unconfirmed'}
						]}
						value={changeFilter}
						/>
					</header>
					<div className={style.table}>
						<header className={style.headtable}>
							<span>PERSON</span>
							<span>TICKET</span>
							<span>STATUS</span>
						</header>
						<section>
							{loadAttends()}
						</section>
					</div>
				</section>
			}
		</main>
	);
}

export default Attendes;