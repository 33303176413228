import axios from 'axios';
import { 
	URL_BASE,
	SIGNIN_PATH
} from '../config/api';


export function login(username, password){
	return axios.post(URL_BASE+SIGNIN_PATH,{
		username: username,
		password: password
	}).then(res => console.log(res)).catch( error => { console.log(error)});
}

/* if user has logged in before, get token
 * (string|null):token  bearer token for authentication, if not available then null
 * boolean:auth         user has logged in before
*/
export function getLogin() {
	const token = localStorage.getItem("token");

	return {
		token,
		auth: !!token && token.length > 0
	};
}