import { FORM } from '../config/constants'
let initial_state = {
  form: []
}
const reducerForm = (state = initial_state, action) => {
  if (action.type === FORM) {
    return {
      ...state,
      form: action.form
    }
  }
  return state
}

export { reducerForm }
