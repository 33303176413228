import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import style from '../styles/list.module.scss';
import { c } from '../helpers';

const List = (props) => {
  const location = useLocation();

  const actived = () => (location.pathname === props.to) && style.actived;

  return(
    <button className={c(style.list, actived())}>
      <Link to={props.to}/>
      <span className={style[`icon-${props.icon}`]}></span>
      <p>{props.title}</p>
    </button>
  );
}

List.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

export default List;
