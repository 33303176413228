import axios from 'axios';

import {
    READ_PARTNERSHIP_SPONSORS,
    READ_PARTNERSHIP_SPONSOR,
    SUCCESS_PARTNERSHIP_SPONSOR_CREATE,
    SUCCESS_PARTNERSHIP_SPONSOR_UPDATE,
    SUCCESS_PARTNERSHIP_SPONSOR_DELETE,
    ERROR_PARTNERSHIP_SPONSOR_READ,
} from '../config/constants'
import { URL_BASE_API, TYPE_PARTNERSHIP_PATH } from '../config/api';

const headers = {
    headers: {
        skip: true
    }
}

const headersFormData = {
    headers: {
        skip: true,
        'Content-Type': 'multipart/form-data',
    }
}

// CRUD PARTNERSHIP_SPONSOR

const get_partnerships = () => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${TYPE_PARTNERSHIP_PATH}`, headers)
            .then(res => res.data.payload.data)
            .then(partnerships => partnerships.sort((partnership, b) => partnership.id - b.id))
            .then(data => {
                dispatch({
                    type: READ_PARTNERSHIP_SPONSORS,
                    partnerships: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_partnership_read(error_read))
                reject(error_read)
            });
         });

    }

}

const get_partnership = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${TYPE_PARTNERSHIP_PATH}${ID}`, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: READ_PARTNERSHIP_SPONSOR,
                    partnership: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_partnership_read(error_read))
                reject(error_read)
            });
         });

    }

}

const post_partnerships = data => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .post(`${URL_BASE_API}${TYPE_PARTNERSHIP_PATH}`, data, headersFormData)
            .then(res => res.data.payload.newdate)
            .then(data => {
                dispatch({
                    type: SUCCESS_PARTNERSHIP_SPONSOR_CREATE,
                    create: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_partnership_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const put_partnerships = (ID, data) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .put(`${URL_BASE_API}${TYPE_PARTNERSHIP_PATH}/${ID}`, data, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: SUCCESS_PARTNERSHIP_SPONSOR_UPDATE,
                    update: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_partnership_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const delete_partnerships = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .delete(`${URL_BASE_API}${TYPE_PARTNERSHIP_PATH}/${ID}`, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_PARTNERSHIP_SPONSOR_DELETE,
                    delete: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_partnership_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

////////////PARTNERSHIP_SPONSOR Read/////////////

const error_partnership_read = (error_read) => {
  return {
    type: ERROR_PARTNERSHIP_SPONSOR_READ,
    error_read
  }
}

export { get_partnerships, get_partnership, post_partnerships, put_partnerships, delete_partnerships }