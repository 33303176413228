import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { get_exhibitors, get_exhibitor, post_exhibitors, put_exhibitors, delete_exhibitors } from '../actions/action_exhibitor.js';
import { get_type_booths, get_type_booth, post_type_booths, put_type_booths } from '../actions/action_type_booth.js';
import { get_partnerships, get_partnership, post_partnerships, put_partnerships } from '../actions/action_partnership.js';
import { get_partnership_positions, get_partnership_position, post_partnership_positions, put_partnership_positions } from '../actions/action_partnership_position.js';

import Meta from '../components/metaContent';
import style from '../styles/exhibitors.module.scss';
import CardExhibitors from '../components/cardExhibitors';
import Button from '../components/button';
import Search from '../components/search';
import { c, urlFor } from '../helpers';
import db from '../data/exhibitors.json';

class Exhibitors extends Component{
    constructor(props){
        super(props);
        this.state = {
            idEvent: this.props.match.path.match(/\d+/)[0],
            type_booth: [],
            partnership: [],
            partnership_position: [],
            card_exhibitors: [],
            countExhibitors: 0,
            currency: db.currency.map(el => el.symbol),
            newExhibitor: false,
            isCategoryActive: true,
            search: '',
            filter: ''
        }
        // global.Exhibitors = this
    }
	componentDidMount() {
        Promise.all([this.getAll()]).then(([res]) => {
            const _category = this.state.type_booth;
            if(_category.length > 0) {
                this.changeFilter(_category[0].id);
                this.setState({isCategoryActive: _category[0].active});
            }
        });
    }
    orderSort(data, compare){
        return data.sort((a, b) => a[compare] - b[compare]);
    }
    getAll(){
        const _exhibitor = this.getExhibitors();
        const _typeExhibitor = this.getTypeExhibitor();
        const _partnerships = this.getPartnerships();
        const _partnershipPositions = this.getPartnershipPositions();
        return Promise.all([_exhibitor, _typeExhibitor, _partnerships, _partnershipPositions]).then(([exhibitors, typeExhibitors, partnerships, partnershipPositions]) => {
            let _cardExhibitors = [];

            for(let i = 0; i < exhibitors.length; i++ ){
                const _idTypeExhibitor = exhibitors[i].id_type_booth;
                const _idPartnership = exhibitors[i].id_partnership;
                const _idExhibitor     = exhibitors[i].id;
                const type_booth = typeExhibitors.filter( typeExhibitor => _idTypeExhibitor ===  typeExhibitor.id);
                const partnership = partnerships.filter( partnership => _idPartnership ===  partnership.id);
                const _dataParnetship = partnership[0];
                const partnershipPosition = partnershipPositions.filter( partnershipPosition => _idPartnership === partnershipPosition.id_partnership);
                const _data = {
                        id: _idExhibitor,
                        id_partnership: _idPartnership,
                        name: _dataParnetship.name,
                        description: _dataParnetship.description,
                        host: _dataParnetship.host,
                        logo: urlFor(_dataParnetship.logo),
                        registry_number: _dataParnetship.registry_number,
                        url: _dataParnetship.url,
                        active: _dataParnetship.active,
                        createdAt: _dataParnetship.createdAt,
                        updatedAt: _dataParnetship.updatedAt,
                        type_booth: type_booth[0],
                        partnershipPosition: partnershipPosition[0]
                };
                _cardExhibitors.push(_data);
              }
              return this.setState({
                    partnership: partnerships,
                    partnership_position: partnershipPositions,
                    card_exhibitors: _cardExhibitors
                }, () => this.countExhibitorsCategory())
          });
    }
    getExhibitors(){
        return this.props.get_exhibitors()
            .then(exhibitors => {
                const _filterExhibitor = exhibitors.filter( exhibitor => exhibitor.id_event === this.state.idEvent)
                console.log(_filterExhibitor)
                this.setState({exhibitors: _filterExhibitor})
                return _filterExhibitor
            });
    }
    getTypeExhibitor(){
        return this.props.get_type_booths()
            .then(type_booth => { return type_booth });
    }
    empty(){
        return this.props.exhibitors.length === 0;
    }
    getPartnerships(){
        return this.props.get_partnerships()
        .then( partnership => {return partnership})
    }
    getPartnershipPositions(){
        return this.props.get_partnership_positions()
        .then( partnership_positions => { return partnership_positions })
    }
    loadExhibitors(){
        let exhibitors = [];
        let exhibitor;
        for (let i = 0; this.state.card_exhibitors.length > i; i++){
            exhibitor = this.state.card_exhibitors[i];
            exhibitors.push(
                <CardExhibitors 
                    key={i}
                    id={exhibitor.id}
                    info={exhibitor}
                    title={exhibitor.name}
                    description={exhibitor.description}
                    isVailable={exhibitor.active}
                    selected={() => {}}
                    search={this.state.search}
                    filter={this.state.filter}
                    fnAction={[
                        {fn: () => {
                            // openDialog({message: 'You will lose the information of the exhibitors', buttonText: 'DELETE'}, () => this.deleteExhibitors(exhibitor));
                        }, name: "Delete" },
                        {fn: () => {
                            // this.editExhibitors(exhibitor)
                        }, name: "Edit"},
                    ]}
                    subDescription={[]}
            />
            )
        }
        return exhibitors;
    }
    newExhibitors(){
        // let {type_booth} = this.state;
        // const _type_booth = type_booth.map(el => el.name);
        // openSheetSide({
        //     title: {placerholder: 'Exhibitor name', edit: true, value: ''},
        //     fields: {
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert description', value: ''},
        //         contact_name: { tag: 'input', label: 'Contact Name', placerholder: 'Insert contact name', width: '50%', value: ''},
        //         email: { tag: 'input', label: 'Email', placerholder: 'Insert email name', width: '50%', value: ''},
        //         contact_position: { tag: 'input', type: 'number', label: 'Contact position', placerholder: 'Insert contact position', width: '50%', value: ''},
        //         type_booth: { tag: 'select', label: 'Type of Booth', width: '50%', value: '', options: _type_booth, actions: [{icon: 'add', method: (schema, setSchema) => this.newTypeBooths(schema, setSchema)}, {icon: 'blog', method: (schema, setSchema) => this.editTypeBooths('type_booth', schema, setSchema)}]},
        //         url: { tag: 'input', label: 'URL', value: '' },
        //         logo: { tag: 'upload', label: 'Logo', value: ''}
        //     }
        // }, data => {
        //     LoaderModule.start();
        //     const _partner = {
        //         name: data.title,
        //         description: data.description,
        //         url: data.url,
        //         registry_number: "",
        //         host: "",
        //         logo: data.logo,
        //         active: true
        //     };
        //     const formDataPartnership = new FormData();    
        //     for (const key in _partner) formDataPartnership.append(key, _partner[key]);
        //     this.props.post_partnerships(formDataPartnership)
        //         .then( res_partnership => {
        //             const parnerPosition = {
        //                 id_partnership: res_partnership.id,
        //                 name_contact: data.contact_name,
        //                 email: data.email,
        //                 job_title: "",
        //                 description: "",
        //                 phone: "",
        //                 active: false,
        //             }
        //             this.props.post_partnership_positions(parnerPosition)
        //                 .then(() => {
        //                     const { idEvent } = this.state;
        //                     const _dataTypeBooth =  this.findTypeBooth(data.type_booth);
        //                     const _dataSendExhibitor = {
        //                         id_event: idEvent,
        //                         id_partnership: res_partnership.id,
        //                         id_type_booth: _dataTypeBooth ? _dataTypeBooth.id : type_booth[0].id,
        //                     }
        //                     this.props.post_exhibitors(_dataSendExhibitor).then(() => {
        //                         this.getAll();
        //                         LoaderModule.end();
        //                     })
        //                 })
        //         })
        // })
    }
    editExhibitors(dataCard){
        // let {type_booth} = this.state;
        // const _type_booth = type_booth.map(el => el.name);
        // openSheetSide({
        //     overflow: false,
        //     title: {placerholder: 'Exhibitors name', edit: true, value: dataCard.name},
        //     fields: {
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert description', value: dataCard.description},
        //         contact_name: { tag: 'input', label: 'Contact Name', placerholder: 'Insert contact name', width: '50%', value: dataCard.partnershipPosition.name_contact},
        //         email: { tag: 'input', label: 'Email', placerholder: 'Insert email name', width: '50%', value: dataCard.partnershipPosition.email},
        //         contact_position: { tag: 'input', type: 'number', label: 'Contact position', placerholder: 'Insert contact position', width: '50%', value: dataCard.partnershipPosition.id},
        //         type_booth: { tag: 'select', label: 'Type of Booth', width: '50%', value: dataCard.type_booth.name, options: _type_booth, actions: [{icon: 'add', method: (schema, setSchema) => this.newTypeBooths(schema, setSchema)}, {icon: 'blog', method: (schema, setSchema) => this.editTypeBooths('type_booth', schema, setSchema)}]},
        //         url: { tag: 'input', label: 'URL', value: dataCard.url },
        //         logo: { tag: 'upload', label: 'Logo', value: dataCard.logo}
        //         }
        // }, data => {
        //     LoaderModule.start();
        //     const _partner = {
        //         id: dataCard.id_partnership,
        //         name: data.title,
        //         description: data.description,
        //         url: data.url,
        //         registry_number: dataCard.registry_number,
        //         host: dataCard.host,
        //         logo: data.logo,
        //         active: true,
        //         return_data: true
        //     };
        //     const formDataPartnership = new FormData();    
        //     for (const key in _partner) formDataPartnership.append(key, _partner[key]);
        //     this.props.put_partnerships(dataCard.id_partnership, formDataPartnership)
        //     .then((res_partnership) => {
        //         const isEdit = dataCard.partnershipPosition.name_contact != data.contact_name || dataCard.partnershipPosition.email != data.email;
        //         const isNew = data.contact_position != dataCard.partnershipPosition.id
        //         const parnerPosition = {
        //             id_partnership: dataCard.id_partnership,
        //             name_contact: data.contact_name,
        //             email: data.email,
        //             job_title: dataCard.partnershipPosition.job_title,
        //             description: dataCard.partnershipPosition.description,
        //             phone: dataCard.partnershipPosition.phone,
        //             active: dataCard.partnershipPosition.active,
        //         }
        //         const _dataTypeBooth =  this.findTypeBooth(data.type_booth);
        //         if(isEdit || isNew){
        //             const _query = isNew ? this.props.post_partnership_positions(parnerPosition) : this.props.put_partnership_positions(dataCard.partnershipPosition.id, parnerPosition);
        //             _query.then(() => put_exhibitor(_dataTypeBooth.id))
        //         }else{
        //             put_exhibitor(_dataTypeBooth.id)
        //         }
        //     });
        // })
        // const put_exhibitor = (id_type_booth) => {
        //     let _dataPut = {
        //         id_event: this.state.idEvent,
        //         id_partnership: dataCard.id_partnership,
        //         id_type_booth,   
        //     }
        //     this.props.put_exhibitors(dataCard.id, _dataPut)
        //     .then(() => {
        //         this.getAll();
        //         LoaderModule.end();
        //     })
        // }
    }
	deleteExhibitors(cardExhibitor){
        // LoaderModule.start();
        // Promise.all([this.props.delete_exhibitors(cardExhibitor.id)])
        // .then(([deleteExhibitor]) => {
        //     this.getAll();
        //     LoaderModule.end();
        // })
    }
    // Type of Booth
    newTypeBooths(schema, setSchema){
        // let { currency } = this.state
        // openModal({
        //     title: {placerholder: 'Type of Booth'},
        //     fields: {
        //         name: { tag: 'input', label: 'Name', placerholder: 'Insert name', value: ''},
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert description', value: ''},
        //         cost: { tag: 'input', type: 'number', label: 'Price', placerholder: 'Insert price', value: '', select: { value: '', options: currency, width: '30%'}},
        //         size_width: { tag: 'input', type: 'number', label: 'Width', placerholder: 'Insert Width', value: '', width: '50%'},
        //         size_height: { tag: 'input', type: 'number', label: 'Height', placerholder: 'Insert Height', value: '', width: '50%'},
        //         active: { tag: 'input', type: 'checkbox', label: 'active', value: ''},
        //         }
        // }, data => {
        //     let json = schema
        //     json.fields.type_booth.options.push(data.name)
        //     json.fields.type_booth.value = data.name
        //     setSchema({...json})
        //     const _data = {...data};
        //     _data.currency = data.cost.option ? data.cost.option : currency[0];
        //     _data.cost = data.cost.value;
        //     _data.active = _data.active ? true : false;
        //     LoaderModule.start();
        //     this.props.post_type_booths(_data)
        //     .then(res => {
        //         this.getTypeExhibitor();
        //         LoaderModule.end();
        //     });
        // })
    }
    editTypeBooths(select, schema, setSchema){
        // let { currency } = this.state
        // const value = document.getElementById(select).value;
        // const _selected = this.findTypeBooth(value);
        // openModal({
        //     title: {placerholder: 'Type of Booth'},
        //     fields: {
        //         name: { tag: 'input', label: 'Name', placerholder: 'Insert name', value: _selected.name},
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert description', value: _selected.description},
        //         cost: { tag: 'input', type: 'number', label: 'Price', value: _selected.cost, select: { options: currency, value: _selected.currency, width: '30%'}},
        //         size_width: { tag: 'input', type: 'number', label: 'Width', placerholder: 'Insert Width', value: _selected.size_width, width: '50%'},
        //         size_height: { tag: 'input', type: 'number', label: 'Height', placerholder: 'Insert Height', value: _selected.size_height, width: '50%'},
        //         active: { tag: 'input', type: 'checkbox', label: 'active', value: _selected.active},
        //         }
        // }, data => {
        //     LoaderModule.start();
        //     if(schema || setSchema){
        //         let json = schema
        //         json.fields.type_booth.options.push(data.name)
        //         json.fields.type_booth.value = data.name
        //         setSchema({...json})
        //     }
        //     const _data = {...data};
        //     _data.currency = data.cost.option ? data.cost.option : currency[0];
        //     _data.cost = data.cost.value;
        //     this.props.put_type_booths(_selected.id, _data)
        //     .then(res => {
        //         this.getTypeExhibitor();
        //         if (select == "selectorCategory" && data.active) this.setState({isCategoryActive: data.active})
        //         LoaderModule.end();
        //     });
        // })
    }
    findTypeBooth(typeBooth){
        const { type_booth } = this.state;
        return type_booth.find(el => el.name == typeBooth);
    }
    handleImg(e, name){
        const _value        = e.target.value,
            _img            = e.target.files["0"];
        this.setState({
            [name]: _img,
            [`img${name}`]: _img
        }, () => {
            this.transformBase64(name);
        });
    }
    transformBase64(name) {
        if(this.state[`img${name}`] != null){
            let reader = new FileReader();
            reader.readAsDataURL(this.state[name]);
            reader.onerror    = (e) => {
                                    reader.abort();
                                    // this.errorHandler(e);
                                };
            // reader.onprogress = (e) => this.handleFileProgress(e);
        
            // if (reader.readyState == 1) {
            //     this.stateUpload();
            // }
            reader.onload = () => {
                this.setState({
                    [`img${name}`]: reader.result
                });
            }
        }
    }
    // search & filter
	updateSearch(value) {
		this.setState({search: value})
	}
	changeFilter(value) {
		this.setState({filter: value}, () => this.countExhibitorsCategory())
    }
    countExhibitorsCategory() {
        const _filterCategory = this.props.exhibitors.filter(exhibitor => exhibitor.id_type_booth == this.state.filter);
        this.setState({countExhibitors: _filterCategory.length});
    }
    // Filter Type of Booth Dashboard

    isCategoryActive(){
        let {isCategoryActive} = this.state;
        if(isCategoryActive) return ''
        else return style.showMsg
    }
    render() {  
        return (
            <main>
                <Meta title="Exhibitors" />
                { !this.empty() ? (
                    <section className={style.container}>
                        <header className={style.toolbar}>
                            <Search method={this.updateSearch.bind(this)}/>
                            <div className={style.selectorCategory}>
                                <span>{this.state.countExhibitors}</span>
                                <select 
                                    id={"selectorCategory"}
                                    onChange={e => {
                                        const _dataTypeBooth = this.props.type_booths.find(type => type.name == e.target.value);
                                        this.changeFilter(_dataTypeBooth.id);
                                        this.setState({isCategoryActive: _dataTypeBooth.active});
                                    }}
                                >
                                    {this.props.type_booths.length < 0 ?
                                        <option defaultValue="" value="">Insert Category</option>
                                    :this.props.type_booths.map( (option, index) => {
                                        return <option key={option.id ? option.id : index} value={option.name}>{option.name}</option>
                                    })}
                                </select>
                            </div>
                            <Button type={'filled'} onClick={e => this.newExhibitors()}>
                                { 'new' }
                            </Button>
                        </header>
                        <div className={c(style.categoryDisabled, this.isCategoryActive())}>
                            <span>this category is currently disabled</span>
                            <button onClick={e => this.editTypeBooths("selectorCategory")} type="button">
                                <i className={style.editIcon}></i>
                            </button>
                        </div>
                        <section className={!!(this.state.countExhibitors > 0) && style.noCard}>
                            {this.state.countExhibitors > 0 ?
                                this.loadExhibitors()
                                : 
                                <Fragment>
                                    <p className={style.title}>No exhibitor added in this category</p>
                                    <i className={style.icon}></i>
                                </Fragment>
                            }
                        </section>
                    </section>
                   ) : (
                        <section className={style.infoEmpty}>
                            <p className={style.title}>There are still no exhibitors for this event</p>
                            <i className={style.icon}></i>
                            <button 
                                className={c(style.btn, this.state.newExhibitor && style.hidden)}
                                type="button"
                                onClick={e => this.newExhibitors()}
                            >
                                create
                            </button>
                        </section>
                   )
                }
            </main>
        );
    }
}

const mapDispatchToProps =  {
    get_exhibitors,
    get_exhibitor,
    post_exhibitors,
    put_exhibitors,
    delete_exhibitors,
    
    get_type_booths,
    get_type_booth,
    post_type_booths,
    put_type_booths,

    get_partnerships,
    get_partnership,
    post_partnerships,
    put_partnerships,

    get_partnership_positions,
    get_partnership_position,
    post_partnership_positions,
    put_partnership_positions
};

const mapStateToProps = state => {

	return {
        //exhibitors
        exhibitors: state.exhibitors.exhibitors,
        exhibitor: state.exhibitors.exhibitor,
        exhibitor_create: state.exhibitors.create,
        exhibitor_update: state.exhibitors.update,
        exhibitor_delete: state.exhibitors.delete,

        //type exhibitors
        type_booths: state.type_booth.type_booths,
        type_booth: state.type_booth.type_booth,
        type_booth_create: state.type_booth.create,
        type_booth_update: state.type_booth.update,

        //partnership
        partnerships: state.partnership.partnerships,
        partnership: state.partnership.partnership,
        partnership_create: state.partnership.create,
        partnership_update: state.partnership.update,

        // partnership position
        partnership_positions: state.partnership_position.partnership_positions,
        partnership_position: state.partnership_position.partnership_position,
        partnership_position_create: state.partnership_position.create,
        partnership_position_update: state.partnership_position.update,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Exhibitors);