//Auth const
export const GET_USER = 'GET_USER'
export const LOGGING = 'LOGGING'
export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'
export const UPDATE_USER = 'UPDATE_USER'
export const PHOTO_USER = 'PHOTO_USER'


//Events const
export const CREATE_EVENT = 'CREATE_EVENT'
export const READ_EVENTS = 'READ_EVENTS'
export const READ_EVENT = 'READ_EVENT'
export const SEARCH_EVENTS = 'SEARCH_EVENTS'
export const SUCCESS_CREATE = 'SUCCESS_CREATE'
export const ERROR_EVENT = 'ERROR_EVENT'
export const READ_CARDS = 'READ_CARDS'
export const EVENT_FILTER = 'EVENT_FILTER'
export const LOADING_EVENT = 'LOADING_EVENT'

//signup conts
export const SUCCESS_SIGNUP = 'SUCCESS_SIGNUP'
export const ERROR_SIGNUP = 'ERROR_SIGNUP'

//communities conts
export const CREATE_COMMUNITY = 'CREATE_COMMUNITY'
export const FORM = 'FORM'
export const READ_COMMUNITY = 'READ_COMMUNITY'
export const READ_COMMUNITIES = 'READ_COMMUNITIES'

export const SUCCESS_CREATE_COMMUNITY = 'SUCCESS_CREATE_COMMUNITY'
export const SUCCESS_READ_COMMUNITY = 'SUCCESS_READ_COMMUNITY'
export const SUCCESS_READ_COMMUNITIES = 'SUCCESS_READ_COMMUNITIES'

export const ERROR_CREATE_COMMUNITY = 'ERROR_CREATE_COMMUNITY'
export const ERROR_READ_COMMUNITY = 'ERROR_READ_COMMUNITY'
export const ERROR_READ_COMMUNITIES = 'ERROR_READ_COMMUNITIES'

//signin conts
export const SUCCESS_SIGNIN = 'SUCCESS_SIGNIN'
export const ERROR_SIGNIN = 'ERROR_SIGNIN'

//Styles
export const STYLES = 'STYLES'
export const CHANGE_VIEW = 'CHANGE_VIEW'
export const SHOW_DELETE_POST = 'SHOW_DELETE_POST'
export const SHOW_DELETE_COMMENT = 'SHOW_DELETE_COMMENT'
export const SHOW_CANCEL = 'SHOW_CANCEL'
export const SHOW_DETAILS = 'SHOW_DETAILS'
export const VALID_SUDMIT = 'VALID_SUDMIT'
export const ENABLE_PHOTO = 'ENABLE_PHOTO'

// --> UserDashboard

//POST
export const READ_POSTS = 'READ_POSTS'
export const DELETE_POST = 'DELETE_POST'
export const READ_POST = 'READ_POST'
export const CREATE_POST = 'CREATE_POST'
export const ERROR_POST = 'ERROR_POST'
export const READ_LIKE = 'READ_LIKE'
export const ADD_LIKE = 'ADD_LIKE'

//FORUM
export const ERROR_FORUM = 'ERROR_FORUM'
export const READ_FORUM = 'READ_FORUM'

//NEWS
export const READ_NEWS = 'READ_NEWS'
export const ERROR_NEWS = 'ERROR_NEWS'

//USER
export const READ_USER = 'READ_USER'
export const ERROR_USER = 'ERROR_USER'

//COMMENTS
export const READ_COMMENT = 'READ_COMMENT'
export const CREATE_COMMENT = 'CREATE_COMMENT'
export const ERROR_COMMENT = 'ERROR_COMMENT'
export const DELETE_COMMENT = 'DELETE_COMMENT'

// <-- UserDashboard




//DnD Items
export const ItemTypes = {
  INPUT: 'input',
  MODULE: 'mod',
  TEXT: 'text'
}

// ROOMS CONST

export const READ_ROOMS = 'READ_ROOMS'
export const READ_ROOM = 'READ_ROOM'
export const SEARCH_ROOMS = 'SEARCH_ROOMS'
export const SUCCESS_ROOM_CREATE = 'SUCCESS_ROOM_CREATE'
export const SUCCESS_ROOM_UPDATE = 'SUCCESS_ROOM_UPDATE'
export const SUCCESS_ROOM_DELETE = 'SUCCESS_ROOM_DELETE'
export const ERROR_ROOM_READ = 'ERROR_ROOM_READ'

// TRACKS CONST

export const READ_TRACKS = 'READ_TRACKS'
export const READ_TRACK = 'READ_TRACK'
export const SEARCH_TRACKS = 'SEARCH_TRACKS'
export const SUCCESS_TRACK_CREATE = 'SUCCESS_TRACK_CREATE'
export const SUCCESS_TRACK_UPDATE = 'SUCCESS_TRACK_UPDATE'
export const SUCCESS_TRACK_DELETE = 'SUCCESS_TRACK_DELETE'
export const ERROR_TRACK_READ = 'ERROR_TRACK_READ'

// SPONSORS CONST

export const READ_SPONSORS = 'READ_SPONSORS'
export const READ_SPONSOR = 'READ_SPONSOR'
export const SUCCESS_SPONSOR_CREATE = 'SUCCESS_SPONSOR_CREATE'
export const SUCCESS_SPONSOR_UPDATE = 'SUCCESS_SPONSOR_UPDATE'
export const SUCCESS_SPONSOR_DELETE = 'SUCCESS_SPONSOR_DELETE'
export const SEARCH_SPONSORS = 'SEARCH_SPONSORS'
export const ERROR_SPONSOR_READ = 'ERROR_SPONSOR_READ'

// TYPE SPONSORS CONST

export const READ_TYPE_SPONSORS = 'READ_TYPE_SPONSORS'
export const READ_TYPE_SPONSOR = 'READ_TYPE_SPONSOR'
export const SUCCESS_TYPE_SPONSOR_CREATE = 'SUCCESS_TYPE_SPONSOR_CREATE'
export const SUCCESS_TYPE_SPONSOR_UPDATE = 'SUCCESS_TYPE_SPONSOR_UPDATE'
export const SUCCESS_TYPE_SPONSOR_DELETE = 'SUCCESS_TYPE_SPONSOR_DELETE'
export const SEARCH_TYPE_SPONSORS = 'SEARCH_TYPE_SPONSORS'
export const ERROR_TYPE_SPONSOR_READ = 'ERROR_TYPE_SPONSOR_READ'

// PARTNERSHIP SPONSORS CONST

export const READ_PARTNERSHIP_SPONSORS = 'READ_PARTNERSHIP_SPONSORS'
export const READ_PARTNERSHIP_SPONSOR = 'READ_PARTNERSHIP_SPONSOR'
export const SUCCESS_PARTNERSHIP_SPONSOR_CREATE =
  'SUCCESS_PARTNERSHIP_SPONSOR_CREATE'
export const SUCCESS_PARTNERSHIP_SPONSOR_UPDATE =
  'SUCCESS_PARTNERSHIP_SPONSOR_UPDATE'
export const SUCCESS_PARTNERSHIP_SPONSOR_DELETE =
  'SUCCESS_PARTNERSHIP_SPONSOR_DELETE'
export const SEARCH_PARTNERSHIP_SPONSORS = 'SEARCH_PARTNERSHIP_SPONSORS'
export const ERROR_PARTNERSHIP_SPONSOR_READ = 'ERROR_PARTNERSHIP_SPONSOR_READ'

// PARTNERSHIP POSITION SPONSORS CONST

export const READ_PARTNERSHIP_POSITION_SPONSORS =
  'READ_PARTNERSHIP_POSITION_SPONSORS'
export const READ_PARTNERSHIP_POSITION_SPONSOR =
  'READ_PARTNERSHIP_POSITION_SPONSOR'
export const SUCCESS_PARTNERSHIP_POSITION_SPONSOR_CREATE =
  'SUCCESS_PARTNERSHIP_POSITION_SPONSOR_CREATE'
export const SUCCESS_PARTNERSHIP_POSITION_SPONSOR_UPDATE =
  'SUCCESS_PARTNERSHIP_POSITION_SPONSOR_UPDATE'
export const SUCCESS_PARTNERSHIP_POSITION_SPONSOR_DELETE =
  'SUCCESS_PARTNERSHIP_POSITION_SPONSOR_DELETE'
export const SEARCH_PARTNERSHIP_POSITION_SPONSORS =
  'SEARCH_PARTNERSHIP_POSITION_SPONSORS'
export const ERROR_PARTNERSHIP_POSITION_SPONSOR_READ =
  'ERROR_PARTNERSHIP_POSITION_SPONSOR_READ'

// EXHIBITORS CONST

export const READ_EXHIBITORS = 'READ_EXHIBITORS'
export const READ_EXHIBITOR = 'READ_EXHIBITOR'
export const SUCCESS_EXHIBITOR_CREATE = 'SUCCESS_EXHIBITOR_CREATE'
export const SUCCESS_EXHIBITOR_UPDATE = 'SUCCESS_EXHIBITOR_UPDATE'
export const SUCCESS_EXHIBITOR_DELETE = 'SUCCESS_EXHIBITOR_DELETE'
export const SEARCH_EXHIBITORS = 'SEARCH_EXHIBITORS'
export const ERROR_EXHIBITOR_READ = 'ERROR_EXHIBITOR_READ'

// TYPE BOOTH CONST

export const READ_TYPE_BOOTHS = 'READ_TYPE_BOOTHS'
export const READ_TYPE_BOOTH = 'READ_TYPE_BOOTH'
export const SUCCESS_TYPE_BOOTH_CREATE = 'SUCCESS_TYPE_BOOTH_CREATE'
export const SUCCESS_TYPE_BOOTH_UPDATE = 'SUCCESS_TYPE_BOOTH_UPDATE'
export const SUCCESS_TYPE_BOOTH_DELETE = 'SUCCESS_TYPE_BOOTH_DELETE'
export const SEARCH_TYPE_BOOTHS = 'SEARCH_TYPE_BOOTHS'
export const ERROR_TYPE_BOOTH_READ = 'ERROR_TYPE_BOOTH_READ'

// TICKETS CONST

export const READ_TICKETS = 'READ_TICKETS'
export const READ_TICKET = 'READ_TICKET'
export const READ_TICKETS_ID_EVENT = 'READ_TICKETS_ID_EVENT'
export const SEARCH_TICKETS = 'SEARCH_TICKETS'
export const SUCCESS_TICKET_CREATE = 'SUCCESS_TICKET_CREATE'
export const SUCCESS_TICKET_UPDATE = 'SUCCESS_TICKET_UPDATE'
export const SUCCESS_TICKET_DELETE = 'SUCCESS_TICKET_DELETE'
export const ERROR_TICKET_READ = 'ERROR_TICKET_READ'

// ATTENDES CONST
export const READ_ATTENDESS = 'READ_ATTENDESS'
export const READ_ATTENDES = 'READ_ATTENDES'
export const READ_ATTENDESS_EVENT = "READ_ATTENDESS_EVENT"
export const SEARCH_ATTENDESS = 'SEARCH_ATTENDESS'
export const SUCCESS_ATTENDES_CREATE = 'SUCCESS_ATTENDES_CREATE'
export const SUCCESS_ATTENDES_UPDATE = 'SUCCESS_ATTENDES_UPDATE'
export const SUCCESS_ATTENDES_DELETE = 'SUCCESS_ATTENDES_DELETE'
export const ERROR_ATTENDES_READ = 'ERROR_ATTENDES_READ'

// SPEAKER CONST
export const READ_SPEAKERS = 'READ_SPEAKERS'
export const READ_SPEAKER = 'READ_SPEAKER'
export const READ_SPEAKERS_EVENT = "READ_SPEAKERS_EVENT"
export const SEARCH_SPEAKERS = 'SEARCH_SPEAKERS'
export const SUCCESS_SPEAKER_CREATE = 'SUCCESS_SPEAKER_CREATE'
export const SUCCESS_SPEAKER_UPDATE = 'SUCCESS_SPEAKER_UPDATE'
export const SUCCESS_SPEAKER_DELETE = 'SUCCESS_SPEAKER_DELETE'
export const ERROR_SPEAKER_READ = 'ERROR_SPEAKER_READ'
