import axios from 'axios';

import {
    READ_ATTENDESS,
    READ_ATTENDESS_EVENT,
    READ_ATTENDES,
    SUCCESS_ATTENDES_CREATE,
    SUCCESS_ATTENDES_UPDATE,
    SUCCESS_ATTENDES_DELETE,
    ERROR_ATTENDES_READ,
} from '../config/constants'
import { URL_BASE_API, ATTENDES_PATH } from '../config/api';

const headers = {
    headers: {
        skip: true
    }
}

const get_attendess = () => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${ATTENDES_PATH}`, headers)
            .then(res => res.data.payload[0])
            .then(attendess => attendess.sort((attendes, b) => attendes.id - b.id))
            .then(data => {
                console.log(data)
                dispatch({
                    type: READ_ATTENDESS,
                    attendess: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_attendes_read(error_read))
                reject(error_read)
            });
         });

    }
}

const get_attendes = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${ATTENDES_PATH}${ID}`, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: READ_ATTENDES,
                    attendes: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_attendes_read(error_read))
                reject(error_read)
            });
         });

    }

}

const get_attendes_for_event = (ID) => {

    return dispatch => {
        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}event/${ID}/${ATTENDES_PATH}/all`, headers)
            .then(res => res.data.payload[0])
            .then(attendess => attendess.sort((attendes, b) => attendes.id - b.id))
            .then(data => {
                dispatch({
                    type: READ_ATTENDESS_EVENT,
                    attendes_event: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_attendes_read(error_read))
                reject(error_read)
            });
         });

    }

}

const post_attendess = data => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .post(`${URL_BASE_API}${ATTENDES_PATH}`, data, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_ATTENDES_CREATE,
                    create: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_attendes_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const put_attendess = (ID, data) => {
    return dispatch => {
        return new Promise ( (resolve, reject) => {
            axios
            .put(`${URL_BASE_API}${ATTENDES_PATH}/${ID}`, data, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_ATTENDES_UPDATE,
                    update: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_attendes_read(error_read))
                reject(error_read)
            });
         });
    }
}

const delete_attendess = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .delete(`${URL_BASE_API}${ATTENDES_PATH}/${ID}`, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_ATTENDES_DELETE,
                    delete: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_attendes_read(error_read))
                reject(error_read)
            });
         });

    }
    
}


////////////Track Read/////////////

const error_attendes_read = (error_read) => {
  return {
    type: ERROR_ATTENDES_READ,
    error_read
  }
}

export { get_attendess, get_attendes, get_attendes_for_event, post_attendess, put_attendess, delete_attendess }