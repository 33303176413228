import React, { useState, useEffect } from 'react';
import style from "../styles/userCardRight.module.scss";
import { c } from "../helpers";

const UserCardRight = ({ forum }) => {
  let forums = forum.slice(0, 3)
  const [displace, setDisplace] = useState({ displace: "", icon: style.iconExpandMore, forumHandler: false });
  if (displace.forumHandler) {
    forums = forum.slice(0, 99)
  } else {
    forums = forum.slice(0, 3)
  }
  const handleForum = () => {
    let oldStateIcon = displace.icon;
    let isExpand = oldStateIcon === style.iconExpandMore;
    let newIcon = isExpand ? style.iconExpandLess : style.iconExpandMore;

    let oldStateDisplace = displace.displace;
    let isDisplace = oldStateDisplace === "";
    let newDisplace = isDisplace ? style.displace : "";

    setDisplace({ displace: newDisplace, icon: newIcon, forumHandler: !displace.forumHandler })
  }

  return (
    <React.Fragment>
      <div className={c(style.boxMessage, displace.displace)}>
        <div className={style.row}>
          <i className={style.iconGroup}></i>
          <h3>Forums</h3>
        </div>
        <div className={c(style.forum, displace.displace)}>
          {forums.map(({ id, author, description, date, comments }, i) =>
            <div className={style.boxForum} key={i + author + id}>
              <span className={style.title}>{description}</span>
              <span className={style.by}>Pasted by {author}</span>
              <div className={c(style.row, style.right)}>
                <span>{date} ago</span>
                <i className={style.iconForum}></i>
                <span>{comments}</span>
              </div>
            </div>
          )}
          <div className={c(style.footer, displace.displace)}>
            <i className={displace.icon}
              onClick={() => handleForum()}
            ></i>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserCardRight;