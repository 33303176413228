import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { actionValidSubmit } from "../actions/action_styles";
import style from "../styles/inputsValidations.module.scss";
import { c } from '../helpers';

const InputsValidations = ({ message1, message2, message3, message4, password, repeat, email }) => {
  const dispatch = useDispatch();

  const validateEmail = e => {
    let email = e;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    re.test(email)
    if (!re.test(email)) {
      setShowEmail(true)
      dispatch(actionValidSubmit(false))
    } else {
      setShowEmail(false)
      dispatch(actionValidSubmit(true))
    }
  }

  const validatePass = e => {
    let pass = e;
    let re = /[a-zA-Z0-9]{8,}/
    re.test(pass)
    let re2 = /[0-9]{1,}/
    re2.test(pass)
    let re3 = re.test(pass) & re2.test(pass);

    if (!re3) {
      setShowPass(true)
      setShowPass2(true)
      dispatch(actionValidSubmit(false))
    } else {
      setShowPass(false)
      setShowPass2(false)
      dispatch(actionValidSubmit(true))
    }

    if (re.test(pass)) {
      setCheckStyle(style.check)
    } else {
      setCheckStyle('')
    }

    if (re2.test(pass)) {
      setCheckStyle2(style.check)
    } else {
      setCheckStyle2('')
    }
  }

  const validateRepeat = (p, r) => {
    let password = p;
    let password2 = r;
    if (password !== password2) {
      setShowRepeat(true)
      dispatch(actionValidSubmit(false))
    } else {
      setShowRepeat(false)
      dispatch(actionValidSubmit(true))
    }
  }

  const [showPass, setShowPass] = useState(false)
  const [showPass2, setShowPass2] = useState(false)
  const [showEmail, setShowEmail] = useState(false)
  const [showRepeat, setShowRepeat] = useState(false)
  const [checkStyle, setCheckStyle] = useState('')
  const [checkStyle2, setCheckStyle2] = useState('')

  useEffect(() => {
    validateEmail(email);
    validatePass(password);
    validateRepeat(password, repeat);
  }, [email, password, repeat]);
  useEffect(() => {
    dispatch(actionValidSubmit(false))
  }, [])
  return (
    <React.Fragment>
      {showEmail && message1 &&
        <div className={style.errorMessage}>
          <i className={style.iconError} />
          <span>
            {message1}
          </span>
        </div>
      }
      {showRepeat && message2 &&
        <div className={style.errorMessage}>
          <i className={style.iconError} />
          <span>
            {message2}
          </span>
        </div>
      }
      {showPass && message3 &&
        <div className={style.errorMessage}>
          <i className={c(style.iconCheck, checkStyle)} />
          <span className={checkStyle}>
            {message3}
          </span>
        </div>
      }
      {showPass2 && message4 &&
        <div className={style.errorMessage}>
          <i className={c(style.iconCheck, checkStyle2)} />
          <span className={checkStyle2}>
            {message4}
          </span>
        </div>
      }

    </React.Fragment>
  )
}

export default InputsValidations;