import {
  STYLES,
  CHANGE_VIEW,
  SHOW_DELETE_POST,
  SHOW_CANCEL,
  SHOW_DETAILS,
  SHOW_DELETE_COMMENT,
  VALID_SUDMIT,
  ENABLE_PHOTO
} from '../config/constants'

const actionStyles = data => {
  return {
    type: STYLES,
    payload: data
  }
}

const actionChangeView = data => {
  return {
    type: CHANGE_VIEW,
    payload: data
  }
}

const actionShowDelete = data => {
  return {
    type: SHOW_DELETE_POST,
    payload: data
  }
}

const actionShowCancel = data => {
  return {
    type: SHOW_CANCEL,
    payload: data
  }
}
const actionShowDetails = data => {
  return {
    type: SHOW_DETAILS,
    payload: data
  }
}
const actionShowDeleteComment = data => {
  return {
    type: SHOW_DELETE_COMMENT,
    payload: data
  }
}

const actionValidSubmit = data => {
  return {
    type: VALID_SUDMIT,
    payload: data
  }
}

const actionEnablePhoto = data => {
  return {
    type: ENABLE_PHOTO,
    payload: data
  }
}

export {
  actionStyles,
  actionChangeView,
  actionShowDelete,
  actionShowCancel,
  actionShowDetails,
  actionShowDeleteComment,
  actionValidSubmit,
  actionEnablePhoto
}
