import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get_rooms, post_rooms, put_rooms, delete_rooms } from '../actions/action_rooms.js';
import style from '../styles/rooms.module.scss';
import CardTicket from './cardTicket';
import Button from './button';
import Search from './search';
import { c } from '../helpers';

class Rooms extends Component{

    constructor(props){
        super(props);
        this.state = {
            idEvent: this.props.match.path.match(/\d+/)[0],
            search: '',
        }
        // global.Rooms = this
    }

	componentDidMount() {
        this.getRoom();
    }
    getRoom(){
        return this.props.get_rooms()
    }
    empty(){
        return this.props.rooms.length === 0;
    }
    loadRooms(){
        let rooms = [];
        for (let i = 0; this.props.rooms.length > i; i++){
            const room = this.props.rooms[i];
            rooms.push(
                <CardTicket 
                    key={room.id}
                    id={room.id}
                    info={room}
                    title={room.name}
                    description={room.description}
                    isVailable={room.active}
                    selected={() => this.cardSelected(room)}
                    search={this.state.search}
                    filter={"all"}
                    fnAction={[
                        {fn: () => {
                            // openDialog({message: 'You will lose the information of the rooms', buttonText: 'DELETE'}, () => this.deleteRooms(room.id) )
                        },
                        name: "Delete"},
                    ]}
                    subDescription={[
                        {title: "Capacity", item: room.max_capacity, emphasis: true}
                    ]}
            />
            )
        }
        return rooms;
    }
    type() {
        let status = this.props.info.confirmed
        if (status) return 'filled'
        else return 'outlined'
    }
	updateSearch(value) {
		this.setState({search: value})
    }
	cardSelected(dataCard){
        // openSheetSide({
        //     overflow: false,
        //     title: {placerholder: 'Room name', edit: true, value: dataCard.name},
        //     fields: {
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert max capacity', value: dataCard.description},
        //         max_capacity: { tag: 'input', type: 'number', label: 'Capacity', placerholder: 'Insert max capacity', value: dataCard.max_capacity},
        //         active: { tag: 'input', type: 'checkbox', label: 'room active', value: dataCard.active}
        //         }
        // }, data => {
        //     data.name = data.title;
        //     LoaderModule.start();
        //     this.props.put_rooms(dataCard.id, data)
        //     .then(res => {
        //         this.getRoom();
        //         LoaderModule.end();
        //     });            
        // })
    }
	deleteRooms(idRoom){
        // LoaderModule.start();
        // this.props.delete_rooms(idRoom)
        // .then(res => {
        //     this.getRoom();
        //     LoaderModule.end();
        // });
    }
    newRoom(){
        // openSheetSide({
        //     title: {placerholder: 'Room name', edit: true, value: ''},
        //     fields: {
        //         description: { tag: 'textarea', label: 'Description', placerholder: 'Insert max capacity', value: ''},
        //         max_capacity: { tag: 'input', type: 'number', label: 'Capacity', placerholder: 'Insert max capacity', value: ''},
        //         active: { tag: 'input', type: 'checkbox', label: 'room active', value: false}
        //         }
        // }, (data) => {
        //     data.name = data.title;
        //     LoaderModule.start();
        //     this.props.post_rooms(data)
        //     .then(res => {
        //         this.getRoom();
        //         LoaderModule.end();
        //     });            
        // })
    }
    render() {  
        return (
            <main>
                { !this.empty() ? (
                    <section className={style.container}>
                        <header className={style.toolbar}>
                            <Search method={this.updateSearch.bind(this)}/>
                            <Button type={'filled'} onClick={e => this.newRoom()}>
                                { 'new' }
                            </Button>
                        </header>
                        <section>
                            {
                                this.loadRooms()
                            }
                        </section>
                    </section>
                   ) : (
                        <section className={style.infoEmpty}>
                            <p className={style.title}>There are still no rooms for this event</p>
                            <i className={style.icon}></i>
                            <button 
                                className={c(style.btn, this.state.newRoom && style.hidden)}
                                type="button"
                                onClick={e => this.newRoom()}
                            >
                                create
                            </button>
                        </section>
                   )
                }
            </main>
        );
    }
}
const mapDispatchToProps =  {
    get_rooms,
    post_rooms,
    put_rooms,
    delete_rooms
};
const mapStateToProps = state => {
	return {
		rooms: state.rooms.rooms
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Rooms);