import React from 'react';
import style from "../styles/rightAsideUserDashboard.module.scss";
import UserCardRight from "./userCardRight";

const RightAsideUserDashboard = ({forum}) => {
  return (
    <React.Fragment>
      <aside className={style.rightAside}>
        <UserCardRight forum={forum}/>
      </aside>
    </React.Fragment>
  )
}

export default RightAsideUserDashboard;