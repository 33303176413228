import React, { useState, useEffect } from 'react';
import style from "../styles/headerPost.module.scss";
import OptionsPost from './optionsPost';
import { c } from '../helpers';

const HeaderPost = ({ user, username, imgUser, name, showPost, index, post, postLeft, date }) => {
  const i = (parseInt(index))
  const [postState, setPostState] = useState('');
  const [leftPost, setPostLeft] = useState('');
  useEffect(() => {
    if (post) {
      setPostState(style.post)

    } else if (postLeft) {
      setPostLeft(style.post)
    }
    else {
      setPostState('')
      setPostLeft('')

    }
  })
  return (
    <React.Fragment>
      <div className={c(style.header, leftPost)}>
        <div className={c(style.headerLeft, postState)}>
          {!showPost && !user.profile_photo &&
            <i className={style.iconUser} />
          }
          {!showPost && user.profile_photo &&
            <img src={user.profile_photo} />
          }

          {!showPost &&
            <span className={style.name}>{user.name}</span>
          }
          {/* //////////// */}
          {showPost && imgUser=="" &&
            <i className={style.iconUser} />
          }
          {showPost && imgUser !== "" &&
            <img src={imgUser} />
          }
          {showPost &&
            <div className={style.column}>
              <span className={style.name}>{name}</span>
              <span className={style.date}>{date}</span>
            </div>
          }
          {showPost &&
            <span className={style.user}>@{username}</span>
          }
        </div>
        {showPost &&
          <OptionsPost post={true} />
        }
      </div>
    </React.Fragment >
  )
}

export default HeaderPost;