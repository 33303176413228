import {
  CREATE_COMMUNITY,
  READ_COMMUNITY,
  READ_COMMUNITIES,
  SUCCESS_CREATE_COMMUNITY,
  SUCCESS_READ_COMMUNITY,
  SUCCESS_READ_COMMUNITIES,
  ERROR_CREATE_COMMUNITY,
  ERROR_READ_COMMUNITY,
  ERROR_READ_COMMUNITIES
} from '../config/constants'

const initialState = {
  communities: [],
  community: [],
  create_community: '',
  error_create_community: 'error',
  error_read_community: 'error'
}

const reducerCommunities = (state = initialState, action) => {
  switch (action.type) {
    case READ_COMMUNITIES:
      return { ...state, communities: [...action.communities[0]] }
    case READ_COMMUNITY:
      return { ...state, community: action.community }
    case SUCCESS_CREATE_COMMUNITY:
      return { ...state, create: action.create_community }
    case ERROR_CREATE_COMMUNITY:
      return { ...state, error_create: action.error_create_community }
    case ERROR_READ_COMMUNITY:
      return { ...state, error_read: action.error_read_community }
    default:
      return state
  }
}
export { reducerCommunities }
