import {
    READ_SPONSORS,
    READ_SPONSOR,
    SEARCH_SPONSORS,
    SUCCESS_SPONSOR_CREATE,
    SUCCESS_SPONSOR_UPDATE,
    SUCCESS_SPONSOR_DELETE,
    ERROR_SPONSOR_READ
  } from '../config/constants'
  
  const initialState = {
    sponsors: [],
    error_read: 'error'
  }
  
  export const reducerSponsors = (state = initialState, action) => {
  
    switch (action.type) {
      case READ_SPONSORS:
        return { ...state, sponsors: action.sponsors }
  
      case READ_SPONSOR:
        return { ...state, sponsor: action.sponsor }
  
      case SEARCH_SPONSORS:
        return { ...state, search: action.search }
  
      case SUCCESS_SPONSOR_CREATE:
        return { ...state, create: action.create }

      case SUCCESS_SPONSOR_UPDATE:
        return { ...state, update: action.update }

      case SUCCESS_SPONSOR_DELETE:
        return { ...state, delete: action.delete }
  
      case ERROR_SPONSOR_READ:
        return { ...state, error_read: action.error_read }
  
      default:
        return state
    }
  
  }