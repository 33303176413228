import React from 'react';
import style from "../styles/leftAsideUserDashboard.module.scss";
import UserCardLeft from './userCardLeft';

const LeftAsideUserDashboard = ({ news }) => {
  
  return (
    <React.Fragment>
      <aside className={style.leftAside}>
        <div className={style.welcome}>
          <span>Welcome to</span>
          <h2>Community´s name</h2>
          <span className={style.news}>News</span>
        </div>
        {news.length !== 0 &&
          <UserCardLeft news={news} />
        }
        {news.length === 0 &&
          <div className={style.message}>
            <div className={style.icon} ></div>
            <span className={style.text}>There is no news in the community</span>
          </div>
        }
      </aside>
    </React.Fragment>
  )
}
export default LeftAsideUserDashboard;