import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from '../components/loginForm';
import style from '../styles/login.module.scss';
import Meta from '../components/metaContent';
import { REGISTER_ROUTE } from '../config/routes';

const Login = () => {
	return (
		<div className={style.loginScreen}>
			<Meta title="Login" />
			<div className={style.topBar}>
				<div className={style.logo}></div>
				<div className={style.side}>
					<div>
						Not a Member yet?
				</div>
					<div>
						<Link to={REGISTER_ROUTE}>Sign Up</Link>
					</div>
				</div>
			</div>
			<div className={style.login}>
				<div className={style.icon}>
				</div>
				<h1>
					Welcome back<br />
					<small>
						what is happening in your community
				</small>
				</h1>
				<LoginForm />

			</div>
		</div>
	)
};

export default Login;