import {
  READ_USER,
  ERROR_USER,
  UPDATE_USER,
  PHOTO_USER
} from '../config/constants'

const initialState = {
  user: [],
  error_read: null,
  update: [],
  photoUser:[]
}

const reducerUser = (state = initialState, action) => {
  switch (action.type) {
    case READ_USER:
      return {
        ...state,
        user: action.user
      }
    case ERROR_USER:
      return {
        ...state,
        error_read: action.error_read
      }
    case UPDATE_USER:
      return {
        ...state,
        update: action.update
      }
    case PHOTO_USER:
      return {
        ...state,
        photoUser: action.payload
      }
    default:
      return state
  }
}
export { reducerUser }
