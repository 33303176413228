import React, { useState } from 'react';
import style from '../styles/cardAttends.module.scss';
import Button from './button';
import CardListOptions from './cardListOptions';
import { c, urlFor } from '../helpers';


const CardAttends = props => {
    const [moreInfo, setMoreInfo] = useState(false);
    const [stateHideImg, setHideImg] = useState(false);

    const changeStatus = () => props.changeStatus(props.id);

    const type = () => props.info.is_present ? 'filled' : 'outlined';

    const labelStatus = () => props.info.is_present ? 'confirmed' : 'unconfirmed';

    const expander = () => moreInfo && style.expander;

    const changeExpander = e => {
        const button = e.nativeEvent.path.find(el => el.tagName === 'BUTTON');
        if (button) return;
        setMoreInfo(!moreInfo);
    }

    const filter = () => {
        let {filter} = props;
        let {is_present} = props.info;
        return (filter === 'all') || (filter === 'confirmed' && is_present) || (filter === 'unconfirmed' && !is_present);
    }

    const search = () => {
        let {search} = props;
        let {name, last_name, username} = props.info.user;
        let _fullname = `${name} ${last_name}`;
        return (_fullname.toLowerCase().search(search) !== -1 || username.toLowerCase().search(search) !== -1);
    }

    const hide = () => !(search() && filter()) && style.hidden;
    
    return (
        <article className={c(style.attend, expander(), hide())} onClick={changeExpander}>
            <header>
                <div className={style.info}>
                    <figure className={stateHideImg && style.hiddenImg}>
                        <div icon="account_circle_fill"></div>
                        <img alt="profile" src={urlFor(props.info.user.profile_photo)} onError={() => setHideImg(true)}></img>
                    </figure>
                    <h6>
                        {`${props.info.user.name} ${props.info.user.last_name}`}
                    </h6>
                </div>
                <p>
                    {props.info.ticket_sale_detail.ticket_sale.name_ticket}
                </p>
                <div className={style.actions}>
                    <Button type={type()} onClick={changeStatus}>
                        { labelStatus() }
                    </Button>
                </div>
                <CardListOptions options={props.fnAction} />
            </header>
            <div className={style.moreInfo}>
                <p>
                    <span>Username:</span>
                    <span>E-mail:</span>
                    <span>Address:</span>
                    <span>Phone:</span>
                </p>
                <p>
                    <span>{!!props.info.user.username ? props.info.user.username : "N/A"}</span>
                    <span>{!!props.info.user.email ? props.info.user.email : "N/A"}</span>
                    <span>{!!props.info.user.address ? props.info.user.address : "N/A"}</span>
                    <span>{!!props.info.user.phone ? props.info.user.phone : "N/A"}</span>
                </p>
            </div>
        </article>
    );
}


export default CardAttends;