import { READ_FORUM, ERROR_FORUM } from '../config/constants'

const initialState = {
  forum: [
    {
      id: 1,
      author: 'Julio',
      description: 'The ultimate assistant',
      date: '1 hrs',
      comments: '12'
    },
    {
      id: 2,
      author: 'Henry',
      description: 'The ultimate assistant',
      date: '2 hrs',
      comments: '13'
    },
    {
      id: 3,
      author: 'Marielys',
      description: 'The ultimate assistant',
      date: '3 hrs',
      comments: '23'
    },
    {
      id: 4,
      author: 'Carhen',
      description: 'The ultimate assistant',
      date: '3 hrs',
      comments: '23'
    },
    {
      id: 5,
      author: 'Adrian',
      description: 'The ultimate assistant',
      date: '3 hrs',
      comments: '23'
    }
  ],
  error_read: 'error'
}

const reducerForum = (state = initialState, action) => {
  switch (action.type) {
    case READ_FORUM:
      return { ...state, forum: [...action.forum[0]] }
    case ERROR_FORUM:
      return { ...state, error_read: action.error_read }
    default:
      return state
  }
}
export { reducerForum }
