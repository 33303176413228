import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { action_read_likes, action_add_like } from "../actions/action_post";
import style from "../styles/likeComment.module.scss";
import { c } from '../helpers';

const LikeComment = ({ count_messages, post, comment, index }) => {
  const dispatch = useDispatch();
  const count_likes = useSelector((state) => state.post.read_like);

  const [like, setLike] = useState('');
  const [postState, setPost] = useState('');
  const [commentState, setComment] = useState('');
  const [show, setShow] = useState(true);

  const handleLikes = () => {
    setLike(style.redLike)
    if (postState) {
      dispatch(action_add_like(index))
        .then(req => {
          dispatch(action_read_likes(index))
          setShow(false)
        })
        .catch(error => {
          console.log(`fail like post: ${error}`);
        });
    }
  }
  useEffect(() => {
    setPost(post)
    setComment(comment)
    dispatch(action_read_likes(index))
  }, [post, comment, dispatch, index])

  return (
    <React.Fragment>
      <div className={style.footer}>
        <div className={style.left}>
          <div className={style.iconBox}>
            {show &&
              <i
                className={c(style.iconFavorite, like)}
                onMouseDown={handleLikes}>
              </i>
            }
            {!show &&
              <i
                className={c(style.iconFavorite, like)}
                onDoubleClick={() => setLike('')}>
              </i>
            }
            <span>{count_likes}</span>
          </div>
          <div className={style.iconBox}>
            <i className={style.iconForum}></i>
            <span>{count_messages}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LikeComment;