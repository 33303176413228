import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import LeftProfile from "../components/leftProfile";
import RightProfile from "../components/rightProfile";
import FooterProfile from "../components/footerProfile";
import { action_read_user } from "../actions/action_user";
import { action_read_community } from "../actions/action_communities";
import style from "../styles/userProfile.module.scss";
import Meta from '../components/metaContent';

const UserProfile = () => {

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.user);
  const communityInfo = useSelector((state) => state.community.community);

  const [form, setForm] = useState({
    id: "",
    user: "",
    country: "",
    name: "",
    phone: "",
    codePhone: "",
    password: "",
    email: "",
    gender: "",
    lastname: "",
    adress: "",
    repeat: "",
    avatar: []
  })
  //useEffect
  useEffect(() => {
    dispatch(action_read_user())
    dispatch(action_read_community())
  },[]);

  return (
    <React.Fragment>
      <Meta title="My profile" />
      <div className={style.profile}>
        <div className={style.row}>
          <div className={style.leftProfile}>
            <LeftProfile userInfo={userInfo} communityInfo={communityInfo} form={form} setForm={setForm}/>
          </div>
          <div className={style.rightProfile}>
            <RightProfile userInfo={userInfo} form={form} setForm={setForm} />
          </div>
        </div>
        <div className={style.footerProfile}>
          <FooterProfile userInfo={userInfo} communityInfo={communityInfo} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserProfile;