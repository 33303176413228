import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownDate, DropdownComponent } from 'react-dropdown-date';
import style from '../../styles/register.module.scss';
import { c } from '../../helpers';
import { useForm } from './hooks';
import SelectInput from './selectInput';

export const GENDER_INPUT = 'gender';
export const NAME_INPUT = 'name';
export const LASTNAME_INPUT = 'last_name';
export const ADDRESS_INPUT = 'address';
export const PHONE_NUMBER_INPUT = 'phone';
export const BIRTHDAY_INPUT = 'birthdate';

const initialStateRightForm = {
    [GENDER_INPUT]: {
        value: '',
        valid: false
    },
    [NAME_INPUT]: {
        value: '',
        valid: false
    },
    [LASTNAME_INPUT]: {
        value: '',
        valid: false
    },
    [ADDRESS_INPUT]: {
        value: '',
        valid: false
    },
    [PHONE_NUMBER_INPUT]: {
        value: '',
        valid: false
    },
    [BIRTHDAY_INPUT]: {
        value: new Date(2012, 10, 15),
        valid: true,
    }
}
const validateName = (value) => {
    const regex = /[a-z]{2,}$/i;
    return regex.test(value);
}

const validateLastname = (value) => {
    let regex = /[a-z]{2,}$/;
    return regex.test(value);
}

const validateAddress = (value) => {
    return !!value && value.length > 0
}

const validatePhoneNumber = (value) => {
    let regex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
    return regex.test(value);
}

const genderList = [
    { name: 'Female', value: 'F' },
    { name: 'Male', value: 'M' }
]

const RightForm = ({ phoneCode, onSubmit }) => {

    const { inputs, isValid, handleInputChange, handleSubmit } = useForm(initialStateRightForm, () => onSubmit(inputs));
    const [activeInput, setActiveInput] = useState(null);

    const handleInput = (name, validation) => e => handleInputChange(name, e.target.value, validation(e.target.value));

    const handleBirthday = (date) => handleInputChange(BIRTHDAY_INPUT, date, true);


    return (
        <>
            <div className={style.inputBox}>
                <SelectInput name={GENDER_INPUT} placeholder="Gender" onChange={handleInputChange} options={genderList} />
                <div
                    className={style.inputGroup}>
                    <i className={c(style.iconPerson, activeInput === NAME_INPUT && style.iconBlue)} />
                    &nbsp;
                    <input
                        onBlur={() => setActiveInput(null)}
                        onFocus={() => setActiveInput(NAME_INPUT)}
                        className={style.inputBlue}
                        type="text"
                        placeholder="Name"
                        autoComplete="off"
                        maxLength={36}
                        value={inputs[NAME_INPUT].value}
                        onChange={handleInput(NAME_INPUT, validateName)} />
                    <div className={c(style.tooltip, style.right, activeInput === NAME_INPUT && !inputs[NAME_INPUT].valid && style.show)}>
                        <span className={style.tooltipContent}>
                            <span className={style.valid}>
                                <i className={style.iconError}></i>&nbsp;Invalid name
                        </span>
                        </span>
                    </div>
                </div>
                <div
                    className={style.inputGroup}>
                    <i className={c(style.iconPerson, activeInput === LASTNAME_INPUT && style.iconBlue)} />
                    &nbsp;
                    <input
                        onBlur={() => setActiveInput(null)}
                        onFocus={() => setActiveInput(LASTNAME_INPUT)}
                        className={style.inputBlue}
                        type="text"
                        placeholder="Last Name"
                        autoComplete="off"
                        maxLength={36}
                        value={inputs[LASTNAME_INPUT].value}
                        onChange={handleInput(LASTNAME_INPUT, validateLastname)} />
                    <div className={c(style.tooltip, style.right, activeInput === LASTNAME_INPUT && !inputs[LASTNAME_INPUT].valid && style.show)}>
                        <span className={style.tooltipContent}>
                            <span className={style.valid}>
                                <i className={style.iconError}></i>&nbsp;Invalid lastname
                            </span>
                        </span>
                    </div>
                </div>
                <div
                    onBlur={() => setActiveInput(null)}
                    onFocus={() => setActiveInput(BIRTHDAY_INPUT)}
                    className={style.inputGroup}>

                    <i className={c(style.iconEvent, activeInput === BIRTHDAY_INPUT && style.iconBlue)} />
                    &nbsp;

                    <DropdownDate
                        selectedDate={inputs[BIRTHDAY_INPUT].value}         // 'yyyy-mm-dd' format only
                        order={[
                            DropdownComponent.day,
                            DropdownComponent.month,
                            DropdownComponent.year,
                        ]}
                        onDateChange={handleBirthday}
                        ids={                             // optional
                            {
                                year: 'select-year',
                                month: 'select-month',
                                day: 'select-day'
                            }
                        }
                        names={                           // optional
                            {
                                year: 'year',
                                month: 'month',
                                day: 'day'
                            }
                        }
                        classes={                         // optional
                            {
                                dateContainer: style.dateContainer,
                                yearContainer: style.selectContainer,
                                monthContainer: style.selectContainer,
                                dayContainer: style.selectContainer,
                                year: style.select,
                                month: style.select,
                                day: style.select,
                                yearOptions: style.option,
                                monthOptions: style.option,
                                dayOptions: style.option
                            }
                        }
                        defaultValues={                   // optional
                            {
                                year: 'Select year',
                                month: 'Select month',
                                day: 'Select day'
                            }
                        }
                        options={                         // optional
                            {
                                yearReverse: false,            // false by default
                                monthShort: false,             // false by default
                                monthCaps: false               // false by default
                            }
                        }
                    />
                    <div className={c(style.tooltip, style.right, activeInput === BIRTHDAY_INPUT && !inputs[BIRTHDAY_INPUT].valid && style.show)}>
                        <span className={style.tooltipContent}>
                            <span className={c(style.valid, style.example)}>
                                <span className={style.blue}>
                                    Example:
                                </span>
                                <span className={style.valid}>
                                    15-November-2012
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
                <div
                    className={style.inputGroup}>
                    <i className={c(style.iconHome, activeInput === ADDRESS_INPUT && style.iconBlue)} />
                    &nbsp;
                    <input
                        onBlur={() => setActiveInput(null)}
                        onFocus={() => setActiveInput(ADDRESS_INPUT)}
                        className={style.inputBlue}
                        type="text"
                        placeholder="Address"
                        autoComplete="off"
                        value={inputs[ADDRESS_INPUT].value}
                        onChange={handleInput(ADDRESS_INPUT, validateAddress)} />
                    <div className={c(style.tooltip, style.right, activeInput === ADDRESS_INPUT && !inputs[ADDRESS_INPUT].valid && style.show)}>
                        <span className={style.tooltipContent}>
                            <span className={c(style.valid, style.example)}>
                                <span className={style.blue}>
                                    Example:
                                </span>
                                <span className={style.valid}>
                                    Blue street, house 09, thecity.
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
                <div
                    className={style.inputGroup}>
                    <i className={c(style.iconPhone, activeInput === PHONE_NUMBER_INPUT && style.iconBlue)} />
                    <input
                        disabled
                        value={phoneCode}
                        className={style.codePhone} />
                    <input
                        onBlur={() => setActiveInput(null)}
                        onFocus={() => setActiveInput(PHONE_NUMBER_INPUT)}
                        className={style.inputBlue}
                        type="number"
                        placeholder="1231234567"
                        autoComplete="off"
                        value={inputs[PHONE_NUMBER_INPUT].value}
                        onChange={handleInput(PHONE_NUMBER_INPUT, validatePhoneNumber)} />
                    <div className={c(style.tooltip, style.right, activeInput === PHONE_NUMBER_INPUT && !inputs[PHONE_NUMBER_INPUT].valid && style.show)}>
                        <span className={style.tooltipContent}>
                            <span className={style.valid}>
                                <i className={style.iconError}></i>&nbsp;Invalid phone number
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <button
                disabled={!isValid()}
                onClick={handleSubmit}
                className={style.btBlock}
                type="submit">
                SIGN UP
        </button>
        </>);
}

RightForm.propTypes = {
    phoneCode: PropTypes.string,
    onSubmit: PropTypes.func.isRequired
};

RightForm.defaultProps = {
    onSubmit: () => { }
};

export default RightForm;
