import React, { useState } from 'react';
import style from "../styles/slider.module.scss";
import { urlFor } from '../helpers';

const Slider = ({ indexView, img, children }) => {
  const [activeIndex, setIndex] = useState(indexView ? 0 : indexView);
  const [lengthView, setLength] = useState(img.length);

  const goToPrevSlide = () => {
    let index = activeIndex;
    let length = lengthView;

    if (index < 1) {
      index = length - 1;
    }
    else {
      index--;
    }
    setIndex(index);
  }

  const goToNextSlide = () => {
    let index = activeIndex;
    let length = lengthView;

    if (index === length - 1) {
      index = 0
    }
    else {
      index++;
    }
    setIndex(index);
  }

  return (
    <React.Fragment>
      <div className={style.slide}>
        <div className={style.iconBoxLeft}>
          <i className={style.iconLeft} onClick={() => goToPrevSlide()}></i>
        </div>
        {img.map((img, i) =>
          <img key={i} className={i === activeIndex ? style.active : style.img} src={urlFor(img)} alt="img" />
        )}
        <div className={style.iconBoxRight}>
          <i className={style.iconRight} onClick={() => goToNextSlide()}></i>
        </div>
      </div>
      {children}
    </React.Fragment>
  )
}

export default Slider;