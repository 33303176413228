import axios from 'axios'
import {
  READ_EVENTS,
  READ_EVENT,
  EVENT_FILTER,
  SEARCH_EVENTS,
  SUCCESS_CREATE,
  ERROR_EVENT,
  LOADING_EVENT
} from '../config/constants'
import { URL_BASE_API, EVENT_PATH } from '../config/api'

////////////Event Create/////////////

function create_event (create) {
  return {
    type: SUCCESS_CREATE,
    create
  }
}
function error_event (error) {
  return {
    type: ERROR_EVENT,
    error_event: error
  }
}
function fetch_create (data) {
  return axios.post(`${URL_BASE_API}${EVENT_PATH}`, data, {
    headers: {
      skip: true
    }
  })
}
const action_events_create = data => {
  return dispatch => {
    return fetch_create(data)
      .then(event => dispatch(create_event(true)))
      .catch(error => dispatch(error_event(error)))
  }
}

////////////Event Read/////////////

const action_events = () => {
  return dispatch => {
    axios
      .get(`${URL_BASE_API}${EVENT_PATH}`, {
        headers: {
          skip: true
        }
      })
      .then(response => {
        dispatch({
          type: READ_EVENTS,
          events: response.data.payload
        })
      })
      .catch(error => dispatch(error_event(error)))
  }
}

const action_event = id => {
  return dispatch => {
    axios
      .get(`${URL_BASE_API}${EVENT_PATH}/${id}`, {
        headers: {
          skip: true
        }
      })
      .then(response => {
        dispatch({
          type: READ_EVENT,
          event: response.data.payload
        })
      })
      .catch(error_read => dispatch(error_event(error_read)))
  }
}

////////////Event Search/////////////

const action_events_search = text => {


  // console.log("a buscar", search);

  return (dispatch, getState) => {

    if(text.length === 0) {
      dispatch({
        type: SEARCH_EVENTS,
        search: []
      })
    }

    const events = getState().events.events;

    const search = events.filter(e => e.name.toLowerCase().includes(text.toLowerCase()));
    dispatch({
      type: SEARCH_EVENTS,
      search
    })
  }
}

////////////Event Filter/////////////

const action_events_filter = filter => {

  return dispatch => {
    dispatch({
      type: EVENT_FILTER,
      filter
    })
  }
}

/////////Show loading on event screen//////////
const action_loading_event = loading => {
  return dispatch => {
    dispatch({
      type: LOADING_EVENT,
      loading
    })
  }
}

export {
  action_events,
  action_events_search,
  action_events_create,
  action_event,
  action_events_filter
}
