import {
    READ_EXHIBITORS,
    READ_EXHIBITOR,
    SEARCH_EXHIBITORS,
    SUCCESS_EXHIBITOR_CREATE,
    SUCCESS_EXHIBITOR_UPDATE,
    SUCCESS_EXHIBITOR_DELETE,
    ERROR_EXHIBITOR_READ
  } from '../config/constants'
  
  const initialState = {
    exhibitors: [],
    exhibitor: [],
    create: [],
    update: [],
    search: [],
    error_create: 'error',
    error_read: 'error'
  }
  
  export const reducerExhibitors = (state = initialState, action) => {
  
    switch (action.type) {
      case READ_EXHIBITORS:
        return { ...state, exhibitors: action.exhibitors }
  
      case READ_EXHIBITOR:
        return { ...state, exhibitor: action.exhibitor }
  
      case SEARCH_EXHIBITORS:
        return { ...state, search: action.search }
  
      case SUCCESS_EXHIBITOR_CREATE:
        return { ...state, create: action.create }

      case SUCCESS_EXHIBITOR_UPDATE:
        return { ...state, update: action.update }

      case SUCCESS_EXHIBITOR_DELETE:
        return { ...state, delete: action.delete }
  
      case ERROR_EXHIBITOR_READ:
        return { ...state, error_read: action.error_read }
  
      default:
        return state
    }
  
  }