import React, {} from 'react';
import {  Link,  useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actionShowDelete } from '../actions/action_styles';
import { action_events_filter } from '../actions/action_events';
import Card from './card';
import style from '../styles/eventsCardsCategory.module.scss';
import { EVENTS_ROUTE } from '../config/routes';
import { c, urlFor } from '../helpers'

const EventsCardsCategory = ({ data, labelCategory, showDelete }) => {
	const { pathname } = useLocation();
	let history = useHistory();
	let _router = labelCategory ? `/events_category/${labelCategory.toLowerCase()}` : "";
	const dispatch = useDispatch();

	/* get styles if this path is not the home of events
	 * or is the path for events and data length is less than 6 */
	let _hide = () => ((pathname !== EVENTS_ROUTE) || (pathname === EVENTS_ROUTE && data.length < 6)) && style.hide;

	console.log(c(style.see, _hide))

	const loaderCards = () =>{
		const _shortEvent = data.slice(0, 6);

        let events = [];
        for (let i = 0; _shortEvent.length > i; i++){
            const event = _shortEvent[i];
            events.push(
				<Card 
					key={event.id}
					id={event.id}
					showDelete={showDelete}
					values={event}
					event={true}
					showList={true}
					title={event.name}
					description={event.description}
					imgURL={urlFor(event.image)}
                    fnAction={[
						{fn: () => {
							history.push(`${EVENTS_ROUTE}/${event.id}/edit`)
							// openDialog({message: 'You will lose the information of this event', buttonText: 'DELETE'}, () => deleteEvents(event.id) )
						},
						name: "Edit"},
						{fn: () => {
							dispatch(actionShowDelete(!showDelete))
							// openDialog({message: 'You will lose the information of this event', buttonText: 'DELETE'}, () => deleteEvents(event.id) )
						},
						name: "Delete"},
					]}
            />
            )
        }
        return events;
	}

	return (
		<div className={style.eventContainer}>
			<div className={style.line}>
				<span>{labelCategory}</span>
			</div>
			<div className={style.contentCards}>
				{loaderCards()}
			</div>
			<div className={c(style.see, _hide())}>
				<Link to={_router} onClick={() => dispatch(action_events_filter(data))}>See all</Link>
				<i className={style.iconArrowRight} />
			</div>
		</div>
	);
};

export default EventsCardsCategory;
