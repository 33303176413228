import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { action_event } from '../actions/action_events.js';
import style from '../styles/leftBar.module.scss';
import List from './list';
import ButtonCopyRouter from './buttonCopyRouter';
import { getEventRoute, TICKETS_ROUTE, CALL_FOR_PAPERS_ROUTE, SPEAKERS_ROUTE, ATTENDEES_ROUTE, SPONSORS_ROUTE, EXHIBITORS_ROUTE, ROOMS_ROUTE, TRACKS_ROUTE } from '../config/routes.js';

const LeftBar = props => {
  const dispatch = useDispatch();
  const event = useSelector(state => state.events.event);
  
  useEffect(() => {
    dispatch(action_event(props.idEvent));
  }, [dispatch, props.idEvent]);

  const route = (path) => getEventRoute(path, props.idEvent);

  return (
    <nav className={style.leftBar}>
      <h6>{event.length === 0 ? "Event" : event.name}</h6>
      <section>
        <List icon="event" title="Dashboard" />
        <List icon="tickets" title="Tickets" to={route(TICKETS_ROUTE)}/>
        <List icon="website" title="Website"/>
        <List icon="cfp" title="Call of papers" to={route(CALL_FOR_PAPERS_ROUTE)}/>
        <hr/>
        <List icon="speakers" title="Speakers" to={route(SPEAKERS_ROUTE)}/>
        <List icon="attendes" title="Attendes" to={route(ATTENDEES_ROUTE)}/>
        <List icon="sponsors" title="Sponsors" to={route(SPONSORS_ROUTE)}/>
        <List icon="exhibitors" title="Exhibitors" to={route(EXHIBITORS_ROUTE)}/>
        <hr/>
        <List icon="rooms" title="Rooms" to={route(ROOMS_ROUTE)}/>
        <List icon="tracks" title="Tracks" to={route(TRACKS_ROUTE)}/>
        <List icon="sessions" title="Sessions" />
        <hr/>
        <List icon="coupon" title="Coupon"/>
        <List icon="survey" title="Survey"/>
        <List icon="agenda" title="Agenda"/>
      </section>
      < ButtonCopyRouter />
    </nav>
  )
}
export default LeftBar;