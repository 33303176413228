import {
  READ_ATTENDESS,
  READ_ATTENDESS_EVENT,
  READ_ATTENDES,
  SEARCH_ATTENDESS,
  SUCCESS_ATTENDES_CREATE,
  SUCCESS_ATTENDES_UPDATE,
  SUCCESS_ATTENDES_DELETE,
  ERROR_ATTENDES_READ
} from '../config/constants'

const initialState = {
  attendess: [],
  attendes_event: [],
  attendes: [],
  create: [],
  search: [],
  error_read: 'error'
}

export const reducerAttendess = (state = initialState, action) => {

  switch (action.type) {
    case READ_ATTENDESS:
      return { ...state, attendess: action.attendess }

    case READ_ATTENDESS_EVENT:
      return { ...state, attendes_event: action.attendes_event }  

    case READ_ATTENDES:
      return { ...state, attendes: action.attendes }

    case SEARCH_ATTENDESS:
      return { ...state, search: action.search }

    case SUCCESS_ATTENDES_CREATE:
      return { ...state, create: action.create }

    case SUCCESS_ATTENDES_UPDATE:
      return { ...state, update: action.update }

    case SUCCESS_ATTENDES_DELETE:
      return { ...state, delete: action.delete }

    case ERROR_ATTENDES_READ:
      return { ...state, error_read: action.error_read }

    default:
      return state
  }

}