import {
  READ_POSTS,
  ERROR_POST,
  READ_POST,
  CREATE_POST,
  DELETE_POST,
  ADD_LIKE,
  READ_LIKE
} from '../config/constants'

const initialState = {
  post: [],
  error_read: 'error',
  onePost: []
}
const reducerPost = (state = initialState, action) => {
  switch (action.type) {
    case READ_POSTS:
      return {
        ...state,
        post: action.post
      }
    case READ_POST:
      return {
        ...state,
        onePost: [...action.onePost[0]]
      }
    case ERROR_POST:
      return {
        ...state,
        error_read: action.error_read
      }
    case CREATE_POST:
      return {
        ...state,
        create: action.create
      }
    case DELETE_POST:
      return {
        ...state,
        erase: action.erase
      }
    case READ_LIKE:
      return {
        ...state,
        read_like: action.read_like
      }
    case ADD_LIKE:
      return {
        ...state,
        add_like: action.add_like
      }
    default:
      return state
  }
}
export { reducerPost }

/*
{

      id: 1,
      name: 'Erik Brown',
      desc: 'The ultimate assistant',
      username: 'erik223',
      date: '07-05-2020',
      video: '',
      files: [],
      likes: '23',
      commentsCount: '14',
      authorComment: 'Alphonse',
      tag: 'ADMIN',
      comments: 'Lorem Ipsum is simply dummy text',
      img: [
        'https://cdn.pixabay.com/photo/2015/07/02/10/07/audience-828584_960_720.jpg',
        'https://cdn.pixabay.com/photo/2016/08/16/09/53/international-conference-1597531_960_720.jpg',
        'https://cdn.pixabay.com/photo/2019/05/05/17/32/stadium-4181150_960_720.jpg',
        'https://cdn.pixabay.com/photo/2017/04/06/20/17/workshop-2209239_960_720.jpg'
      ],
      imgUser:
        'https://cdn.pixabay.com/photo/2017/04/06/19/34/girl-2209147_960_720.jpg',
      authorCommentImg:
        'https://cdn.pixabay.com/photo/2016/03/09/15/10/man-1246508_960_720.jpg'
    },
    {
      id: 2,
      name: 'Alphonse Elric',
      desc: 'Assistant design',
      username: 'alpha_beta',
      date: '08-05-2020',
      img: [
        'https://cdn.pixabay.com/photo/2017/11/24/10/43/admission-2974645_960_720.jpg'
      ],
      desc: 'landscape',
      video: '',
      files: [],
      likes: '13',
      commentsCount: '24',
      tag: 'ADMIN',
      authorComment: 'Jose',
      comments:
        "Lorem Ipsum has been the industry'swhen an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I",
      imgUser:
        'https://cdn.pixabay.com/photo/2016/11/21/14/53/adult-1845814_960_720.jpg',
      authorCommentImg:
        'https://www.sketchappsources.com/resources/source-image/profile-illustration-gunaldi-yunus.png'
    },
    {
      id: 3,
      name: 'Maximilians schwarzenegger',
      desc: 'Assistant author',
      username: 'terminator',
      date: '09-05-2020',
      video: 'http://clips.vorwaerts-gmbh.de/VfE_html5.mp4',
      img: [],
      files: [],
      likes: '32',
      commentsCount: '41',
      tag: 'ADMIN',
      authorComment: 'Luis',
      comments:
        "Lorem Ipsum is Ipsum has been the industry's, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I",
      imgUser:
        'https://cdn.pixabay.com/photo/2016/11/22/21/42/adult-1850703_960_720.jpg',
      authorCommentImg:
        'https://st2.depositphotos.com/1006318/5909/v/450/depositphotos_59095205-stock-illustration-businessman-profile-icon.jpg'
    },
    {
      id: 4,
      name: 'Maxi Lopez',
      desc:
        'Assistant author kommu  Ipsum has been the industrys, when an unknown printer galley of type and scrambled ',
      username: 'Lopex',
      date: '09-05-2020',
      video: '',
      img: [],
      files: [
        {
          title: 'Hexadecimales.pdf',
          link:'https://drive.google.com/file/d/0B-GnqQk8uubSY2x5MXVCTTR5d2VCdHZzNFpKd1g4VzVwSDlN/view'
        },
        {
          title: 'Conversiones.pdf',
          link:'https://drive.google.com/file/d/0B-GnqQk8uubSTnZhaUItY0RnWUJMTlVXUjMwSGExSkppNWtn/view?usp=sharing'
        }
      ],
      likes: '32',
      commentsCount: '41',
      tag: 'ADMIN',
      authorComment: 'Luis',
      comments:
        "Lorem Ipsum is Ipsum has been the industry's, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I",
      imgUser:
        'https://cdn.pixabay.com/photo/2016/11/22/21/42/adult-1850703_960_720.jpg',
      authorCommentImg:
        'https://st2.depositphotos.com/1006318/5909/v/450/depositphotos_59095205-stock-illustration-businessman-profile-icon.jpg'
    }
*/
