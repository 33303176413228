import {
  READ_EVENTS,
  READ_EVENT,
  EVENT_FILTER,
  SEARCH_EVENTS,
  SUCCESS_CREATE,
  ERROR_EVENT,
  LOADING_EVENT
} from '../config/constants'

const initialState = {
  search: [],
  events: [],
  event: [],
  filter: [],
  create: '',
  error_event: 'error',
  loading: false
}

const reducerEvents = (state = initialState, action) => {
  switch (action.type) {
    case READ_EVENTS:
      return { ...state, events: [...action.events] }
    case READ_EVENT:
      return { ...state, event: action.event }
    case EVENT_FILTER:
      return {...state, filter: action.filter}
    case SEARCH_EVENTS:
      return { ...state, search: action.search }
    case SUCCESS_CREATE:
      return { ...state, create: action.create }
    case ERROR_EVENT:
      return { ...state, error_event: action.error_event }
    case LOADING_EVENT:
      return {...state, loading: action.loading }
    default:
      return state
  }
}
export { reducerEvents }
