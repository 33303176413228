import {
  READ_COMMENT,
  ERROR_COMMENT,
  CREATE_COMMENT,
  DELETE_COMMENT,
} from '../config/constants'

const initialState = {
  create: [],
  read_comments: [],
  error: 'error'
}

const reducerComments = (state = initialState, action) => {
  switch (action.type) {
    case READ_COMMENT:
      return { ...state, 
        read_comments: [...action.read_comments[0]] 
      }
    case ERROR_COMMENT:
      return { ...state, 
        error_read: action.error_read
       }
    case CREATE_COMMENT:
      return { ...state,
         create: action.create
        }
    case DELETE_COMMENT:
      return { ...state, 
        erase: action.erase
       }
    default:
      return state
  }
}
export { reducerComments }
