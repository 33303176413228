import axios from 'axios';

import {
    READ_TYPE_SPONSORS,
    READ_TYPE_SPONSOR,
    SUCCESS_TYPE_SPONSOR_CREATE,
    SUCCESS_TYPE_SPONSOR_UPDATE,
    SUCCESS_TYPE_SPONSOR_DELETE,
    ERROR_TYPE_SPONSOR_READ
} from '../config/constants'
import { URL_BASE_API, TYPE_SPONSOR_PATH } from '../config/api';

const headers = {
    headers: {
        skip: true
    }
}

// CRUD TYPE_SPONSOR

const get_type_sponsors = () => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${TYPE_SPONSOR_PATH}`, headers)
            .then(res => res.data.payload[0])
            .then(type_sponsors => type_sponsors.sort((type_sponsor, b) => type_sponsor.id - b.id))
            .then(data => {
                dispatch({
                    type: READ_TYPE_SPONSORS,
                    type_sponsors: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_type_sponsor_read(error_read))
                reject(error_read)
            });
         });

    }

}

const get_type_sponsor = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${TYPE_SPONSOR_PATH}${ID}`, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: READ_TYPE_SPONSOR,
                    type_sponsor: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_type_sponsor_read(error_read))
                reject(error_read)
            });
         });

    }

}

const post_type_sponsors = data => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .post(`${URL_BASE_API}${TYPE_SPONSOR_PATH}`, data, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: SUCCESS_TYPE_SPONSOR_CREATE,
                    create: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_type_sponsor_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const put_type_sponsors = (ID, data) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .put(`${URL_BASE_API}${TYPE_SPONSOR_PATH}/${ID}`, data, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: SUCCESS_TYPE_SPONSOR_UPDATE,
                    update: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_type_sponsor_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const delete_type_sponsors = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .delete(`${URL_BASE_API}${TYPE_SPONSOR_PATH}/${ID}`, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_TYPE_SPONSOR_DELETE,
                    delete: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_type_sponsor_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

////////////Sponsor Read/////////////

const error_type_sponsor_read = (error_read) => {
  return {
    type: ERROR_TYPE_SPONSOR_READ,
    error_read
  }
}

export { get_type_sponsors, get_type_sponsor, post_type_sponsors, put_type_sponsors, delete_type_sponsors }