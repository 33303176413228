import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

const MetaContent = (props) => {
    return (
        <Helmet>
            <title>Kommu | {props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
    )
};

MetaContent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};

MetaContent.defaultProps = {
    title: '',
    description: ''
};

export default MetaContent;