import React from 'react';
import { useSelector } from "react-redux";
import { Route, Switch } from 'react-router-dom';
import IndexRedirect from './components/indexRedirect';
import RegisterRouter from './routers/register';
import Login from './screens/login';
import Home from './screens/home';
import PrivateRoute from './components/privateRoute';
import './styles/app.scss';
import { LOGIN_ROUTE, REGISTER_ROUTE } from './config/routes';

const App = () => {
	const auth = useSelector((state) => state.signin.auth);

	return (
		<div className="router">
			<Switch>
				<Route path="/" exact render={() => <IndexRedirect auth={auth} />} />
				<Route path={LOGIN_ROUTE} component={Login} />
				<Route path={REGISTER_ROUTE} component={RegisterRouter} />
				<PrivateRoute path="/" component={Home} />
			</Switch>
		</div>
	);
};

export default App;
