import axios from 'axios';

import {
    READ_PARTNERSHIP_POSITION_SPONSORS,
    READ_PARTNERSHIP_POSITION_SPONSOR,
    SUCCESS_PARTNERSHIP_POSITION_SPONSOR_CREATE,
    SUCCESS_PARTNERSHIP_POSITION_SPONSOR_UPDATE,
    SUCCESS_PARTNERSHIP_POSITION_SPONSOR_DELETE,
    ERROR_PARTNERSHIP_POSITION_SPONSOR_READ,
} from '../config/constants'
import { URL_BASE_API, PARTNERSHIP_POSITION_PATH } from '../config/api';

const headers = {
    headers: {
        skip: true
    }
}

// CRUD PARTNERSHIP_POSITION

const get_partnership_positions = () => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${PARTNERSHIP_POSITION_PATH}`, headers)
            .then(res => res.data.payload[0])
            .then(partnership_positions => partnership_positions.sort((partnership_position, b) => partnership_position.id - b.id))
            .then(data => {
                dispatch({
                    type: READ_PARTNERSHIP_POSITION_SPONSORS,
                    partnership_positions: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_partnership_position_read(error_read))
                reject(error_read)
            });
         });

    }

}

const get_partnership_position = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${PARTNERSHIP_POSITION_PATH}${ID}`, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: READ_PARTNERSHIP_POSITION_SPONSOR,
                    partnership_position: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_partnership_position_read(error_read))
                reject(error_read)
            });
         });

    }

}

const post_partnership_positions = data => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .post(`${URL_BASE_API}${PARTNERSHIP_POSITION_PATH}`, data, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: SUCCESS_PARTNERSHIP_POSITION_SPONSOR_CREATE,
                    create: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_partnership_position_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const put_partnership_positions = (ID, data) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .put(`${URL_BASE_API}${PARTNERSHIP_POSITION_PATH}/${ID}`, data, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: SUCCESS_PARTNERSHIP_POSITION_SPONSOR_UPDATE,
                    update: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_partnership_position_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const delete_partnership_positions = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .delete(`${URL_BASE_API}${PARTNERSHIP_POSITION_PATH}/${ID}`, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_PARTNERSHIP_POSITION_SPONSOR_DELETE,
                    delete: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_partnership_position_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

////////////PARTNERSHIP_POSITION Read/////////////

const error_partnership_position_read = (error_read) => {
  return {
    type: ERROR_PARTNERSHIP_POSITION_SPONSOR_READ,
    error_read
  }
}

export { get_partnership_positions, get_partnership_position, post_partnership_positions, put_partnership_positions, delete_partnership_positions }