import {
    READ_SPEAKERS,
    READ_SPEAKERS_EVENT,
    READ_SPEAKER,
    SEARCH_SPEAKERS,
    SUCCESS_SPEAKER_CREATE,
    SUCCESS_SPEAKER_UPDATE,
    SUCCESS_SPEAKER_DELETE,
    ERROR_SPEAKER_READ
  } from '../config/constants'
  
  const initialState = {
    speakers: [],
    speaker_event: [],
    speaker: [],
    create: [],
    search: [],
    error_read: 'error'
  }
  
  export const reducerSpeakers = (state = initialState, action) => {
  
    switch (action.type) {
      case READ_SPEAKERS:
        return { ...state, speakers: action.speakers }
  
      case READ_SPEAKERS_EVENT:
        return { ...state, speaker_event: action.speaker_event }  
  
      case READ_SPEAKER:
        return { ...state, speaker: action.speaker }
  
      case SEARCH_SPEAKERS:
        return { ...state, search: action.search }
  
      case SUCCESS_SPEAKER_CREATE:
        return { ...state, create: action.create }
  
      case SUCCESS_SPEAKER_UPDATE:
        return { ...state, update: action.update }
  
      case SUCCESS_SPEAKER_DELETE:
        return { ...state, delete: action.delete }
  
      case ERROR_SPEAKER_READ:
        return { ...state, error_read: action.error_read }
  
      default:
        return state
    }
  }