import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { action_create_post, action_read_post } from "../actions/action_post";
import { actionShowCancel } from "../actions/action_styles";
import { action_read_user } from "../actions/action_user";
import style from "../styles/userCreatePost.module.scss";
import Upload from './upload';
import Modal from "./modal";
import HeaderPost from './headerPost';

const UserCreatePost = () => {

    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.user.user);
    const textArea = useRef(null);
    const showCancel = useSelector((state) => state.styles);
    const [data, setData] = useState([]);
    const [showUpload, setShowUpload] = useState(true);

    const [post, setPost] = useState({
        content: '',
        media: [],
    });
    const [type, setType] = useState({
        file: false,
        img: false,
        video: false
    });

    const setTypeImg = () => {
        setType({
            file: false,
            img: true,
            video: false
        })
    };
    const setTypeVideo = () => {
        setType({
            file: false,
            img: false,
            video: true
        })
    };

    const setTypeFile = () => {
        setType({
            file: true,
            img: false,
            video: false
        })
    };

    const { image, video, file } = post;


    const setDataPost = e => {
        e.preventDefault();
        dispatch(actionShowCancel(!showCancel.cancel))
    };
    const cleanInput = () => {
        textArea.current.value = "";
    };
    const autoSize = () => {
        setTimeout(() => {
            textArea.current.style.cssText = 'height:auto; padding:0';
            textArea.current.style.cssText = 'height:' + textArea.current.scrollHeight + 'px';
        }, 0);
    };
    useEffect(() => {
        if (!showUpload) {
            setTimeout(() => {
                setShowUpload(true)
            }, 3000);
        }
    })
    useEffect(() => {
        dispatch(action_read_user())
    }, []);
    return (
        <React.Fragment>
            <form onSubmit={setDataPost} className={style.boxCenter}>
                <HeaderPost index={0} user={userInfo} showPost={false} />
                <div className={style.content}>
                    <textarea
                        onKeyDown={autoSize}
                        rows='1'
                        ref={textArea}
                        onChange={e => setPost({ ...post, content: e.target.value })}
                        name="content"
                        placeholder="Share something"
                    />
                    {showUpload &&
                        <Upload data={data} file={type.file} video={type.video} img={type.img} />
                    }
                </div>
                <div className={style.footer}>
                    <div className={style.left}>
                        <label title="Picture" htmlFor="image" className={style.iconImage} >
                            <input id="image"
                                type="file"
                                title="Picture"
                                accept="image/*"
                                multiple
                                name="image"
                                defaultValue={image}
                                onChange={
                                    e => setPost({
                                        ...post,
                                        media: e.target.files
                                    }
                                    )}
                                onFocus={e => setData(e.target.files)}
                                onClick={() => setTypeImg()}
                            />
                        </label>
                        <label title="Video" htmlFor="video" className={style.iconVideo}>
                            <input id="video"
                                type="file"
                                title="Video"
                                accept="video/*"
                                defaultValue={video}
                                onChange={
                                    e => setPost({
                                        ...post,
                                        media: e.target.files
                                    }
                                    )}
                                onFocus={e => setData(e.target.files)}
                                onClick={() => setTypeVideo()}
                            />
                        </label>
                        <label title="Document" htmlFor="file" className={style.iconFile}>
                            <input id="file"
                                type="file"
                                title="Document"
                                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                                multiple
                                defaultValue={file}
                                name="file"
                                onChange={
                                    e => setPost({
                                        ...post,
                                        media: e.target.files
                                    }
                                    )}
                                onFocus={e => setData(e.target.files)}
                                onClick={() => setTypeFile()}
                            />
                        </label>
                    </div>
                    <div className={style.right}>
                        <button type="submit">POST</button>
                    </div>
                </div>
            </form>
            {showCancel.cancel &&
                <ModalPublic setShowUpload={setShowUpload} cleanInput={() => cleanInput()} post={post} setPost={setPost} />
            }
        </React.Fragment>
    )
}
const ModalPublic = ({ post, setPost, cleanInput, setShowUpload }) => {
    const dispatch = useDispatch();
    const showCancel = useSelector((state) => state.styles);
    const handleSubmit = () => {
        dispatch(action_create_post(post))
            .then(req => {
                setPost(
                    {
                        content: '',
                        image: null,
                        video: null,
                        file: null,
                    });
                dispatch(actionShowCancel(!showCancel.cancel))
                cleanInput()
                setShowUpload(false)
                dispatch(action_read_post())
            })
            .catch(error => {
                console.log(`fail create post: ${error}`);
            });
    };
    return (
        <Modal>
            <div className={style.modalPublic}>
                <h2>Are you sure?</h2>
                <span>
                    Some things haven´t been loaded yet
                </span>
                <button onClick={handleSubmit}>PUBLISH</button>
            </div>
        </Modal>
    )
}

export default UserCreatePost;