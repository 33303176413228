import {
  STYLES,
  CHANGE_VIEW,
  SHOW_DELETE_POST,
  SHOW_CANCEL,
  SHOW_DETAILS,
  SHOW_DELETE_COMMENT,
  VALID_SUDMIT,
  ENABLE_PHOTO
} from '../config/constants'

const initialState = {
  styles: [],
  view: false,
  delete: false,
  cancel: false,
  details: false,
  comment:false,
  validSudmit:false,
  enablePhoto:false
}

const reducerStyles = (state = initialState, action) => {
  switch (action.type) {
    case STYLES: {
      return {
        ...state,
        styles: action.payload || []
      }
    }
    case CHANGE_VIEW: {
      return {
        ...state,
        view: action.payload
      }
    }
    case SHOW_DELETE_POST: {
      return {
        ...state,
        delete: action.payload
      }
    }
    case SHOW_CANCEL: {
      return {
        ...state,
        cancel: action.payload
      }
    }

    case SHOW_DETAILS: {
      return {
        ...state,
        details: action.payload
      }
    }
    case SHOW_DELETE_COMMENT: {
      return {
        ...state,
        comment: action.payload
      }
    }
    case VALID_SUDMIT: {
      return {
        ...state,
        validSudmit: action.payload
      }
    }

    case ENABLE_PHOTO: {
      return {
        ...state,
        enablePhoto: action.payload
      }
    }
    default:
      return state
  }
}

export { reducerStyles }
