import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import style from '../styles/postRegister.module.scss';
import { actionForm } from '../actions/action_form';
import { c } from '../helpers';
import { LOGIN_ROUTE, REGISTER_ROUTE } from '../config/routes';
import action_signup from '../actions/action_signup';

const PostRegister = (props) => {
    const [hasRegistered, setHasRegistered] = useState(false);
    const [createSelected, setCreateSelected] = useState(true);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const form = useSelector(state => state.form.form);

    let communityNameRef = useRef(null);
    let communityCodeRef = useRef(null);

    if(!form.hasOwnProperty('email')) {
        history.push(LOGIN_ROUTE);
        return null;
    }

    const spanMessage = () => createSelected ? "Please enter the name of the community" : "Please enter the community code";

    const sendRegister = async () => {
        setLoading(true);
        let data = { ...form }
        if(createSelected) {
            data.nameCommunity = communityNameRef.current.value;
        } else {
            data.codeCommunity = communityCodeRef.current.value;
        }
        console.log(data);
        try {
            const {signup} = await dispatch(action_signup(data));
            if(signup) {
                setHasRegistered(true);
                dispatch(actionForm({}));
            }
        } catch (err) {
            /* TODO: show error */
            setHasRegistered(false);
            communityCodeRef.reset();
            communityNameRef.reset();
        } finally {
            setLoading(false);
        }
    }

    if(hasRegistered) {
        return (
            <div className={style.postRegister}>
                <div className={style.message}>
                    <div className={style.message}>
                        <div className={style.text}>To access you must verify your email</div>
                        <div className={style.icon} ></div>
                        <div className={style.inputGroup}>
                            <Link to={LOGIN_ROUTE} className={style.btn}>
                                Go to login
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={style.postRegister}>
            <div className={style.backto}>
                <Link to={REGISTER_ROUTE}>
                    <i className={style.back}></i>
                </Link>
                <span>Back to register</span>
            </div>
            <div className={style.title}>
                <h1>You are almost part of Kommu</h1>
                <h4>To continue please indicate what you wish to do</h4>
            </div>
            <div className={style.contentPost}>
                <div className={style.boxtobox}>
                    <button
                        className={c(style.create, createSelected && style.blue)}
                        onClick={() => setCreateSelected(true)}>
                        <div className={style.iconPerson}></div>
                        Create community
                    </button>
                    <button
                        className={c(style.join, !createSelected && style.blue)}
                        onClick={() => setCreateSelected(false)}>
                        <div className={style.iconPersonGroup}></div>
                        Join community
                    </button>
                </div>
                <div className={style.inputGroup}>
                    <span>{spanMessage()}</span>
                    { createSelected ?
                        (<input type="text" ref={communityNameRef} placeholder="Community name" />) :
                        (<input type="text" ref={communityCodeRef} placeholder="Insert code" />) }
                </div>
                <div className={style.inputGroup}>
                    <button disabled={loading} onClick={sendRegister} className={style.button}>NEXT</button>
                </div>
            </div>
        </div>
    )

}

export default PostRegister;