import React from 'react';
import style from '../styles/button.module.scss';
import { c } from '../helpers';

const Button = props => {
  const type = () => style[props.type] || style.filled;

  return (
    <button className={c(style.btn, (props.classStyle && props.classStyle), type())} onClick={props.onClick}>
      <span>
        {
          props.children
        }
      </span>
    </button>
  );
}

export default Button;