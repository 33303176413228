import React, { useRef } from 'react';
import style from '../styles/buttonCopyRouter.module.scss';

const ButtonCopyRouter = props => {
    let urlRouterRef = useRef(null);

    const copyFunction = e => {
        console.log(urlRouterRef);
        urlRouterRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    }
    
    return (
        <div className={style.wrapperButton}>
            <div className={style.circle} title="COPIED" onClick={copyFunction}>
                <i className={style.iconFileCopy} />
            </div>
            <input
                contentEditable="false"
                ref={urlRouterRef}
                onClick={copyFunction}
                type="text"
                defaultValue="event.kommu.io"
                title="COPIED"
            />
        </div>
    );
}

export default ButtonCopyRouter;