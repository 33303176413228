import React, { useState } from 'react';
import style from '../styles/cardExhibitors.module.scss';
import CardListOptions from './cardListOptions';
import BtnUrl from './btnUrl';
import { loadStatus, c } from '../helpers';

const CardExhibitors = props => {
    const [status, setStatus] = useState(loadStatus(props.info.length));
    const [selected, setSelected] = useState(null);
    const [id, setId] = useState(null);
    const setTarget = useState(null);

    const search = () => {
        let {search} = props;
        let {name} = props.info;
        return (name.toLowerCase().search(search) !== -1);
    }

    const filter = () => {
        let {filter} = props;
        let {id} = props.info.type_booth;
        return (filter == id);
    }

    const hide = () => !(search() && filter()) && style.hidden;

    const deselectedCard = () => {
        setStatus(status.map(actived => {
            if(actived) actived = false;
            return actived;
        }));
    }

    const selectedCard = (e, id) => {
        setSelected(!selected);
        setId(id);

        window.onclick = e => {
            let index = e.path.findIndex(el => el == this.state.target);
            if(!(index > -1)) {
                setSelected(false);
                setId(null);
                window.onclick = null;
            }
        }
        setSelected(props.info);
    }

    return (
        <article
            className={c(style.cardTicket, selected && style.selected, hide())}
            onClick={e => selectedCard(e, props.id)}
        >
            <header>
                <h3>{props.title}</h3>
                <CardListOptions options={props.fnAction} />
            </header>
            <section>
                <BtnUrl url={props.info.url} />
                <img src={props.info.logo} alt={props.title} />
            </section>
        </article>
    );
}

export default CardExhibitors;