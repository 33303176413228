import React from 'react';
import style from '../styles/loading.module.scss';
import { c } from '../helpers';

export const LoadingCircle = (props) =>

    <div 
        className={c(
            style.loaderWrapper,
            style.background,
            props.show && style.show,
            props.fixed && style.fixed,
            props.transparent && style.transparent
        )}
    >
        <div className={c(style.loader, style.circle)} />
    </div>


export const LoadingProgressBar = (props) => {

    let _progress = {width: `${props.porcents}%`};
    let _text = props.porcents > 100 ? "READY" : "Loading";

    if(props.error){
        _text = "ERROR";
        _progress.width = "0%";
    }

    return(
        <div 
            className={c(
                style.LoaderContentBar,
                props.porcents >= 100 && style.done,
                props.error && style.error
            )}
        >
            <div
                className={style.btnResetContent}
                onClick={e => props.fnReturnUpload()}
            >
                <div className={style.iconReset}></div>
            </div>
            <svg>
                <rect x="0%" y="0%" style={_progress}></rect>
            </svg>
            <span className={style.textLoading}>{_text}</span>
        </div>
    )

}

export const LoadingProgressCircle = (props) => {
   
    let _progress = {strokeDasharray: Math.round((props.porcents)*2) +" "+ Math.round(200 - (props.porcents)*2)};
    
    if(props.error){
        _progress.strokeDasharray = "0 200";
    }

    return(
        <div 
            className={c(
                style.LoaderContentCircle,
                props.porcents >= 100 && style.done,
                props.error && style.error,
                props.show && style.show
            )}>
            <div className={c(style.wrapperCircle, props.backgroundActive && style.backgroundCircle)}>
                <svg width="100" height="100" viewBox="0 0 50 100">
                    <circle cx="25" cy="50" r="30" rx="50" ry="50"></circle>
                    <circle cx="25" cy="50" r="30" rx="50" ry="50" style={_progress}></circle>
                </svg>
                <div
                    className={style.btnResetContent}
                    onClick={e => props.fnReturnUpload()}
                >
                    <span className={style.textError}>ERROR</span>
                    <div className={style.iconReset} />
                </div>
            </div>
        </div>
    )

}