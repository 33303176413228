import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from '../../styles/register.module.scss';
import { c } from '../../helpers';


const DatalistInput = ({ name, options, onChange }) => {
    const [active, setActive] = useState(false);
    const [text, setText] = useState('');

    const iconExpand = () => active ? style.iconExpandL : style.iconExpandM;
    const iconExpandColor = () => active && style.iconBlueDark;
    const iconActive = () => active && style.iconBlue;

    const handleSelect = item => {
        onChange(name, item.value, true, item);
        setText(item.name);
        setActive(false);
    }

    const handleInput = e => setText(e.target.value);

    const filteredOptions = () => {
        if(options.length === 0) return [];

        if(text.length === 0) return options;

        return options.filter(option => option.name.toLowerCase().includes(text.toLowerCase()));
    }

    const onBlur = () => setTimeout(() => setActive(false), 200);

    return (
        <div
            onFocus={() => setActive(true)}
            onBlur={onBlur}>
            <div
                className={style.inputGroup}>
                <i className={c(style.iconHdr, iconActive())} />
                &nbsp;
                <select name={name} className={style.hide}>
                    {options.map((opt, i) => <option key={i} value={opt.value}>{opt.name}</option>)}
                </select>
                <input
                    type="text"
                    className={style.inputBlue}
                    placeholder="Country"
                    value={text}
                    onChange={handleInput} />
                <i
                    className={c(iconExpand(), iconExpandColor())} />
            </div>
            <ul onBlur={onBlur} className={c(style.gender, style.inputSelect, !active && style.hideSelect)}>
                {filteredOptions().map((opt, i) => (
                        <li key={i}
                            className={style.list}
                            onClick={() => handleSelect(opt)}>
                                {opt.name}
                        </li>
                ))}
            </ul>
        </div>
    );
}

DatalistInput.propTypes = {
    name: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func
}

DatalistInput.defaultProps = {
    options: [],
    onChange: () => {}
}
export default DatalistInput;
