import axios from 'axios';

import {
    READ_ROOMS,
    SUCCESS_ROOM_CREATE,
    SUCCESS_ROOM_UPDATE,
    SUCCESS_ROOM_DELETE,
    ERROR_ROOM_READ,
} from '../config/constants'
import { URL_BASE_API, ROOM_PATH } from '../config/api';

const headers = {
    headers: {
        skip: true
    }
}

const get_rooms = (callback) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${ROOM_PATH}`, headers)
            .then(res => res.data.payload[0])
            .then(rooms => rooms.sort((room, b) => room.id - b.id))
            .then(data => {
                dispatch({
                    type: READ_ROOMS,
                    rooms: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_room_read(error_read))
                reject(error_read)
            });
         });

    }
}

const post_rooms = data => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .post(`${URL_BASE_API}${ROOM_PATH}`, data, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_ROOM_CREATE,
                    create: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_room_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const put_rooms = (ID, data) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .put(`${URL_BASE_API}${ROOM_PATH}/${ID}`, data, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_ROOM_UPDATE,
                    update: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_room_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const delete_rooms = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .delete(`${URL_BASE_API}${ROOM_PATH}/${ID}`, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_ROOM_DELETE,
                    delete: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_room_read(error_read))
                reject(error_read)
            });
         });

    }
    
}


////////////Room Read/////////////

const error_room_read = (error_read) => {
  return {
    type: ERROR_ROOM_READ,
    error_read
  }
}

export { get_rooms, post_rooms, put_rooms, delete_rooms }