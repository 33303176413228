import axios from 'axios'
import {
  ERROR_SIGNIN,
  SUCCESS_SIGNIN,
  LOG_OUT
} from '../config/constants'
import { URL_BASE, SIGNIN_PATH } from '../config/api'

// SESSION SUCCESS
const success_signin = payload => {
  return {
    type: SUCCESS_SIGNIN,
    payload
  }
}
// SESSION EERROR

const error_signin = error => {
  return {
    type: ERROR_SIGNIN,
    error
  }
}

const fetch_signin = (email, password) => {
  return axios.post(
    `${URL_BASE}${SIGNIN_PATH}`,
    { email, password },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}

const action_signin = (email, password) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      fetch_signin(email, password)
        .then(res => {
          dispatch(
            success_signin({
              access_token: res.data.payload
            })
          )
          resolve(res.data)
        })
        .catch(error => {
          dispatch(error_signin(error))
          reject(error)
        })
    })
  }
}

//CERRAR SESSION
const action_log_out = () => {
  return {
    type: LOG_OUT
  }
}

export {  action_log_out, action_signin }
