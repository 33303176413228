import React, { useEffect, useState } from 'react';
import style from "../styles/toTop.module.scss";

const ToTop = ({ showToTop, scroll }) => {
  const [show, setShow] = useState(false);
  const scrollToTop = () => {
    document.querySelector('section').scrollTo({
      top: 0,
      behavior: "smooth"
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  useEffect(() => {
    if (showToTop) {
      setShow(true)
    } else {
      setShow(false)
    }
    if (scroll === 0) {
      setShow(false)
    }
  })
  return (
    <React.Fragment>
      {show &&
        <div title='Back to top' className={style.scroll}
          onClick={() => scrollToTop()}>
          <i className={style.iconUp} />
        </div>
      }
    </React.Fragment>
  )
}

export default ToTop;