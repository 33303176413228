import React, { useRef, useState } from 'react';
import style from '../styles/cardListOptions.module.scss';
import ListOption from './listOption';
import { c } from '../helpers';

const CardListOptions = props => {
    const [open, setOpen] = useState(false);

    let parent = useRef(null);


    const toggleActive = e => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(!open);
    }

    const loadListOption = () => props.options.map((opt, i) => (
           <ListOption
            key={i}
            id={i}
            fn={opt.fn}
        >
            {opt.name}
        </ListOption>
    ));

    const deactivate = (event) => {
        if(event.hasOwnProperty('relatedTarget')) {
            if(event.relatedTarget.parentNode.parentNode === parent.current) {
                return ;
            }
        }

        setOpen(false);
    }

    return (
        <div
            ref={parent}
            onBlur={deactivate}
            className={c(style.contentListOption, open && style.open)}
            onClick={e => toggleActive(e)}
        >
            <div className={style.overflow}></div>
            <i className={style.iconMoreVert} />
            <section className={style.listOption}>
                {loadListOption()}
            </section>
        </div>
    );
}

export default CardListOptions;