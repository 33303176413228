import { ERROR_SIGNIN, SUCCESS_SIGNIN, LOG_OUT } from '../config/constants'
import { getLogin } from '../services/auth'

const previousSignin = getLogin();

let initial_state = {
  user: {},
  access_token: previousSignin.token,
  auth: previousSignin.auth,
  error: null
}

const getError = (action) => {
  try {
    return action.error.response.data.message
  } catch {
    return 'You cannot login, try again later'
  }
}

export default function reducerSignin (state = initial_state, action) {
  switch (action.type) {
    case SUCCESS_SIGNIN:
      localStorage.setItem('token', action.payload.access_token)
      return {
        ...state,
        access_token: action.payload.access_token,
        error: null,
        auth: true,
        user: action.payload
      }
    case ERROR_SIGNIN:
    case LOG_OUT:
      localStorage.removeItem('token')
      return {
        ...state,
        access_token: null,
        auth: false,
        error: getError(action)
      }
    default:
      return state
  }
}
