import axios from 'axios';

import {
    READ_SPONSORS,
    READ_SPONSOR,
    SUCCESS_SPONSOR_CREATE,
    SUCCESS_SPONSOR_UPDATE,
    SUCCESS_SPONSOR_DELETE,
    ERROR_SPONSOR_READ
} from '../config/constants'
import { URL_BASE_API, SPONSOR_PATH } from '../config/api';

const headers = {
    headers: {
        skip: true
    }
}

// CRUD SPONSOR

const get_sponsors = () => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${SPONSOR_PATH}`, headers)
            .then(res => res.data.payload[0])
            .then(sponsors => sponsors.sort((sponsor, b) => sponsor.id - b.id))
            .then(data => {
                dispatch({
                    type: READ_SPONSORS,
                    sponsors: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_sponsor_read(error_read))
                reject(error_read)
            });
         });

    }

}

const get_sponsor = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${SPONSOR_PATH}/${ID}`, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: READ_SPONSOR,
                    sponsor: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_sponsor_read(error_read))
                reject(error_read)
            });
         });

    }

}

const post_sponsors = data => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .post(`${URL_BASE_API}${SPONSOR_PATH}`, data, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: SUCCESS_SPONSOR_CREATE,
                    create: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_sponsor_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const put_sponsors = (ID, data) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .put(`${URL_BASE_API}${SPONSOR_PATH}/${ID}`, data, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: SUCCESS_SPONSOR_UPDATE,
                    update: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_sponsor_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const delete_sponsors = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .delete(`${URL_BASE_API}${SPONSOR_PATH}/${ID}`, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_SPONSOR_DELETE,
                    delete: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_sponsor_read(error_read))
                reject(error_read)
            });
         });

    }
    
}


////////////Sponsor Read/////////////

const error_sponsor_read = (error_read) => {
  return {
    type: ERROR_SPONSOR_READ,
    error_read
  }
}

export { get_sponsors, get_sponsor, post_sponsors, put_sponsors, delete_sponsors }