import axios from 'axios';

import {
    READ_SPEAKERS,
    READ_SPEAKERS_EVENT,
    READ_SPEAKER,
    SUCCESS_SPEAKER_CREATE,
    SUCCESS_SPEAKER_UPDATE,
    SUCCESS_SPEAKER_DELETE,
    ERROR_SPEAKER_READ,
} from '../config/constants'
import { URL_BASE_API, SPEAKER_PATH } from '../config/api';

const headers = {
    headers: {
        skip: true
    }
}

const get_speakers = () => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${SPEAKER_PATH}`, headers)
            .then(res => res.data.payload[0])
            .then(speakers => speakers.sort((speaker, b) => speaker.id - b.id))
            .then(data => {
                dispatch({
                    type: READ_SPEAKERS,
                    speakers: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_speaker_read(error_read))
                reject(error_read)
            });
         });

    }
}

const get_speaker = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}${SPEAKER_PATH}${ID}`, headers)
            .then(res => res.data.payload[0])
            .then(data => {
                dispatch({
                    type: READ_SPEAKER,
                    speaker: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_speaker_read(error_read))
                reject(error_read)
            });
         });

    }

}

const get_speaker_for_event = (ID) => {
    return dispatch => {
        return new Promise ( (resolve, reject) => {
            axios
            .get(`${URL_BASE_API}event/${ID}/${SPEAKER_PATH}/all`, headers)
            .then(res => res.data.payload[0])
            .then(speakers => speakers.sort((speaker, b) => speaker.id - b.id))
            .then(data => {
                dispatch({
                    type: READ_SPEAKERS_EVENT,
                    speaker_event: data
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_speaker_read(error_read))
                reject(error_read)
            });
         });

    }

}

const post_speakers = data => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .post(`${URL_BASE_API}${SPEAKER_PATH}`, data, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_SPEAKER_CREATE,
                    create: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_speaker_read(error_read))
                reject(error_read)
            });
         });

    }
    
}

const put_speakers = (ID, data) => {
    return dispatch => {
        return new Promise ( (resolve, reject) => {
            axios
            .put(`${URL_BASE_API}${SPEAKER_PATH}/${ID}`, data, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_SPEAKER_UPDATE,
                    update: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_speaker_read(error_read))
                reject(error_read)
            });
         });
    }
}

const delete_speakers = (ID) => {

    return dispatch => {

        return new Promise ( (resolve, reject) => {
            axios
            .delete(`${URL_BASE_API}${SPEAKER_PATH}/${ID}`, headers)
            .then(data => {
                dispatch({
                    type: SUCCESS_SPEAKER_DELETE,
                    delete: true
                })
                resolve(data)
            })
            .catch(error_read => {
                dispatch(error_speaker_read(error_read))
                reject(error_read)
            });
         });

    }
    
}


////////////Track Read/////////////

const error_speaker_read = (error_read) => {
  return {
    type: ERROR_SPEAKER_READ,
    error_read
  }
}

export { get_speakers, get_speaker, get_speaker_for_event, post_speakers, put_speakers, delete_speakers }