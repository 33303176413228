import { URL_BASE } from "./config/api";
import defaultAsset from './images/asset_default.svg';

export const createUniqeId = function(){
	var result           = '';
	var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	var charactersLength = characters.length;
	for ( var i = 0; i < 6; i++ ) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export const loadStatus = length => {
    let status = []
    for (let i = 0; i < length; i++) status.push(false);
    return status;
}

// join classnames
export const c = (...classes) => classes.filter(v => !!v && v.length > 0).join(' ');

// set proper source URL
export const urlFor = (route) => {
        if(typeof route !== typeof "" || route.length < 1) {
		return defaultAsset;
	}
	if(/^https?:\/\//.test(route)) {
		return route;
	}

	const upload = (/^\/?uploads/.test(route) ? '' : 'uploads');
	if(route.charAt(0) === '/') {
		return URL_BASE + upload + route.slice(1);
	}

	return URL_BASE + upload + '/' + route;
}

/* validation */
export const isValidEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

/* FORM DATE */
export const formatDate = (date) => {	// formats a JS date to 'yyyy-mm-dd'
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}
