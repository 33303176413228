import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { actionChangeView } from "../actions/action_styles";
import style from "../styles/buttonChangeView.module.scss";

const ButtonChangeView = () => {
  const [iconView, setIconView] = useState(style.iconViewList)
  const [showList, setShowList] = useState(false)
  const dispatch = useDispatch();

  const changeView = () => {
    let oldStateList = showList;
    let oldStateIcon = iconView;
    let isShowList = !oldStateList;
    let isShowIcon = oldStateIcon === style.iconViewList;
    let newStateShowList = !!isShowList;
    let newStateShowIcon = isShowIcon ? style.iconViewModule : style.iconViewList;
    setIconView(newStateShowIcon);
    setShowList(newStateShowList);
    dispatch(actionChangeView(newStateShowList))
  }

  return (
    <React.Fragment>
      <div className={style.changeView}>
        <button onClick={changeView} className={style.btn}>
          <i className={iconView}></i>
        </button>
      </div>
    </React.Fragment>
  );
}


export default ButtonChangeView;