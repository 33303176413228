import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionShowDelete } from '../actions/action_styles';
import Card from './card';
import EventsBar from './eventsBar';
import style from '../styles/eventsCardsSearch.module.scss';
import { urlFor } from '../helpers';

const EventsCardsSearch = ({ showDelete }) => {
	const search = useSelector(store => store.events.search);
	const dispatch = useDispatch();

	const empty = () => search.length === 0;

	const loaderCards = () => search.map(event => (
		<Card 
			key={event.id}
			id={event.id}
			showDelete={showDelete}
			values={event}
			event={true}
			showList={true}
			title={event.name}
			description={event.description}
			imgURL={urlFor(event.image)}
			fnAction={[
				{fn: () => {
					dispatch(actionShowDelete(!showDelete))
					// openDialog({message: 'You will lose the information of this event', buttonText: 'DELETE'}, () => deleteEvents(event.id) )
				},
				name: "Edit"},
				{fn: () => {
					dispatch(actionShowDelete(!showDelete))
					// openDialog({message: 'You will lose the information of this event', buttonText: 'DELETE'}, () => deleteEvents(event.id) )
				},
				name: "Delete"},
			]} />
    ));

	return (
		<div className={style.eventContainer}>
			<div className={style.line}>
				<span>Search result</span>
			</div>
			{!empty() ?
				<section className={style.contentCards}>
					{loaderCards()}
				</section>
			:
				<section className={style.infoEmpty}>
					<p className={style.title}>Search not found</p>
					<i className={style.icon}></i>
				</section>
			}
		</div>
	);
};

export default EventsCardsSearch;
