import React from 'react';
import { Link } from 'react-router-dom';
import style from '../styles/cfpDashboard.module.scss';
import Meta from '../components/metaContent';

const CfpDashboard = () => (
	<div className={style.cfpDashboard}>
		<Meta title="Call for Papers" />
		<div className={style.noForms}>
			<h3 className={style.text}>
				There are still no forms for this event <br />
				Start one!
			</h3>
			<span className={style.icon}></span>
			<button>
				<Link to="/home/formBuilder">
				CREATE
				</Link>
			</button>
		</div>
	</div>
);

export default CfpDashboard;