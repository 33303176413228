import React from 'react';
import { Switch, Route } from 'react-router-dom';
import EventRouter from '../routers/event';
import MenuBar from '../components/menuBar';
import EventsCardsSearch from '../components/eventsCardsSearch';
import Event from './event';
import UserDashboard from './dashboard';
import UserProfile from './profile';
import NotFound from './pageNotFound';
import style from '../styles/home.module.scss';
import {
	DASHBOARD_ROUTE,
	PROFILE_ROUTE,
	EVENTS_ROUTE,
	EVENT_ROUTE,
	SEARCH_ROUTE
} from '../config/routes';


const Home = () =>
	<div className={style.home}>
		<MenuBar />
		<Switch>
			<Route path={DASHBOARD_ROUTE} component={UserDashboard} />
			<Route path={PROFILE_ROUTE} component={UserProfile} />
			<Route path={EVENTS_ROUTE} component={EventRouter} />
			<Route exact path={EVENT_ROUTE} component={Event} />
			<Route path={SEARCH_ROUTE} component={EventsCardsSearch} />
			{/*
			<Route path="/admin/dashboard" component={AdminDashboard} />
			<Route path="/createCall" component={CFPBuildForm} />
			<Route path="/edit_form" component={CFPEditForm} />
			<Route path="/edit_form" component={CFPEditForm} />
			<Route path="/formBuilder" component={CFPBuildForm} />
			<Route path="/edit_form" component={CFPEditForm} />
			<Route path="/edit_form" component={CFPEditForm} />
			<Route path="/progress" component={EventsCardsInProgress} />
			<Route path="/previews" component={EventsCardsPreviews} />
			<Route path="/draft" component={EventsCardsDraft} />
			<Route path="/search" component={EventsCardsSearch} />*/}
			<Route path="/*" component={NotFound} />
		</Switch>
	</div>;

export default Home;
