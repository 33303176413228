import {
  READ_PARTNERSHIP_SPONSORS,
  READ_PARTNERSHIP_SPONSOR,
  SUCCESS_PARTNERSHIP_SPONSOR_CREATE,
  SUCCESS_PARTNERSHIP_SPONSOR_UPDATE,
  SUCCESS_PARTNERSHIP_SPONSOR_DELETE,
  SEARCH_PARTNERSHIP_SPONSORS,
  ERROR_PARTNERSHIP_SPONSOR_READ,
} from '../config/constants'

const initialState = {
  partnerships: [],
  partnership: [],
  create: [],
  update: [],
  search: [],
  error_create: 'error',
  error_read: 'error'
}

export const reducerPartnership = (state = initialState, action) => {

    switch (action.type) {
      case READ_PARTNERSHIP_SPONSORS:
        return { ...state, partnerships: action.partnerships }

      case READ_PARTNERSHIP_SPONSOR:
        return { ...state, partnership: action.partnership }

      case SEARCH_PARTNERSHIP_SPONSORS:
        return { ...state, search: action.search }

      case SUCCESS_PARTNERSHIP_SPONSOR_CREATE:
        return { ...state, create: action.create }

      case SUCCESS_PARTNERSHIP_SPONSOR_UPDATE:
        return { ...state, update: action.update }

      case SUCCESS_PARTNERSHIP_SPONSOR_DELETE:
        return { ...state, delete: action.delete }

      case ERROR_PARTNERSHIP_SPONSOR_READ:
        return { ...state, error_read: action.error_read }

      default:
        return state
    }

}
