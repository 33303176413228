import React, { useState, useEffect } from 'react';
import { actionShowDelete, actionShowDetails } from "../actions/action_styles";
import { action_read_post, action_delete_post } from "../actions/action_post";
import { useDispatch, useSelector } from "react-redux";
import { c, urlFor } from "../helpers";
import WriteComments from './writeComments';
import ModalViewPost from './modalViewPost';
import CommentPost from './commentPost';
import LikeComment from './likeComment';
import HeaderPost from './headerPost';
import Modal from "./modal";
import Slider from './slider';
import style from "../styles/userPostBox.module.scss";

const UserPostBox = () => {
  const dispatch = useDispatch();
  const showDelete = useSelector((state) => state.styles);
  const showDetails = useSelector((state) => state.styles);
  const post = useSelector((state) => state.post.post);

  const [view, setView] = useState(0);
  const [index, setIndex] = useState(0);
  const [showMedia, setShowMedia] = useState(false);

  const handleId = (id) => {
    setTimeout(() => {
      dispatch(actionShowDetails(!showDetails.details))
    }, 200);
    setIndex(id)
  }

  useEffect(() => {
    dispatch(action_read_post())
  }, [dispatch])

  return (
    <React.Fragment>
      {post.length === 0 &&
        <div className={style.message}>
          <div className={style.icon} ></div>
          <span className={style.text}>There's not post yet.</span>
          <span className={style.text}>(Pssst you should create one!)</span>
        </div>
      }
      {post.length >= 1 &&
        post.map(({ id, image, fullname, name, video, coun_message, content, file, date }, i) =>
          <div key={id + i} className={style.boxCenter} onMouseEnter={() => setIndex(id)}>
            <HeaderPost
              name={fullname}
              username={name}
              date={date.substring(0, 10)}
              imgUser=""
              showPost={true}
              index={i} />
            {video.length === 0 && image.length === 0 &&
              <div className={style.desc}
                onMouseDown={() => setView(i)}
                onClick={() => handleId(id)}
              >
                <p>{content}</p>
              </div>
            }
            {file.length >= 1 &&
              <div
                className={style.contentFiles}
                onMouseDown={() => setView(i)}
                onClick={() => handleId(id)} >
                {file.map((archive, i) =>
                  <div key={"file"+i} className={style.rowFile}>
                    <a target="_blank" href={urlFor(archive)} className={style.iconFile} />
                    <span>Archive {i}</span>
                  </div>
                )}
              </div>
            }
            {image.length === 1 &&
              <div
                className={style.content}
                onMouseDown={() => setView(i)}
                onClick={() => handleId(id)}>
                <img loading="lazy" src={urlFor(image[0])} className={style.img1} />
              </div>
            }
            {image.length === 2 &&
              <div className={style.content} onMouseDown={() => setView(i)} onClick={() => dispatch(actionShowDetails(!showDetails.details))}>
                {image.map((image, i) =>
                  <img loading="lazy" key={i} src={urlFor(image)} className={style.img2} />
                )}
              </div>
            }
            {image.length === 3 &&
              <div className={style.content} onMouseDown={() => setView(i)} onClick={() => dispatch(actionShowDetails(!showDetails.details))}>
                {image.map((image, i) =>
                  <img loading="lazy" key={i} src={urlFor(image)} className={style.img3} />
                )}
              </div>
            }
            {image.length === 4 &&
              <div className={c(style.content, style.imgMore)} onMouseDown={() => setView(i)} onClick={() => dispatch(actionShowDetails(!showDetails.details))}>
                {image.map((image, i) =>
                  <img loading="lazy" width="600" height="480" key={i} src={urlFor(image)} className={style.img4} />
                )}
              </div>
            }
            {image.length >= 5 &&
              <div className={c(style.content, style.imgMore)} onMouseDown={() => setView(i)} onClick={() => dispatch(actionShowDetails(!showDetails.details))}>
                {image.map((image, i) =>
                  <img loading="lazy" key={i} src={urlFor(image)} className={style.img5} />
                )}
              </div>
            }
            {video.length >= 1 &&
              <div
                className={c(style.content, style.video)}
                onMouseDown={() => setView(parseInt(i))}
                onClick={() => handleId(id)}>
                <video muted className={style.video} loading="lazy" controls>
                  <source src={urlFor(video[0])} type="video/mp4" />
                  <source src={urlFor(video[0])} type="video/ogg" />
                </video>
              </div>
            }
            <LikeComment
              index={id}
              count_messages={coun_message}
              post={true} />
            <WriteComments />
          </div>
        )}
      {showDelete.delete &&
        <ModalPost index={index} />
      }
      {showDetails.details &&
        <ModalDetailsPost view={view} index={index} post={post[view]} />
      }
    </React.Fragment>
  )
}

const ModalPost = ({ index }) => {
  const dispatch = useDispatch();
  const showDelete = useSelector((state) => state.styles);
  const hide = () => {
    dispatch(actionShowDetails(false))
    dispatch(actionShowDelete(!showDelete.delete))
  }
  const onDelete = () => {
    dispatch(action_delete_post(index))
      .then(req => {
        dispatch(action_read_post())
      })
      .catch(error => {
        console.log(`fail delete post: ${error}`);
      });
  };
  return (
    <Modal>
      <div className={style.postModal}>
        <h2>Are you sure?</h2>
        <span>
          You will lose all about this post
        </span>
        <button onClick={() => hide()} onMouseDown={onDelete}>DELETE</button>
      </div>
    </Modal>
  )
}

const ModalDetailsPost = ({ view, post, index }) => {
  const [media, setMedia] = useState('');
  const [showMedia, setShowMedia] = useState('');
  const [like, setLike] = useState('');
  useEffect(() => {
    if (post.image.length >= 1 || post.video.length >= 1) {
      setMedia(style.media)
      setShowMedia(true)
    } else {
      setMedia('')
      setShowMedia(false)
    }
  })
  return (
    <ModalViewPost>
      <div className={style.modalDetailsPost}>
        <div className={c(style.contentLeft, media)}>
          {!showMedia &&
            <HeaderPost
              postLeft={true}
              name={post.fullname}
              username={post.name}
              date={post.date.substring(0, 10)}
              imgUser=""
              showPost={true}
              index={view} />
          }
          {!showMedia &&
            post.content !== "" &&
            <div className={style.contentComments}>
              <div className={style.contentDesc}>
                <p>{post.content}</p>
              </div>
            </div>
          }
          {post.file >= 1 &&
            <div className={style.contentFiles}>
              <div className={style.rowFile}>
                <a target="_blank" href={post.file} className={style.iconFile} />
                <span>Archive</span>
              </div>
            </div>
          }
          {showMedia &&
            <div className={c(style.leftView, media)}>
              {post.image.length > 1 &&
                <Slider indexView={view} img={post.image} />
              }
              {post.image.length === 1 &&
                <img className={style.imgView} loading="lazy" src={urlFor(post.image[0])} />
              }
              {post.video.length >= 1 &&
                <video className={style.videoView} loading="lazy" controls>
                  <source src={urlFor(post.video[0])} type="video/mp4" />
                  <source src={urlFor(post.video[0])} type="video/ogg" />
                </video>
              }
            </div>
          }
          {!showMedia &&
            <div className={style.iconBox}>
              <i
                className={c(style.iconFavorite, like)}
                onDoubleClick={() => setLike('')}
                onClick={() => setLike(style.redLike)}></i>
              <span>{post.count_likes}</span>
            </div>
          }
        </div>
        <div className={style.right}>
          {showMedia &&
            <HeaderPost
              post={true}
              name={post.fullname}
              username={post.name}
              date={post.date.substring(0, 10)}
              imgUser=""
              showPost={true}
              index={view} />
          }
          <div className={c(style.contentComents, style.comments)}>
            {showMedia &&
              post.content !== "" &&
              <div className={style.contentDescRight}>
                <p>{post.content}</p>
              </div>
            }
            <WriteComments index={index} modal={style.modal} />
            <CommentPost index={index} />
          </div>
        </div>
      </div>
    </ModalViewPost>
  )
}


export default UserPostBox;