import React from 'react';
import style from "../styles/centerUserDashboard.module.scss";
import UserCreatePost from './userCreatePost';
import UserPostBox from './userPostBox';

const CenterUserDashboard = ({ user }) => {
  return (
    <React.Fragment>
      <div className={style.centerUser}>
        <UserCreatePost />
        <UserPostBox user={user}/>
      </div>
    </React.Fragment>
  )
}

export default CenterUserDashboard;