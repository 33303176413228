import axios from 'axios'
import {
  CREATE_COMMENT,
  READ_COMMENT,
  ERROR_COMMENT,
  DELETE_COMMENT
} from '../config/constants'
import {
  URL_BASE_API,
  COMMENT_PATH,
  POST_PATH
} from '../config/api'

//////////// Error comment /////////////

const error_comment = error => {
  return {
    type: ERROR_COMMENT,
    error
  }
}

////////////Delete comment/////////////
const delete_comment = erase => {
  return {
    type: DELETE_COMMENT,
    erase
  }
}
const fetch_delete_comment = id => {
  const token = localStorage.getItem('token')
  return axios.delete(`${URL_BASE_API}${COMMENT_PATH}/${id}`, {
    headers: {
      Authorization: `bearer ${token}`
    }
  })
}
const action_delete_commet = id => {
  return dispatch => {
    return fetch_delete_comment(id)
      .then(data => dispatch(delete_comment(true)))
      .catch(error => dispatch(error_comment(error)))
  }
}

//////////// Read comment Post/////////////

const action_read_comment_post = id => {
  const token = localStorage.getItem('token')

  return dispatch => {
    axios
      .get(`${URL_BASE_API}${POST_PATH}/${id}/${COMMENT_PATH}/all/`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(response => {
        dispatch({
          type: READ_COMMENT,
          read_comments: response.data.payload
        })
      })
      .catch(error => dispatch(error_comment(error)))
  }
}
////////Create comment//////

const create_comment = create => {
  return {
    type: CREATE_COMMENT,
    create
  }
}

const fetch_create = data => {
  const token = localStorage.getItem('token')
  return axios.post(`${URL_BASE_API}${COMMENT_PATH}/`, data, {
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

const action_create_comment = data => {
  let formdata = new FormData()
  formdata.append('content', data.content)
  formdata.append('id_post', data.id_post)
  if (data.vide) {
    formdata.append('video', data.video, data.videoName)
  }
  if (data.image) {
    formdata.append('image', data.image, data.imageName)
  }
  if (data.file) {
    formdata.append('file', data.file, data.fileName)
  }
  if (data.reference) {
    formdata.append('reference', data.reference)
  }
  formdata.append('active', 'true')
  formdata.append('fixed', 'true')
  formdata.append('id_user', '1')
  return dispatch => {
    return fetch_create(formdata)
      .then(data => dispatch(create_comment(true)))
      .catch(error => dispatch(error_comment(error)))
  }
}

export { action_create_comment, action_read_comment_post, action_delete_commet }
