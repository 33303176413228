import {
  READ_ROOMS,
  READ_ROOM,
  SUCCESS_ROOM_CREATE,
  SUCCESS_ROOM_UPDATE,
  SUCCESS_ROOM_DELETE,
  ERROR_ROOM_READ,
} from '../config/constants'

const initialState = {
  rooms: []
}

export const reducerRooms = (state = initialState, action) => {
  switch (action.type) {
    case READ_ROOMS:
      return { ...state, rooms: action.rooms }
      
    case READ_ROOM:
      return { ...state, room: action.room }

    case SUCCESS_ROOM_CREATE:
      return { ...state }

    case SUCCESS_ROOM_UPDATE:
      return { ...state }

    case SUCCESS_ROOM_DELETE:
      return { ...state }

    case ERROR_ROOM_READ:
      return { ...state, error_read: action.error_read }

    default:
      return state
  }

}