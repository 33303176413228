import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { action_update_user, action_read_user } from "../actions/action_user";
import { actionEnablePhoto } from "../actions/action_styles";
import style from "../styles/rightProfile.module.scss";
import CountryList from './countryList';
import GenderList from './genderList';
import InputsValidations from './inputsValidations';
import { c } from '../helpers';

const RightProfile = ({ userInfo, form, setForm }) => {
  const dispatch = useDispatch();
  const valid = useSelector((state) => state.styles.validSudmit);
  const photo = useSelector((state) => state.user.photoUser);
  const repeatref = useRef(null)
  const passwordref = useRef(null)

  const { user, country, name, phone, password, email, gender, lastname, adress, repeat, codePhone, id, avatar } = form;

  const [disable, setDisable] = useState(true)
  const [validstate, setvalidstate] = useState(false)
  const [styleButton, setStyleButton] = useState('')
  const [typePass, setTypePass] = useState('password')
  const [disableStyle, setDisableStyle] = useState(style.disable)

  const onChange = e => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const onSubmit = e => {
    e.preventDefault();
    //==> Validate
    // if (brand.trim() === '' || year.trim() === '' || plan.trim() === '') {
    //   saveError(true);
    //   return;
    // }
    // saveError(false)
    // Validate <==//
  }
  const onEdit = () => {
    setDisable(false)
    setDisableStyle(style.enable)
    dispatch(actionEnablePhoto(true))
  }
  const onCancel = () => {
    setDisable(true)
    setDisableStyle(style.disable)
    dispatch(actionEnablePhoto(false))
  }
  const onSave = () => {
    console.log("ONSAVE")
    dispatch(action_update_user(form))
      .then(req => {
        dispatch(action_read_user())
        setDisable(true)
        setDisableStyle(style.disable)
        dispatch(actionEnablePhoto(false))
      })
      .catch(error => {
        console.log(`fail update: ${error}`);
      });
  }
  const showPassword = () => {
    console.log("show")
  }

  const viewPass = () => {
    let oldState = typePass;
    let changeState = oldState === 'password';
    let newState = changeState
      ? 'text'
      : 'password';
    setTypePass(newState);
  }

  useEffect(() => {
    setTimeout(() => {
      setForm({
        id: parseInt(userInfo.id),
        user: userInfo.username,
        email: userInfo.email,
        lastname: userInfo.last_name,
        name: userInfo.name,
        adress: userInfo.address,
        phone: userInfo.phone,
        codePhone: userInfo.country_code,
        country: userInfo.country,
        gender: userInfo.gender,
        password: userInfo.password,
        type: userInfo.type,
        birthdate: userInfo.birthdate,
        zip_code: userInfo.zip_code,
        dni: userInfo.dni,
        avatar: photo
      })
    }, 100);
  }, []);
  useEffect(() => {
    if (valid) {
      setStyleButton(style.active)
      setvalidstate(false)
    } else {
      setStyleButton('')
      setvalidstate(true)
    }
  },[valid])
  return (
    <React.Fragment>
      <div className={style.contentRight}>
        <div className={style.title}>
          <h2>Your data</h2>
          <div className={style.btnGroup}>
            {disable &&
              <button
                onClick={onEdit}
                className={c(style.btnBlue, style.active)}>
                EDIT
              </button>
            }
            {!disable &&
              <button
                disabled={validstate}
                onClick={onSave}
                className={c(style.btnBlue, styleButton)}
              >
                SAVE
              </button>
            }
            {!disable &&
              <button
                onClick={onCancel}
                className={style.btnWhite}>
                CANCEL
              </button>
            }
          </div>
        </div>
        <form onSubmit={onSubmit} className={style.form}>
          <div className={style.column}>
            <div className={style.divInput}>
              <label htmlFor="user">User</label>
              <input
                className={c(disableStyle, style.input)}
                disabled={disable}
                id="user"
                name="user"
                type="text"
                value={user}
                onChange={onChange} />
            </div>
            <div className={style.divInput}>
              <CountryList
                form={form}
                setForm={setForm}
                disable={disable}
                disableStyle={disableStyle}
              />
            </div>
            <div className={style.divInput}>
              <label htmlFor="name">Name</label>
              <input
                className={c(disableStyle, style.input)}
                disabled={disable}
                id="name"
                name="name"
                type="text"
                value={name}
                onChange={onChange} />
            </div>
            <div className={style.divInput}>
              <label htmlFor="phone">Phone</label>
              <div className={style.divRow}>
                <input type="text"
                  className={c(disableStyle, style.phone, style.code)}
                  disabled={true}
                  id="code"
                  name="code"
                  type="text"
                  value={"(" + codePhone + ")"}
                  onChange={onChange}
                />
                <input
                  className={c(disableStyle, style.phone)}
                  disabled={disable}
                  id="phone"
                  name="phone"
                  type="text"
                  value={phone}
                  onChange={onChange} />
              </div>
            </div>
            <div className={style.divInput}>
              <label htmlFor="password">Password</label>
              <div className={style.divRow}>
                <input
                  className={c(disableStyle, style.inputIcon)}
                  disabled={disable}
                  id="password"
                  name="password"
                  type={typePass}
                  value={password}
                  onChange={onChange}
                  ref={passwordref}
                />
                <div className={c(style.divIcons, disableStyle)}>
                  {!disable &&
                    <i
                      onMouseUp={() => setTypePass('password')}
                      onMouseDown={viewPass}
                      className={style.iconVisibility} />
                  }
                </div>
              </div>
              {!disable &&
                <InputsValidations
                  password={password}
                  message3={"Minimun 8 Characteres"}
                  message4={"At least one number"}
                />
              }
            </div>
          </div>
          <div className={style.column}>
            <div className={style.divInput}>
              <label htmlFor="email">Email</label>
              <input
                className={c(disableStyle, style.input)}
                disabled={disable}
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={onChange} />
              {!disable &&
                <InputsValidations
                  email={email}
                  message1={"Invalid Email"}
                />
              }
            </div>
            <div className={style.divInput}>
              <GenderList
                setForm={setForm}
                form={form}
                disable={disable}
                disableStyle={disableStyle}
              />
            </div>
            <div className={style.divInput}>
              <label htmlFor="lastname">Lastname</label>
              <input
                className={c(disableStyle, style.input)}
                disabled={disable}
                id="lastname"
                name="lastname"
                type="text"
                value={lastname}
                onChange={onChange} />
            </div>
            <div className={style.divInput}>
              <label htmlFor="adress">Adress</label>
              <input
                className={c(disableStyle, style.input)}
                disabled={disable}
                id="adress"
                name="adress"
                type="text"
                value={adress}
                onChange={onChange} />
            </div>
            {!disable &&
              <div className={style.divInput}>
                <label htmlFor="repeat">Repeat password</label>
                <input
                  className={c(disableStyle, style.input)}
                  disabled={disable}
                  id="repeat"
                  name="repeat"
                  type="password"
                  value={repeat}
                  onChange={onChange}
                  ref={repeatref}
                />
                {!disable &&
                  <InputsValidations
                    password={password}
                    repeat={repeat}
                    message2={"Passwords do not macth"}
                  />
                }
              </div>
            }
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}
export default RightProfile;